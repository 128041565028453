.c-acc {
  &-header {
    padding: 11px 0;
    position: sticky;
    top: 0;
    z-index: 3;

    @include s-1440 {
      padding: 3px 0;
    }

    @include s-1024 {
      padding: 2px 0;
    }

    & .user {
      &-name {
        font-size: 24px;
        line-height: 120%;

        @include s-1440 {
          font-size: 20px;
        }

        &-current {
          color: var(--color-orange-main);
        }
      }

      &-text {
        font-size: 16px;
        line-height: 150%;

        @include s-1440 {
          font-size: 14px;
        }

        @include s-1024 {
          font-size: 13px;
        }
      }

      &-box {
        display: flex;
        align-items: center;

        &-icon {
          position: relative;
          cursor: pointer;
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;

          @include s-1440 {
            width: 40px;
            height: 40px;
          }

          @include s-1024 {
            width: 36px;
            height: 36px;
          }

          @include s-768 {
            width: 32px;
            height: 32px;
          }

          @include s-375 {
            width: 30px;
            height: 30px;
          }

          &-ring {
            position: relative;
            display: block;
            height: 100%;

            .svg.icon-svg {
              display: block;
            }
            & svg.icon-svg {
              position: relative;
              z-index: 1;
              animation-name: none;

              & .icon-bg {
                fill: #ecedef;
                transition: fill ease 0.3s;
              }
            }

            & .user-box-icon-counter {
              transform: scale(1);
            }
            &.null-notify {
              & .user-box-icon-counter {
                transform: scale(0);
              }
            }
            &.first-notify {
              & svg.icon-svg {
                animation-name: shake;
                animation: shake 2s ease-in-out;
                animation-iteration-count: 1;
              }

              &:before {
                content: '';
                background: #000;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 50px;
                transform: scale(1);
                animation-name: transformScale;
                animation-duration: 1.5s;
                animation-delay: 0;
                animation-iteration-count: 1;
              }

              &:after {
                content: '';
                background: #000;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 50px;
                transform: scale(1);
                animation-name: transformScale;
                animation-duration: 1.5s;
                animation-delay: 0.75s;
                animation-iteration-count: 1;
              }

              & .user-box-icon-counter {
                // transform: scale(1);
                animation-name: increaseFirst;
                animation: increaseFirst 600ms ease-in-out;
                animation-iteration-count: 1;
              }
            }
            &.alarm-notify {
              & svg.icon-svg {
                animation-name: shake;
                animation: shake 2s ease-in-out;
                animation-iteration-count: 1;
              }

              &:before {
                content: '';
                background: #000;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 50px;
                transform: scale(1);
                animation-name: transformScale;
                animation-duration: 1.5s;
                animation-delay: 0;
                animation-iteration-count: 1;
              }

              &:after {
                content: '';
                background: #000;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 50px;
                transform: scale(1);
                animation-name: transformScale;
                animation-duration: 1.5s;
                animation-delay: 0.75s;
                animation-iteration-count: 1;
              }

              & .user-box-icon-counter {
                animation-name: increaseAndDecrease;
                animation: increaseAndDecrease 600ms ease-in-out;
                animation-iteration-count: 1;
              }
            }
          }

          @keyframes transformScale {
            0% {
              transform: scale(1);
              opacity: 0.4;
            }
            100% {
              transform: scale(2.5);
              opacity: 0.01;
            }
          }

          @keyframes shake {
            0% {
              transform: rotate(0);
            }
            1% {
              transform: rotate(30deg);
            }
            7% {
              transform: rotate(-28deg);
            }
            13% {
              transform: rotate(26deg);
            }
            19% {
              transform: rotate(-20deg);
            }
            25% {
              transform: rotate(14deg);
            }
            31% {
              transform: rotate(-8deg);
            }
            37% {
              transform: rotate(2deg);
            }
            43% {
              transform: rotate(0);
            }
            100% {
              transform: rotate(0);
            }
          }
          @keyframes increaseFirst {
            0% {
              transform: scale(0);
            }
            50% {
              transform: scale(0.5);
            }
            100% {
              transform: scale(1);
            }
          }
          @keyframes increaseAndDecrease {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.25);
            }
            100% {
              transform: scale(1);
            }
          }

          &-counter {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 1;
            top: -4px;
            right: -4px;
            padding: 6px;
            border-radius: 50%;
            overflow: hidden;
            background-color: var(--color-orange-light);
            font-size: 13px;
            line-height: 100%;
            font-family: var(--font-med);
            // border-radius: 28px;
            pointer-events: none;
            height: 21px;
            min-width: 21px;
            border: 0 none;

            @include s-1440 {
              border-radius: 24px;
              // padding: 4px;
              font-size: 11px;
              height: 16px;
              min-width: 16px;
            }
          }
        }

        &-avatar {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          overflow: hidden;
          margin-left: 32px;

          @include s-1440 {
            margin-left: 24px;
            width: 40px;
            height: 40px;
          }

          @include s-1024 {
            margin-left: 20px;
            width: 36px;
            height: 36px;
          }

          @include s-768 {
            width: 32px;
            height: 32px;
          }

          @include s-375 {
            width: 30px;
            height: 30px;
            margin-left: 14px;
          }

          &-img {
            height: 100%;

            & img {
              height: 100%;
              object-fit: cover;
            }
          }

          &-init {
            background: linear-gradient(100deg, #8ecae6 1.83%, #2487a7 98.07%);
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
            letter-spacing: 1.2px;
            color: var(--color-white);
            text-transform: uppercase;
            font-family: var(--font-reg);

            @include s-768 {
              font-size: 14px;
            }

            @include s-375 {
              font-size: 12px;
            }
          }

          &.mobile {
            display: none;

            @include s-375 {
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              padding: 10px;
              width: 40px;
              height: 40px;
              transition: all ease 0.4s;

              &:hover {
                background-color: rgba(0, 0, 0, 0.1);
              }
            }
          }
        }
      }
    }

    & .MuiContainer-root {
      display: flex;
      justify-content: space-between;
      padding: 0;
    }

    &-without {
      padding: 20px 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 9;

      a.logo {
        max-width: 200px;

        @include s-768 {
          max-width: 140px;
        }
      }
    }
  }
}
