.auth-content {
  margin: 150px 0;
  width: 100%;

  @include s-1440 {
    margin: 110px 0;
  }

  @include s-1024 {
    margin: 92px 0;
  }

  @include s-375 {
    margin: 70px 0;
  }

  & .auth-title-block {
    padding-bottom: 48px;
    @include s-1440 {
      padding-bottom: 40px;
    }
    @include s-1024 {
      padding-bottom: 36px;
    }
    .el-title-h6 {
      padding-bottom: 0;
    }
  }

  &-row {
    display: flex;
    gap: 16px;

    @include s-375 {
      flex-wrap: wrap;
    }

    & .el-input:first-child {
      flex: 0 0 28%;

      @include s-1024 {
        flex: 0 0 32%;
      }

      @include s-375 {
        flex: 0 0 100%;
        margin-bottom: 0;
      }
    }

    & .el-input-select {
      flex: 0 0 28%;

      @include s-1024 {
        flex: 0 0 32%;
      }

      @include s-375 {
        flex: 0 0 100%;
        margin-bottom: 0;
      }
    }
  }

  &--title {
    display: block;
    text-align: center;
    color: var(--color-gray-7);
    padding-top: 16px;

    @include s-1440 {
      padding-top: 12px;
    }

    @include s-1024 {
      padding-top: 10px;
    }
  }

  &--subtitle {
    display: block;
    text-align: center;
    color: var(--color-gray-7);
    padding-top: 16px;

    @include s-1440 {
      padding-top: 12px;
    }

    @include s-1024 {
      padding-top: 10px;
    }
  }

  &-confirm {
    margin-top: 24px;

    @include s-1440 {
      margin-top: 14px;
    }

    @include s-1024 {
      margin-top: 24px;
    }
  }

  &-indetify {
    &-box {
      display: flex;
      justify-content: center;
      // align-items: center;
      align-items: stretch;
      gap: 32px;

      @include s-1440 {
        gap: 24px;
      }

      @include s-1024 {
        gap: 20px;
      }

      @include s-375 {
        flex-wrap: wrap;
      }
    }
    &-date {
      margin-top: 48px;

      @include s-1440 {
        margin-top: 40px;
      }

      @include s-1024 {
        margin-top: 36px;
      }

      .el-input-date {
        margin-bottom: 0;
      }
    }
    & .dropzone {
      text-align: center;
      border-radius: 16px;
      border: 1px solid #d3d6d8;
      padding: 40px 32px;
      max-width: 364px;
      width: 100%;
      min-height: 420px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include s-1440 {
        padding: 32px 28px;
        min-height: 336px;
        max-width: 288px;
      }

      @include s-1024 {
        max-width: 262px;
        min-height: 308px;
        padding: 28px 20px;
      }

      &-img {
        max-width: 28px;
        margin: 0 auto;
        width: 100%;
        @include s-1440 {
          max-width: 24px;
        }

        @include s-1024 {
          max-width: 20px;
        }
      }

      &-description {
        // padding-top: 24px;

        &--title {
          font-family: var(--font-med);
        }

        &--subtitle {
          padding: 8px 0;

          @include s-1440 {
            padding: 6px 0;
          }
        }

        &--upload {
          padding-top: 24px;

          @include s-1440 {
            padding-top: 16px;
          }

          @include s-1024 {
            padding-top: 12px;
          }
        }

        & ul {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          list-style-type: bullet;
          list-style: inside;
          & .el-text-xs {
            color: var(--color-gray-6);
            display: list-item;
          }
        }
        // li{
        //   color:var(--color-gray-6);
        //   display: inline-block;
        // }
      }

      &-preview {
        width: 284px;
        height: 212px;
        margin: 8px auto 0;
        display: flex;
        overflow: hidden;
        background-color: #cecece;

        @include s-1440 {
          width: 224px;
          height: 167px;
        }

        @include s-1024 {
          width: 206px;
          height: 155px;
        }

        &-name {
          word-break: break-all;
          padding-top: 12px;
          @include s-1440 {
            padding-top: 8px;
          }

          @include s-1024 {
          }
        }
        & img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }

      & .el-text-m,
      .el-text-xs {
        display: block;
        word-break: break-word;
      }

      & .el-button {
        margin: 32px auto 0;
        max-width: 166px;

        @include s-1440 {
          margin: 24px auto 0;
          max-width: 138px;
        }

        @include s-1024 {
          max-width: 124px;
          margin: 20px auto 0;
          padding: 15px;
        }
      }
    }
  }
}
