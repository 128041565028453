.p-referral {
  h2,
  .el-title-h2 {
    font-family: var(--font-reg);
    font-size: 92px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    @include s-1440 {
      font-size: 70px;
    }

    @include s-1024 {
      font-size: 56px;
    }

    @include s-768 {
      font-size: 56px;
    }
    @include s-375 {
      font-size: 56px;
    }
    & .orange {
      color: var(--color-orange-light1);
    }
  }
  &-container {
    padding-top: 80px;
    @include s-1440 {
      padding-top: 74px;
    }
    @include s-1024 {
      padding-top: 64px;
    }
    @include s-375 {
      padding-top: 0;
    }
  }
  & .s {
    &-hero {
      position: relative;
      border-radius: 20px;
      overflow: hidden;
      margin-top: 100px;
      @include s-1440 {
        margin-top: 70px;
      }
      @include s-1024 {
        margin-top: 42px;
      }
      @include s-375 {
        margin-top: 20px;
      }
      &-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        & img {
          object-fit: cover;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          height: 100%;
          transform: translate(-50%, -50%);
          transform-origin: center;
        }

        & iframe {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 120%;
          height: 120%;

          @include s-375 {
            display: none;
          }
        }
      }

      &-content {
        padding: 120px 40px 128px;
        @include s-1440 {
          padding-top: 80px;
        }
        @include s-1024 {
          padding: 60px 30px 160px;
        }
        @include s-768 {
          padding: 60px 30px 100px;
        }
        @include s-375 {
          padding: 40px 20px 225px;
        }
      }
      &-title {
        max-width: 1086px;
        @include s-1440 {
          padding-top: 20px;
        }
        @include s-1024 {
          padding-top: 16px;
        }
        &-desc {
          padding-top: 24px;
          color: var(--color-gray-2);
          @include s-1440 {
            padding-top: 20px;
          }
          @include s-1024 {
            padding-top: 16px;
          }
        }
      }
      & h1 {
        color: var(--color-white);
      }

      &-bottom {
        padding-top: 150px;
        @include s-1440 {
          padding-top: 120px;
        }
        @include s-1024 {
          padding-top: 100px;
        }
        @include s-375 {
          padding-top: 80px;
        }
      }
      & .links {
        display: flex;
        gap: 50px;
        @include s-1440 {
          gap: 40px;
        }
        @include s-1024 {
          gap: 30px;
        }
        @include s-768 {
          flex-direction: column;
          gap: 20px;
        }
        &-btn {
          width: 248px;
          min-width: 248px;
          height: 88px;
          text-transform: uppercase;
          border-radius: 8px;
          overflow: hidden;
          transition: none;
          @include s-1440 {
            width: 188px;
            min-width: 188px;
            height: 68px;
            border-radius: 5px;
          }
          @include s-1024 {
            width: 168px;
            min-width: 168px;
            height: 60px;
          }
          @include s-768 {
            width: 260px;
            min-width: 260px;
            height: 60px;
          }
          @include s-375 {
            width: 100%;
          }
          &-app {
            background-color: var(--color-black);
            img {
              object-fit: contain;
              height: 100%;
            }
          }
          &.el-button {
            background: linear-gradient(100deg, #E98000 1.83%, #FF9C25 98.07%);
            font-size: 22px;
            @include s-1440 {
              font-size: 18px;
            }
            @include s-1024 {
              font-size: 16px;
            }
          }
        }
      }
    }
    &-how {
      display: flex;
      flex-wrap: nowrap;
      @include s-768 {
        flex-wrap: wrap;
      }

      &-left {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 16px;
        padding-top: 150px;
        @include s-1440 {
          padding-top: 120px;
          padding-right: 10px;
        }
        @include s-1024 {
          padding-top: 100px;
        }
        @include s-768 {
          flex: 0 0 100%;
          max-width: 100%;
          padding-top: 80px;
          padding-right: 0;
        }
        @include s-375 {
          padding-top: 0;
        }
        &-list {
          padding-top: 50px;
          @include s-1440 {
            padding-top: 40px;
          }
          @include s-1024 {
            padding-top: 30px;
          }
          @include s-375 {
            padding-top: 20px;
          }
          li {
            padding-bottom: 40px;
            display: flex;
            align-items: flex-start;
            @include s-1440 {
              padding-bottom: 32px;
            }
            @include s-1024 {
              padding-bottom: 24px;
            }
            @include s-375 {
              padding-bottom: 16px;
            }
            &:last-of-type {
              padding-bottom: 0;
            }
          }
          & .li-count {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 42px;
            height: 42px;
            min-width: 42px;
            background-color: var(--color-gray-5);
            border-radius: 50%;
            overflow: hidden;
            color: var(--color-white);
            font-size: 20px;
            @include s-1440 {
              width: 36px;
              height: 36px;
              min-width: 36px;
              font-size: 16px;
            }
          }
          & .li-content {
            padding-left: 24px;
            @include s-1440 {
              padding-left: 20px;
            }
            @include s-1024 {
              padding-left: 16px;
            }
          }
          h6 {
            margin-top: 6px;
          }
          p {
            padding-top: 12px;
            padding-bottom: 0;
            color: var(--color-gray-7);
            @include s-1024 {
              padding-left: 8px;
            }
          }
        }
      }
      &-right {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 16px;
        @include s-1440 {
          padding-left: 10px;
        }
        @include s-768 {
          flex: 0 0 100%;
          max-width: 100%;
          order: -1;
          display: flex;
          justify-content: flex-end;
          padding-left: 0;
          height: 0;
        }
        @include s-375 {
          height: 230px;
        }
        &-wrap {
          width: 100%;
          height: auto;
          // margin-top: -266px;
          margin-top: -390px;
          @include s-1440 {
            // margin-top: -180px;
            margin-top: -380px;
          }
          @include s-1024 {
            // margin-top: -145px;
            margin-top: -160px;
          }
          @include s-768 {
            // max-width: 50%;
            max-width: 428px;
            margin-top: -428px;
          }
          @include s-375 {
            max-width: 100%;
            margin-top: -210px;
          }
          & .phone-img {
            position: relative;
            overflow: hidden;
            @include s-768 {
              max-width: 428px;
              width: 100%;
            }
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
    &-why {
      // padding-top: 240px;
      padding-top: 130px;
      @include s-1440 {
        padding-top: 200px;
      }
      @include s-1024 {
        padding-top: 160px;
      }
      @include s-375 {
        padding-top: 140px;
      }
      &-list {
        display: flex;
        flex-wrap: nowrap;
        padding-top: 50px;
        margin: 0 -16px;
        @include s-1440 {
          padding-top: 40px;
        }
        @include s-1024 {
          margin: 0 -10px;
          padding-top: 30px;
        }
        @include s-768 {
          flex-wrap: wrap;
        }
        @include s-375 {
          margin: 0;
          padding-top: 20px;
        }
        .item {
          padding: 0 16px;
          flex: 0 0 33.33%;
          max-width: 33.33%;
          @include s-1440 {
            padding: 0 10px;
          }
          @include s-768 {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 10px 20px;
          }
          @include s-375 {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0 0 20px;
          }
          &-wrap {
            // min-height: 420px;
            border-radius: 20px;
            overflow: hidden;
            background-color: var(--color-gray-8);
            padding: 50px;
            height: 100%;
            // display: flex;
            // flex-direction: column;
            // justify-content: space-between;
            @include s-1440 {
              // min-height: 350px;
              padding: 40px;
            }
            @include s-1024 {
              // min-height: 290px;
              padding: 30px;
            }
            // @include s-375 {
            //     min-height: 260px;
            // }
          }
          &-img {
            max-width: 120px;
            @include s-1440 {
              max-width: 100px;
            }
            @include s-1024 {
              max-width: 80px;
            }
            img {
              width: 100%;
              height: auto;
            }
          }
          &-bottom {
            padding-top: 50px;
            @include s-1440 {
              padding-top: 40px;
            }
            @include s-1024 {
              padding-top: 30px;
            }
          }
        }

        p {
          padding-top: 16px;
          padding-bottom: 0;
          color: var(--color-gray-7);
          @include s-1440 {
            padding-top: 12px;
          }
          @include s-1024 {
            padding-top: 8px;
          }
        }
      }
    }
    &-ready {
      padding-top: 240px;
      padding-bottom: 240px;
      @include s-1440 {
        padding-top: 200px;
        padding-bottom: 200px;
      }
      @include s-1024 {
        padding-top: 160px;
        padding-bottom: 160px;
      }
      @include s-375 {
        padding-top: 140px;
        padding-bottom: 140px;
      }
      &-wrap {
        border-radius: 20px;
        overflow: hidden;
        background: linear-gradient(99.55deg, #E98000 1.83%, #FF9C25 98.07%);
      }

      &-content {
        padding: 100px 50px 50px;
        @include s-1440 {
          padding: 80px 40px 40px;
        }
        @include s-1024 {
          padding: 60px 30px 30px;
        }
        @include s-768 {
        }
        @include s-375 {
          padding: 40px 20px 20px;
        }
      }
      &-title {
        max-width: 1380px;
        @include s-1440 {
          max-width: 1080px;
        }
        @include s-1024 {
          max-width: 870px;
        }
        h2 {
          text-transform: capitalize;
        }
        &-desc {
          padding-top: 24px;
          color: var(--color-black);
          max-width: 900px;
          font-family: 26px;
          @include s-1440 {
            padding-top: 20px;
            max-width: 650px;
            font-family: 22px;
          }
          @include s-1024 {
            padding-top: 16px;
            max-width: 560px;
            font-family: 20px;
          }
          @include s-768 {
            max-width: 100%;
          }
        }
      }

      &-bottom {
        padding-top: 100px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        @include s-1440 {
          padding-top: 80px;
        }
        @include s-1024 {
          padding-top: 60px;
        }
        @include s-375 {
          padding-top: 40px;
          flex-wrap: wrap;
        }
      }
      & .links {
        display: flex;
        gap: 50px;
        padding-bottom: 50px;
        @include s-1440 {
          gap: 40px;
          padding-bottom: 40px;
        }
        @include s-1024 {
          gap: 30px;
          padding-bottom: 30px;
        }
        @include s-768 {
          flex-direction: column;
          gap: 20px;
          padding-bottom: 40px;
        }
        @include s-375 {
          flex: 0 0 100%;
          max-width: 100%;
        }
        &-btn {
          width: 248px;
          height: 88px;
          text-transform: uppercase;
          border-radius: 8px;
          overflow: hidden;
          @include s-1440 {
            width: 188px;
            height: 68px;
            border-radius: 5px;
          }
          @include s-1024 {
            width: 168px;
            height: 60px;
          }
          @include s-768 {
            width: 260px;
            height: 60px;
          }
          @include s-375 {
            max-width: 100%;
            min-width: 100%;
            width: 100%;
          }
          &-app {
            background-color: var(--color-black);
            img {
              object-fit: contain;
              height: 100%;
            }
          }
        }
      }
      &-img {
        position: relative;
        @include s-375 {
          flex: 0 0 100%;
          max-width: 100%;
          display: flex;
          justify-content: flex-end;
        }
        &-block {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 422px;
          height: 414px;
          @include s-1440 {
            width: 320px;
            height: 316px;
          }
          @include s-1024 {
            width: 245px;
            height: 240px;
          }
          @include s-375 {
            position: relative;
            width: 100%;
            max-width: 145px;
            height: auto;
          }
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
}
