.tx-item {
  padding-bottom: 24px;

  @include s-1440 {
    padding-bottom: 16px;
  }

  @include s-1024 {
    padding-bottom: 12px;
  }

  @include s-768 {
    padding-bottom: 10px;
  }

  @include s-375 {
    padding-bottom: 8px;
  }

  &-wrap {
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid var(--color-gray-2);
    padding: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: box-shadow ease 0.3s;
    position: relative;
    overflow: hidden;
    min-height: 96px;
    gap: 32px;
    transition: box-shadow ease 0.3s;

    @include s-768 {
      min-height: 60px;
      padding: 16px;
      gap: 16px;
    }

    @include s-375 {
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    &.link {
      font-family: var(--font-reg);
    }

    &.attention {
      border: 1px solid var(--color-orange-main);
    }

    &:hover {
      box-shadow: 0 8px 12px 0 rgba(44, 47, 50, 0.1);
    }
  }

  &-info {
    display: flex;
    flex: 1;

    @include s-375 {
      flex: 1 1 100%;
    }

    &-avatar {
      width: 48px;
      height: 48px;

      @include s-1440 {
        width: 40px;
        height: 40px;

        @include s-768 {
          width: 34px;
          height: 34px;
        }

        @include s-375 {
          width: 28px;
          height: 28px;
        }
      }
    }

    &-name {
      display: flex;
      align-items: center;

      &-title {
        color: var(--color-black);
        font-family: var(--font-semi);
        font-size: 18px;
        line-height: 150%;
        padding-right: 8px;
        text-transform: capitalize;

        @include s-1440 {
          font-size: 16px;
        }

        @include s-1024 {
          font-size: 15px;
        }

        @include s-768 {
          font-size: 13px;
        }

        @include s-375 {
          font-size: 11px;
        }
      }
    }

    &-agent {
      color: var(--color-orange-main);
      font-family: var(--font-semi);
      font-size: 14px;
      line-height: 1;
      text-transform: uppercase;
      display: block;
      margin-right: 12px;
      margin-top: 2px;

      @include s-1440 {
        font-size: 13px;
        margin-right: 8px;
      }

      @include s-1024 {
        font-size: 11px;
      }

      @include s-768 {
        font-size: 10px;
      }

      @include s-375 {
        font-size: 9px;
      }
    }

    &-star {
      width: 20px;
      height: 20px;

      @include s-1440 {
        width: 16px;
        height: 16px;
      }

      @include s-1024 {
        width: 14px;
        height: 14px;
      }

      @include s-768 {
        width: 12px;
        height: 12px;
      }

      @include s-375 {
        width: 10px;
        height: 10px;
      }
    }

    &-you {
      height: 20px;
      display: block;

      @include s-1440 {
        height: 16px;
      }

      @include s-1024 {
        height: 14px;
      }

      @include s-768 {
        height: 12px;
      }

      @include s-375 {
        height: 10px;
      }
    }

    &-date {
      font-size: 14px;
      line-height: 150%;
      font-family: var(--font-reg);

      @include s-1440 {
        font-size: 13px;
      }

      @include s-1024 {
        font-size: 12px;
      }

      @include s-768 {
        font-size: 11px;
      }

      @include s-375 {
        font-size: 10px;
      }
    }

    &-desc {
      padding-left: 12px;

      @include s-768 {
        padding-left: 10px;
      }

      @include s-375 {
        padding-left: 8px;
      }
    }
  }

  &-phone {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      margin-left: 8px;
      margin-right: 8px;

      @include s-1440 {
        width: 16px;
        height: 16px;
      }

      @include s-1024 {
        width: 14px;
        height: 14px;
      }

      @include s-768 {
        width: 12px;
        height: 12px;
      }

      @include s-375 {
        width: 10px;
        height: 10px;
      }
    }

    &-num {
      font-family: var(--font-semi);
    }
  }

  &-quantity {
    flex: 0 0 26%;
    max-width: 280px;

    @include s-1440 {
      max-width: 240px;
    }

    @include s-1024 {
      max-width: 200px;
    }

    @include s-768 {
      max-width: 180px;
      min-width: 140px;
    }

    @include s-375 {
      max-width: 160px;
      min-width: unset;
    }

    &-wrap {
      display: flex;
      align-items: center;

      @include s-375 {
        min-width: 140px;
        justify-content: flex-end;
      }
    }

    .icon {
      width: 36px;
      height: 36px;

      @include s-1440 {
        width: 32px;
        height: 32px;
      }

      @include s-768 {
        width: 28px;
        height: 28px;
      }

      @include s-375 {
        width: 24px;
        height: 24px;
      }

      img {
        width: 36px;
        height: 36px;

        @include s-1440 {
          width: 32px;
          height: 32px;
        }

        @include s-768 {
          width: 28px;
          height: 28px;
        }

        @include s-375 {
          width: 24px;
          height: 24px;
        }
      }
    }

    .text {
      font-size: 16px;
      font-family: var(--font-med);
      line-height: 150%;
      padding: 6px 12px;
      border-radius: 100px;
      overflow: hidden;

      @include s-1440 {
        padding: 4px 12px;
      }

      @include s-768 {
        padding: 4px 10px;
        font-size: 13px;
      }

      @include s-375 {
        padding: 4px 8px;
      }
    }

    & .sent-quan {
      & .text {
        color: var(--color-send);
        background-color: var(--color-send-bg);
      }
    }

    & .wallet-quan {
      & .text {
        color: var(--color-deposit);
        background-color: var(--color-deposit-bg);
      }
    }
    & .buy-quan {
      & .text {
        color: var(--color-buy);
        background-color: var(--color-buy-bg);
      }
    }
    & .withdrawal-quan {
      & .text {
        color: var(--color-withdraw);
        background-color: var(--color-withdraw-bg);
      }
    }
    & .received-quan {
      & .text {
        color: var(--color-receive);
        background-color: var(--color-receive-bg);
      }
    }
  }

  &-status {
    flex: 0 0 19%;
    max-width: 208px;

    @include s-1440 {
      max-width: 162px;
    }

    @include s-1024 {
      max-width: 140px;
    }

    @include s-768 {
      max-width: 120px;
    }

    @include s-375 {
      max-width: 100px;
    }

    &-wrap {
      display: flex;
      align-items: center;
    }

    .icon {
      width: 20px;
      height: 20px;

      @include s-1440 {
        width: 16px;
        height: 16px;
      }

      @include s-768 {
        width: 14px;
        height: 14px;
      }

      @include s-375 {
        width: 12px;
        height: 12px;
      }

      img {
        width: 20px;
        height: 20px;
        display: block;

        @include s-1440 {
          width: 16px;
          height: 16px;
        }

        @include s-768 {
          width: 14px;
          height: 14px;
        }

        @include s-375 {
          width: 12px;
          height: 12px;
        }
      }
    }

    .text {
      padding-left: 6px;
      font-size: 18px;
      font-family: var(--font-med);
      line-height: 150%;

      @include s-1440 {
        padding-left: 4px;
        font-size: 16px;
      }

      @include s-768 {
        font-size: 14px;
      }

      @include s-375 {
        font-size: 12px;
      }
    }

    & .success {
      .text {
        color: var(--color-green);
      }
    }

    & .failed {
      .text {
        color: var(--color-red);
      }
    }

    & .initiated,
    & .assigned,
    & .pending {
      .text {
        color: var(--color-gray-4);
      }
    }
  }

  &-details {
    flex: 0 0 28px;

    @include s-1440 {
      flex: 0 0 24px;
    }

    @include s-1024 {
      flex: 0 0 20px;
    }

    @include s-768 {
      flex: 0 0 18px;
    }

    @include s-375 {
      flex: 0 0 16px;
    }

    &-btn {
      display: flex;
    }

    &-btn.MuiButtonBase-root {
      border: 0 none;
      background-color: transparent;
      box-shadow: none;
      outline: none;
      width: 28px;
      min-width: 28px;
      height: 28px;
      margin: 0;
      padding: 0;

      @include s-1440 {
        width: 24px;
        min-width: 24px;
        height: 24px;
      }

      @include s-1024 {
        width: 22px;
        min-width: 22px;
        height: 22px;
      }

      @include s-768 {
        width: 20px;
        min-width: 20px;
        height: 20px;
      }

      @include s-375 {
        width: 18px;
        min-width: 18px;
        height: 18px;
      }

      &:hover {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }

  & .MuiSkeleton-root {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.txagent-item {
  padding-bottom: 24px;

  @include s-1440 {
    padding-bottom: 16px;
  }

  @include s-1024 {
    padding-bottom: 12px;
  }

  @include s-768 {
    padding-bottom: 10px;
  }

  @include s-375 {
    padding-bottom: 8px;
  }

  &-wrap {
    background-color: var(--color-white);
    border-radius: 8px;
    border: 1px solid var(--color-gray-2);
    padding: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: box-shadow ease 0.3s;
    position: relative;
    overflow: hidden;
    min-height: 96px;
    gap: 32px;
    z-index: 1;
    cursor: pointer;

    @include s-1440 {
      gap: 24px;
      padding: 16px;
      min-height: 80px;
    }

    @include s-1024 {
      padding: 12px;
      min-height: 70px;
      gap: 20px;
    }

    @include s-768 {
      padding: 10px;
      min-height: 60px;
      gap: 18px;
    }

    @include s-375 {
      padding: 10px;
      min-height: 50px;
      gap: 16px;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    &.link {
      font-family: var(--font-reg);
    }

    &.attention {
      border: 1px solid var(--color-orange-main);
    }
    &:hover {
      box-shadow: 0 8px 12px 0 rgba(44, 47, 50, 0.1);
    }
  }

  &-info {
    display: flex;
    flex: 1;

    @include s-375 {
      width: 100%;
      flex: unset;
    }

    &-left {
      display: flex;
      align-items: center;
    }

    &-avatar {
      width: 48px;
      height: 48px;

      @include s-1440 {
        width: 40px;
        height: 40px;
      }

      @include s-1024 {
        width: 36px;
        height: 36px;
      }

      @include s-768 {
        width: 32px;
        height: 32px;
      }

      @include s-375 {
        width: 28px;
        height: 28px;
      }
    }

    &-name {
      display: flex;
      align-items: center;

      &-title {
        color: var(--color-black);
        font-family: var(--font-semi);
        font-size: 18px;
        line-height: 150%;
        padding-right: 8px;
        text-transform: capitalize;

        @include s-1440 {
          font-size: 16px;
        }

        @include s-1024 {
          font-size: 15px;
        }

        @include s-768 {
          font-size: 14px;
        }

        @include s-375 {
          font-size: 13px;
        }
      }
    }

    &-agent {
      color: var(--color-orange-main);
      font-family: var(--font-semi);
      font-size: 14px;
      line-height: 1;
      text-transform: uppercase;
      display: block;
      margin-right: 12px;
      margin-top: 2px;

      @include s-1440 {
        font-size: 13px;
        margin-right: 8px;
      }

      @include s-1024 {
        font-size: 11px;
      }

      @include s-768 {
        font-size: 10px;
      }

      @include s-375 {
        font-size: 9px;
      }
    }

    &-star {
      width: 20px;
      height: 20px;

      @include s-1440 {
        width: 16px;
        height: 16px;
      }

      @include s-1024 {
        width: 14px;
        height: 14px;
      }

      @include s-768 {
        width: 12px;
        height: 12px;
      }

      @include s-375 {
        width: 10px;
        height: 10px;
      }
    }

    &-bottom {
      display: flex;
      align-items: center;

      & .c-avatar {
        width: 20px;
        height: 20px;

        @include s-768 {
          width: 18px;
          height: 18px;
        }

        @include s-375 {
          width: 16px;
          height: 16px;
        }

        &-init-text {
          font-size: 8px;
          font-style: normal;
          line-height: 150%;
          color: var(--color-white);
          text-transform: uppercase;
          font-family: var(--font-reg);
        }
      }
    }

    &-ag {
      display: flex;
      align-items: center;
      padding-right: 12px;

      & .name {
        text-transform: capitalize;
        padding-left: 8px;
        font-size: 14px;
        font-family: var(--font-med);

        @include s-768 {
          font-size: 12px;
        }

        @include s-375 {
          font-size: 10px;
        }
      }

      & .text {
        padding-left: 8px;
        font-size: 14px;
        font-family: var(--font-med);

        @include s-768 {
          font-size: 12px;
        }

        @include s-375 {
          font-size: 10px;
        }
      }

      & .avatar {
        display: flex;
      }
    }
    &-date {
      font-size: 14px;
      line-height: 150%;
      font-family: var(--font-reg);
      color: var(--color-gray-6);

      @include s-1440 {
        font-size: 13px;
      }

      @include s-1024 {
        font-size: 12px;
      }

      @include s-768 {
        font-size: 11px;
      }

      @include s-375 {
        font-size: 10px;
      }
    }

    &-desc {
      padding-left: 12px;
    }
  }

  &-phone {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      margin-left: 8px;
      margin-right: 8px;

      @include s-1440 {
        width: 16px;
        height: 16px;
      }

      @include s-768 {
        width: 14px;
        height: 14px;
      }

      @include s-375 {
        width: 12px;
        height: 12px;
      }
    }
    &-num {
      font-family: var(--font-semi);
    }
  }

  &-name {
    font-family: var(--font-semi);
  }

  &-rew {
    font-family: var(--font-semi);

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &-quantity {
    flex: 0 0 26%;
    max-width: 260px;

    @include s-1440 {
      max-width: 220px;
    }

    @include s-1024 {
      max-width: 190px;
    }

    @include s-768 {
      max-width: 120px;
      flex: 1;
    }

    @include s-375 {
      flex: unset;
    }

    &-wrap {
      display: flex;
      align-items: center;
    }

    .icon {
      width: 36px;
      height: 36px;

      @include s-1440 {
        width: 32px;
        height: 32px;
      }

      @include s-1024 {
        width: 30px;
        height: 30px;
      }

      @include s-768 {
        width: 28px;
        height: 28px;
      }

      @include s-375 {
        width: 26px;
        height: 26px;
      }

      img {
        width: 36px;
        height: 36px;

        @include s-1440 {
          width: 32px;
          height: 32px;
        }

        @include s-1024 {
          width: 30px;
          height: 30px;
        }

        @include s-768 {
          width: 28px;
          height: 28px;
        }

        @include s-375 {
          width: 26px;
          height: 26px;
        }
      }
    }

    .text {
      font-size: 16px;
      font-family: var(--font-med);
      line-height: 150%;
      padding: 6px 12px;
      border-radius: 100px;
      overflow: hidden;

      @include s-1440 {
        padding: 4px 10px;
      }

      @include s-1024 {
        padding: 4px 8px;
      }

      @include s-768 {
        font-size: 14px;
      }

      @include s-375 {
        font-size: 12px;
        padding: 4px 6px;
      }
    }

    & .sent-quan {
      & .text {
        color: var(--color-send);
        background-color: var(--color-send-bg);
      }
    }

    & .wallet-quan {
      & .text {
        color: var(--color-deposit);
        background-color: var(--color-deposit-bg);
      }
    }
    & .buy-quan {
      & .text {
        color: var(--color-buy);
        background-color: var(--color-buy-bg);
      }
    }
    & .withdrawal-quan {
      & .text {
        color: var(--color-withdraw);
        background-color: var(--color-withdraw-bg);
      }
    }
    & .received-quan {
      & .text {
        color: var(--color-receive);
        background-color: var(--color-receive-bg);
      }
    }
  }

  &-status {
    flex: 0 0 19%;
    max-width: 150px;

    @include s-1440 {
      max-width: 102px;
    }

    @include s-1024 {
      max-width: 90px;
    }

    @include s-768 {
      max-width: 70px;
    }

    &-wrap {
      display: flex;
      align-items: center;
    }

    .icon {
      width: 20px;
      height: 20px;

      @include s-1440 {
        width: 16px;
        height: 16px;
      }

      @include s-1024 {
        width: 15px;
        height: 15px;
      }

      @include s-768 {
        width: 14px;
        height: 14px;
      }

      @include s-375 {
        width: 12px;
        height: 12px;
      }

      img {
        width: 20px;
        height: 20px;
        display: block;

        @include s-1440 {
          width: 16px;
          height: 16px;
        }

        @include s-1024 {
          width: 15px;
          height: 15px;
        }

        @include s-768 {
          width: 14px;
          height: 14px;
        }

        @include s-375 {
          width: 12px;
          height: 12px;
        }
      }
    }

    .text {
      padding-left: 6px;
      font-size: 18px;
      font-family: var(--font-med);
      line-height: 150%;

      @include s-1440 {
        font-size: 16px;
      }

      @include s-768 {
        font-size: 14px;
      }

      @include s-375 {
        font-size: 12px;
      }
    }

    & .success,
    & .completed {
      .text {
        color: var(--color-green);
      }
    }

    & .failed,
    & .cancelled {
      .text {
        color: var(--color-red);
      }
    }
    & .initiated,
    & .assigned,
    & .pending {
      .text {
        color: var(--color-gray-4);
      }
    }
  }

  &-details {
    flex: 0 0 28px;

    @include s-1440 {
      flex: 0 0 24px;
    }

    @include s-1024 {
      flex: 0 0 20px;
    }

    @include s-768 {
      flex: 0 0 18px;
    }

    @include s-375 {
      flex: 0 0 16px;
    }

    &-btn {
      display: block;
      width: 28px;
      height: 28px;

      @include s-1440 {
        width: 24px;
        height: 24px;
      }

      @include s-1024 {
        width: 20px;
        height: 20px;
      }

      @include s-768 {
        width: 18px;
        height: 18px;
      }

      @include s-375 {
        width: 16px;
        height: 16px;
      }
    }

    &-btn.MuiButtonBase-root {
      border: 0 none;
      background-color: transparent;
      box-shadow: none;
      outline: none;
      width: 28px;
      min-width: 28px;
      height: 28px;
      margin: 0;
      padding: 0;

      @include s-1440 {
        width: 24px;
        min-width: 24px;
        height: 24px;
      }

      @include s-768 {
        width: 22px;
        min-width: 22px;
        height: 22px;
      }

      @include s-375 {
        width: 20px;
        min-width: 20px;
        height: 20px;
      }

      &:hover {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }

  & .MuiSkeleton-root {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
