.p-careers {
  & .s {
    &-hero {
      padding-top: 104px;

      @include s-1440 {
        padding-top: 94px;
      }

      @include s-1024 {
        padding-top: 80px;
      }

      @include s-375 {
        padding-top: 60px;
      }

      &-top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include s-768 {
          flex-wrap: wrap;
        }

        &-desc {
          flex: 0 0 49%;
          margin-right: 20px;
          position: relative;
          z-index: 1;
          padding: 50px;
          color: var(--color-white);
          aspect-ratio: 874/872;
          position: sticky;
          top: 60px;
          border-radius: 20px;
          overflow: hidden;

          @include s-1440 {
            padding: 40px;
            aspect-ratio: 640/706;
          }

          @include s-1024 {
            padding: 30px;
            aspect-ratio: 460/672;
          }

          @include s-768 {
            flex: 0 0 100%;
            position: relative;
            padding: 26px;
            aspect-ratio: unset;
            flex: 0 0 100%;
            min-height: 250px;
            top: 0;
          }

          @include s-375 {
            min-height: 272px;
          }

          & h1 {
            text-transform: uppercase;
            color: var(--color-white);
          }

          & .subtitle {
            font-size: 18px;
            line-height: 150%;
            display: block;
            padding-top: 12px;

            @include s-1440 {
              font-size: 16px;
              padding-top: 8px;
            }

            @include s-1024 {
              font-size: 15px;
            }
          }

          & .bg {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 100%;
            z-index: -1;
            transform: translate(-50%, -50%) scale(1.8);

            @include s-1440 {
              transform: translate(-50%, -50%) scale(2);
            }

            @include s-1024 {
              transform: translate(-50%, -50%) scale(2.8);
            }

            @include s-768 {
              transform: translate(-50%, -50%) scale(2);
            }

            &.loaded {
              & img {
                display: none;

                @include s-375 {
                  display: block;
                }
              }
            }

            &--layout {
              display: block;
              background: linear-gradient(100deg, #e98000 1.83%, #ff9c25 98.07%);
              height: 100%;
            }

            & img {
              object-fit: cover;
              position: absolute;
              left: 50%;
              top: 50%;
              width: 100%;
              height: 100%;
              transform: translate(-50%, -50%);
              transform-origin: center;
            }

            & iframe {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              height: 100%;
              opacity: 0.2;

              @include s-375 {
                display: none;
              }
            }
          }
        }

        &-accordion {
          flex: 0 0 49%;

          @include s-768 {
            padding-top: 30px;
            flex: 0 0 100%;
          }

          &--subtitle {
            color: #6d757d;
            line-height: 150%;

            & a {
              &:hover {
                color: inherit;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  & .c-footer {
    margin-top: 200px;

    @include s-1440 {
      margin-top: 160px;
    }

    @include s-1024 {
      margin-top: 140px;
    }

    @include s-768 {
      margin-top: 120px;
    }

    @include s-375 {
      margin-top: 100px;
    }
  }
}
