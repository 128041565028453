.p-auth {
  background: #f0f1f2;

  &-flow {
    background: var(--color-white);
  }

  & .notes {
    display: block;
    padding-top: 40px;
    color: var(--color-gray-6);

    @include s-1440 {
      padding-top: 24px;
    }

    @include s-1024 {
      padding-top: 16px;
    }
    & .el-text-s {
      color: var(--color-gray-6);
    }
    & a {
      margin-left: 6px;
    }
  }

  & .s {
    &-hero {
      padding-top: 94px;
      padding-bottom: 240px;

      @include s-1440 {
        padding-bottom: 200px;
      }

      @include s-1024 {
        padding-bottom: 160px;
      }

      @include s-768 {
        padding-bottom: 160px;
      }

      @include s-375 {
        padding-bottom: 140px;
        padding-top: 20px;
      }

      &-top {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        gap: 20px;

        @include s-768 {
          flex-wrap: wrap;
        }

        &-desc {
          width: 100%;
          position: relative;
          z-index: 1;
          padding: 50px;
          color: var(--color-white);
          flex: 0 0 50%;
          max-width: 844px;
          height: 874px;
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          overflow: hidden;
          background: linear-gradient(100deg, #e98000 1.83%, #ff9c25 98.07%);

          @include s-1440 {
            max-width: 640px;
            height: 706px;
            padding: 40px;
          }

          @include s-1024 {
            max-width: 460px;
            height: 672px;
            padding: 30px;
          }

          @include s-768 {
            height: unset;
            max-width: 640px;
            flex: 0 0 100%;
            margin: 0 auto;
          }

          @include s-375 {
            padding: 20px;
            border-radius: 10px;
          }

          & h1 {
            text-transform: uppercase;
            color: var(--color-black);
          }

          &-bottom {
            margin: auto 0 0 0;

            & .item {
              padding-top: 32px;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;

              &-icon {
                width: 64px;
                min-width: 64px;
                aspect-ratio: 1/1;
                margin-right: 16px;
              }

              &-text {
                &--bot {
                  color: #2c2f32;
                  line-height: 150%;
                  padding-top: 4px;
                  display: block;
                }
              }
            }
          }
          &.tablet-inner {
            max-width: 100%;
            flex: 0 0 100%;
            height: auto;
            @include s-375 {
              padding: 25px;
            }
            h3 {
              color: var(--color-black);
              @include s-375 {
                font-size: 40px;
              }
            }
            .desc {
              padding-top: 24px;
              color: var(--color-black);
              font-size: 17px;
              line-height: 120%;
              @include s-375 {
                padding-top: 16px;
              }
            }
          }
        }

        &-form {
          width: 100%;
          padding: 50px;
          border-radius: 20px;
          flex: 0 0 50%;
          max-width: 844px;
          // overflow: hidden;
          border: solid 1px var(--color-orange-main);
          background-color: var(--color-white);
          position: relative;

          @include s-1440 {
            max-width: 640px;
            height: 706px;
            padding: 40px;
          }

          @include s-1024 {
            max-width: 460px;
            height: 672px;
            padding: 30px;
          }

          @include s-768 {
            flex: 0 0 100%;
            max-width: 640px;
            height: unset;
            margin: 0 auto;
          }

          @include s-375 {
            border-radius: 10px;
            padding: 20px;
          }

          & .el-button {
            margin-top: 40px;
            width: 100%;

            @include s-1440 {
              margin-top: 24px;
            }

            @include s-1024 {
              margin-top: 16px;
            }

            @include s-375 {
              margin-top: 6px;
            }
          }

          #recaptcha-container {
            display: flex;
            justify-content: center;

            & > div {
              margin-top: 44px;

              @include s-1440 {
                margin-top: 28px;
              }

              @include s-1024 {
                margin-top: 20px;
              }

              @include s-375 {
                margin-top: 16px;
              }
            }
          }
        }
      }
    }
  }
}
