.c-option {
  &-verify {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include s-1440 {
      gap: 12px;
    }

    @include s-1024 {
      gap: 8px;
    }

    &-btn {
      border-radius: 16px;
      border: 1px solid var(--color-gray-2);
      padding: 32px;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 100%;
      transition: box-shadow ease 0.3s;
      
      &:hover {
        box-shadow: 0px 40px 40px -50px rgba(0, 0, 0, 0.2);
      }

      & .icon {
        width: 64px;
        min-width: 64px;
        border-radius: 50%;
        overflow: hidden;
        aspect-ratio: 1;
        margin-right: 24px;
        background-color: var(--color-orange-bg);

        @include s-1440 {
          margin-right: 16px;
          max-width: 56px;
        }

        @include s-1024 {
          margin-right: 12px;
          max-width: 48px;
        }
      }

      & .description {
        width: 100%;
        text-align: left;

        & .el-text-m,
        .el-text-ss {
          display: block;
        }

        & .el-text-ss {
          display: block;
          padding-top: 8px;

          @include s-1440 {
            padding-top: 6px;
          }
        }
      }

      & .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px;
      }
    }
  }
}
