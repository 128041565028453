.p-blog {
  background: #f0f1f2;

  & .s {
    &-hero {
      padding-top: 104px;

      @include s-1440 {
        padding-top: 94px;
      }

      @include s-1024 {
        padding-top: 80px;
      }

      @include s-375 {
        padding-top: 64px;
      }

      &-top {
        min-height: 360px;
        padding: 50px;
        position: relative;
        border-radius: 20px;
        overflow: hidden;

        @include s-1440 {
          min-height: 306px;
          padding: 40px;
        }

        @include s-1024 {
          padding: 30px;
          min-height: 250px;
        }

        @include s-375 {
          min-height: 270px;
        }

        &-bg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          z-index: -1;
          border-radius: 20px;
          overflow: hidden;

          @include s-768 {
            transform: translate(-50%, -50%) scale(2);
          }

          &.loaded {
            & img {
              display: none;

              @include s-375 {
                display: block;
              }
            }
          }

          &--layout {
            display: block;
            background: linear-gradient(100deg, #E98000 1.83%, #FF9C25 98.07%);
            height: 100%;
          }

          & img {
            object-fit: cover;
            position: absolute;
            left: 50%;
            top: 50%;
            width: 120%;
            height: 120%;
            transform: translate(-50%, -50%);
            transform-origin: center;
          }

          & iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 140%;
            height: 140%;
            opacity: 0.2;

            @include s-375 {
              display: none;
            }
          }
        }

        & h1 {
          color: var(--color-white);
          text-transform: uppercase;
        }
      }
    }

    &-grid {
      &-list {
        padding-top: 50px;

        @include s-1440 {
          padding-top: 40px;
        }

        @include s-1024 {
          padding-top: 30px;
        }

        @include s-375 {
          padding-top: 28px;
        }
      }

      & .MuiPagination-root {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 50px;

        @include s-1440 {
          padding-top: 40px;
        }

        @include s-1024 {
          padding-top: 30px;
        }

        @include s-375 {
          padding-top: 26px;
        }
      }

      & .MuiButtonBase-root {
        background-color: var(--color-white);
        border: solid 1px var(--color-orange-main);
        margin: 0 8px;
        width: 42px;
        height: 42px;

        @include s-1440 {
          margin: 0 6px;
          width: 36px;
          height: 36px;
        }

        @include s-1024 {
          width: 36px;
          height: 36px;
        }

        &.Mui-selected {
          background-color: var(--color-orange-main);
          color: var(--color-white);
        }
      }
    }
  }
}
