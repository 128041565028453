// Responsive breakpoints. "Include media" settings.
$d-1920: 1919px;
$d-1440: 1439px;
$d-1024: 1023px;
$d-768: 640px;
$d-375: 374px;

@mixin s-1440 {
  @media (max-width: #{$d-1920}) {
    @content;
  }
}

@mixin s-1024 {
  @media (max-width: #{$d-1440}) {
    @content;
  }
}

@mixin s-768 {
  @media screen and (max-width: #{$d-1024}) {
    @content;
  }
}

@mixin s-375 {
  @media screen and (max-width: #{$d-768}) {
    @content;
  }
}

@mixin pad240 {
  padding-top: 240px;

  @include s-1440 {
    padding-top: 200px;
  }

  @include s-1024 {
    padding-top: 160px;
  }

  @include s-375 {
    padding-top: 140px;
  }
}

@mixin padTopBot240 {
  padding: 240px 0;

  @include s-1440 {
    padding: 200px 0;
  }

  @include s-1024 {
    padding: 160px 0;
  }

  @include s-375 {
    padding: 140px 0;
  }
}

@mixin padBot240 {
  padding-bottom: 240px;

  @include s-1440 {
    padding-bottom: 200px;
  }

  @include s-1024 {
    padding-bottom: 160px;
  }

  @include s-375 {
    padding-bottom: 140px;
  }
}

@mixin marg240 {
  margin-top: 240px;

  @include s-1440 {
    margin-top: 200px;
  }

  @include s-1024 {
    margin-top: 160px;
  }

  @include s-375 {
    margin-top: 140px;
  }
}

@mixin pad120 {
  padding-top: 120px;

  @include s-1440 {
    padding-top: 100px;
  }

  @include s-1024 {
    padding-top: 70px;
  }

  @include s-375 {
    padding-top: 40px;
  }
}

@function pxVh($f-px, $vh) {
  $k: calc($f-px/$vh * 100);
  $k: calc(round($k * 100) / 100);
  $b: vh;
  @return #{$k}#{$b};
}
@function pxVw($f-px, $vw) {
  $k: calc($f-px/$vw * 100);
  $k: calc(round($k * 100) / 100);
  $b: vw;
  @return #{$k}#{$b};
}
