.c-notifications {
  & .MuiPaper-root {
    width: 100%;
    border-radius: 0;
    margin: 0;
    box-shadow: unset;
    border: unset;
    overflow-y: auto;
    max-width: 720px;

    @include s-1440 {
      max-width: 640px;
    }

    @include s-1024 {
      max-width: 580px;
    }

    @include s-768 {
      max-width: 460px;
    }

    @include s-375 {
      max-width: 100%;
    }
  }

  &-top {
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 40px;
    position: sticky;
    top: 0;

    @include s-768 {
      padding: 20px;
    }

    &-title {
      display: block;
    }

    &-close {
      cursor: pointer;
      display: flex;
      min-width: 40px;
      width: 48px;
      height: 48px;

      @include s-1440 {
        width: 40px;
        height: 40px;
      }

      @include s-1024 {
        width: 36px;
        height: 36px;
      }

      & svg {
        & path {
          fill: var(--color-gray-5);
        }
      }

      &:hover {
        & svg {
          & path {
            fill: var(--color-orange-main);
          }
        }
      }
    }
  }

  &-content {
    padding: 0 40px 42px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow-y: auto;

    @include s-1440 {
      gap: 16px;
    }

    @include s-1024 {
      gap: 12px;
      padding: 0 30px 30px;
    }

    @include s-768 {
      padding: 0 20px 20px;
    }

    &-block {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 24px;

      @include s-1440 {
        gap: 16px;
        margin-top: 16px;
      }

      @include s-1024 {
        gap: 12px;
        margin-top: 12px;
      }

      & .notifications-content-month {
        display: block;
      }

      &.loading {
        padding-top: 24px;

        @include s-1440 {
          padding-top: 16px;
        }

        @include s-1024 {
          padding-top: 12px;
        }
      }
    }

    & .el {
      position: relative;
      padding: 16px 24px;
      transition: box-shadow ease 0.4s;
      border-radius: 8px;
      overflow: hidden;
      border: 1px solid #d3d6d8;
      cursor: pointer;

      &.unread {
        border: 1px solid var(--color-orange-light);
      }

      &:hover {
        box-shadow: 0 8px 12px 0 rgba(44, 47, 50, 0.1);
      }

      &.loading {
        pointer-events: none;
        user-select: none;
        min-height: 166px;

        @include s-1440 {
          min-height: 156px;
        }

        @include s-1024 {
          min-height: 150px;
        }
      }

      &--title {
        flex: 0 0 53%;
        margin-right: 24px;
        word-break: break-word;

        @include s-768 {
          flex: 0 0 100%;
          margin-right: 0;
          margin-bottom: 10px;
        }

        &-mark {
          cursor: pointer;
          margin-top: 8px;
          padding: 6px 16px;
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          border-radius: 16px;
          background: #ecedef;

          & img {
            display: inline-block;
            max-width: 24px;
            margin-right: 6px;
          }
        }
      }

      &--date {
        flex: 0 0 18%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      &--time {
        display: block;
        color: var(--color-gray-5);
      }

      &--status {
        display: flex;
        align-items: center;
        color: var(--color-green);

        @include s-768 {
          margin: 0 0 0 auto;
        }

        & img {
          display: block;
          // margin-right: 8px;
          width: 16px;
          height: 16px;
        }
      }

      &-btn {
        width: 40px;
        height: 40px;
        display: flex;
        flex-direction: column;
        gap: 3px;
        align-items: center;
        justify-content: center;
        transition: background-color ease 0.3s;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        // margin: 0 -8px 0 auto;
        margin: 0 -8px 0 0;
        position: relative;

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }

        &-circle {
          user-select: none;
          pointer-events: none;
          width: 3px;
          aspect-ratio: 1;
          border-radius: 50%;
          overflow: hidden;
          background-color: var(--color-gray-5);
        }

        &.active {
          background-color: var(--color-gray-2);
        }
      }

      &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include s-768 {
          flex-wrap: wrap;
        }
      }

      &-bottom {
        border-top: solid 1px var(--color-gray-2);
        padding-top: 6px;
        display: flex;
        align-items: center;
        margin-top: 12px;
        margin-bottom: -8px;

        &-img {
          display: flex;
          width: 33px;
          margin-right: 8px;
        }

        &--text {
          display: block;
          margin-right: 8px;
        }

        &-icon {
          display: flex;
          cursor: pointer;
        }
      }

      & .MuiSkeleton-root {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.MuiMenu-root.el-btn-menu {
  & .MuiPaper-root {
    border: solid 1px var(--color-gray-2);
    border-radius: 8px;
    padding: 8px 0;
    width: 140px;
    box-shadow: 0px 8px 12px 0px #2c2f3214;
    margin-top: 8px;
  }
}

// &-menu {

//   &--item {
//     display: block;
//     padding: 8px 12px;
//     color: var(--color-gray-6);
//     cursor: pointer;

//     &:hover {
//       background-color: rgba(0, 0, 0, 0.04);
//     }
//   }
// }
