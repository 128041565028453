.review {
  &-box {
    & .block {
      padding-top: 48px;

      @include s-1440 {
        padding-top: 40px;
      }

      @include s-1024 {
        padding-top: 36px;
      }
      &:first-of-type {
        padding-top: 0;
      }
      &-wrap {
        padding: 32px;
        border-radius: 16px;
        border: 1px solid var(--color-gray-2);

        @include s-1440 {
          padding: 24px;
        }

        @include s-1024 {
          padding: 20px;
        }
      }

      &-top {
        border-bottom: 1px solid var(--color-gray-2);
        padding: 0 0 16px;
        display: flex;
        justify-content: space-between;
      }

      &-title {
        color: var(--color-gray-6);
        font-size: 22px;
        font-family: var(--font-med);
        line-height: 150%;

        @include s-1440 {
          font-size: 18px;
        }

        @include s-1024 {
          font-size: 17px;
        }
      }

      &-edit {
        & .el-button {
          min-width: 86px;
          padding: 4px 14px;
          min-height: 32px;
          border-radius: 16px;
          color: var(--color-black);
          background: var(--color-gray-1);
          border: 1px solid var(--color-gray-1);
          font-size: 14px;
          font-family: var(--font-med);
          line-height: 150%;

          @include s-1440 {
            font-size: 14px;
          }

          @include s-1024 {
            font-size: 13px;
          }
          &:hover {
            background: var(--color-gray-2);
            border: 1px solid var(--color-gray-2);
            color: var(--color-black);
          }
          &:active {
            background: var(--color-gray-3);
            border: 1px solid var(--color-gray-3);
            color: var(--color-black);
          }
          &.disabled {
            background: var(--color-gray-1);
            border: 1px solid var(--color-gray-1);
            color: var(--color-gray-5);
            .el-button {
              &-icon {
                path {
                  fill: var(--color-gray-5);
                }
              }
            }
          }
          &-icon {
            margin-right: 8px;
            width: 28px;
            height: 28px;

            @include s-1440 {
              width: 24px;
              height: 24px;
            }

            @include s-1024 {
              width: 20px;
              height: 20px;
            }

            path {
              fill: var(--color-orange-dark);
            }
          }
        }
      }

      &-content {
        // padding-top: 8px;
        padding-top: 24px;

        @include s-1440 {
          padding-top: 16px;
        }

        @include s-1024 {
          padding-top: 12px;
        }

        &-item {
          padding: 12px 0 18px;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @include s-1440 {
            padding: 8px 0 11px;
          }
          &.comment {
            align-items: flex-start;
            overflow: hidden;
          }
        }

        &-label {
          line-height: 150%;
          color: var(--color-gray-6);
          // max-width: 144px;
          padding-right: 24px;
          font-size: 18px;

          @include s-1440 {
            font-size: 16px;
          }

          @include s-1024 {
            font-size: 15px;
          }
        }

        &-text {
          font-size: 18px;
          line-height: 150%;
          color: var(--color-black);
          &.name {
            text-transform: capitalize;
          }
          &.upper {
            text-transform: uppercase;
          }
          & .currency {
            text-transform: uppercase;
          }
          &.comment {
            text-align: right;
          }
          @include s-1440 {
            font-size: 16px;
          }

          @include s-1024 {
            font-size: 15px;
          }

          b {
            font-size: 22px;
            line-height: 150%;

            @include s-1440 {
              font-size: 18px;
            }

            @include s-1024 {
              font-size: 17px;
            }
          }
        }
      }
    }
  }
}
