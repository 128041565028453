.c-avatar {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;

  &-label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &-init {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    background: linear-gradient(99.55deg, #8ecae6 1.83%, #2487a7 98.07%);
    display: flex;
    justify-content: center;
    align-items: center;

    &-text {
      font-size: 16px;
      font-style: normal;
      line-height: 150%;
      letter-spacing: 1.2px;
      color: var(--color-white);
      text-transform: uppercase;
      font-family: var(--font-reg);

      @include s-375 {
        font-size: 12px;
      }
    }
  }

  &-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
