.p-account {
  & .account-edits {
    padding-bottom: 320px;
    @include s-1440 {
      padding-bottom: 200px;
    }

    @include s-1024 {
      padding-bottom: 160px;
    }

    & .el-title-h6 {
      padding: 0;
    }

    &--subtitle {
      text-align: center;
      color: var(--color-gray-6);
      padding-top: 16px;

      @include s-1440 {
        padding-top: 12px;
      }

      @include s-1024 {
        padding-top: 10px;
      }
    }

    &-form {
      padding-top: 48px;

      @include s-1440 {
        padding-top: 40px;
      }

      @include s-1024 {
        padding-top: 36px;
      }

      & .el-input-date {
        margin-bottom: 0;
        position: relative;
        .MuiInputBase-root {
          padding-right: 0;
        }
        .MuiInputAdornment-root {
          position: relative;
        }
        .MuiButtonBase-root {
          width: 28px;
          height: 28px;
          top: calc(50% - 14px);
          right: 34px;
          position: absolute;
          @include s-1440 {
            width: 24px;
            height: 24px;
            top: calc(50% - 12px);
            right: 26px;
          }
        }
        .MuiSvgIcon-root {
          width: 100%;
          height: 100%;
        }
      }

      &-btns {
        text-align: center;
        padding-top: 48px;

        @include s-1440 {
          padding-top: 40px;
        }

        @include s-1024 {
          padding-top: 36px;
        }

        & .el-link-m {
          display: inline-block;
          margin-top: 24px;

          @include s-1440 {
            margin-top: 16px;
          }

          @include s-1024 {
            margin-top: 12px;
          }
        }
      }
    }

    &-variants {
      padding: 40px;
      border-radius: 28px;
      overflow: hidden;
      border: solid 1px var(--color-gray-2);
      margin-top: 48px;

      @include s-1440 {
        margin-top: 40px;
        padding: 32px;
      }

      @include s-1024 {
        margin-top: 36px;
        padding: 28px;
      }

      & .MuiFormGroup-root {
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        align-items: flex-start;

        & .MuiFormControlLabel-root.label {
          font-family: var(--font-reg);
          font-size: 18px;
          font-style: normal;
          line-height: 150%;
          color: var(--color-gray-6);

          @include s-1024 {
            font-size: 16px;
          }

          @include s-768 {
            font-size: 15px;
          }
        }
      }

      & .MuiRadio-root {
        margin: 11px 11px 11px 0;

        &.Mui-checked {
          & .MuiSvgIcon-root {
            fill: var(--color-orange-main);
          }
        }
      }

      & textarea {
        margin-top: 8px;
        border-radius: 8px;
        overflow: hidden;
        border: solid 1px var(--color-gray-6);
        padding: 16px;
        resize: none;
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;

        &:focus {
          outline-width: 0;
        }
      }
    }
  }
}
