$labelColorDefault: #6d757d;
$labelColorFocused: #f57c00;
$borderColorDefault: #41464b;
$borderColorFocused: #f57c00;
$hoverBorderColor: #888;
$hiddenBorderColor: #ddd;
$errorBorderColor: #f00;
$errorLabelColor: #f00;
$hoverBgColor: #f8f8f8;
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
}

.MuiFormControlLabel-root {
  & .MuiSvgIcon-root {
    fill: var(--color-gray-3);
  }

  & .MuiCheckbox-root {
    padding: 9px;

    @include s-375 {
      margin-left: -9px;
    }

    &.Mui-checked {
      color: var(--color-orange-main);

      & .MuiSvgIcon-root {
        fill: var(--color-orange-main);
      }
    }
    &:hover {
      background-color: transparent;
    }
  }
  & .MuiFormControlLabel-label {
    font-size: 16px;
    color: var(--color-gray-6);
    font-family: var(--font-reg);

    @include s-1440 {
      font-size: 14px;
    }

    @include s-1024 {
      font-size: 13px;
    }

    @include s-768 {
      font-size: 12px;
    }
  }

  & a {
    color: var(--color-gray-6);
    font-family: var(--font-semi);
    margin: 0 5px;
    font-size: 16px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      box-sizing: border-box;
      border-bottom: 2px solid var(--color-gray-6);
    }

    &:hover {
      color: var(--color-gray-5);
      &::after {
        border-color: var(--color-gray-5);
      }
    }

    @include s-1440 {
      font-size: 14px;
      margin: 0 3px;
    }

    @include s-1024 {
      font-size: 13px;
    }

    @include s-768 {
      font-size: 16px;
    }

    @include s-375 {
      font-size: 12px;
    }
  }
}

.el-input-phone {
  position: relative;

  & input.form-control.invalid-number {
    border: unset;
    border-left-color: unset;
  }

  & .form-control {
    border: none;
    width: 100%;
    height: unset;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 16px 24px 16px 48px;
    min-height: 60px;
    box-sizing: border-box;
    transition: all ease-in-out 0.3s;
    width: 100%;
    background: transparent;
    letter-spacing: 0;
    line-height: 125%;
    font-family: var(--font-reg);
    @include s-1440 {
      font-size: 16px;
      padding: 16px 16px 16px 48px;
      min-height: 56px;
    }

    @include s-1024 {
      font-size: 15px;
      min-height: 54px;
      padding: 16px 12px 16px 40px;
    }
  }

  & .flag-dropdown {
    border: none;
    background-color: transparent;
  }

  & .selected-flag {
    padding: 0 0 0 16px;
    pointer-events: none;
  }

  & .arrow {
    display: none;
  }

  &-label {
    display: block;
    position: absolute;
    top: -1px;
    left: 30px;
    transform: translate(14px, 16px) scale(1);
    font-size: 16px;
    line-height: 100%;
    color: #6d757d;
    transition:
      color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
    padding: 5px;
  }

  &-fieldset {
    text-align: left;
    position: absolute;
    bottom: 0;
    right: 0;
    top: -5px;
    left: 0;
    margin: 0;
    padding: 0 8px;
    pointer-events: none;
    border-radius: inherit;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: #41464b;
    border-radius: 12px;

    @include s-1440 {
      border-radius: 8px;
    }

    & legend {
      float: unset;
      width: auto;
      overflow: hidden;
      display: block;
      padding: 0;
      height: 11px;
      font-size: 0.75em;
      visibility: hidden;
      max-width: 0.01px;
      transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      white-space: nowrap;
      font-family: var(--font-reg);
      & > span {
        padding-left: 5px;
        padding-right: 5px;
        display: inline-block;
        opacity: 0;
        visibility: visible;
        font-family: var(--font-reg);
      }
    }
  }

  &:hover {
    // & fieldset {
    //   background: #f8f8f8;
    // }
    // & .Mui-focused {
    //   & fieldset {
    //     background: transparent;
    //   }
    // }
  }

  &.Mui-focused {
    & .el-input-phone-label {
      transform: translate(-32px, -10px) scale(0.75);
    }
    & .form-control {
      background: transparent;
    }
    & .el-input-phone-fieldset {
      border-color: $borderColorFocused;
    }
    & legend {
      max-width: 87px;
      width: 100%;
    }
    // &:hover {
    //   & .form-control {
    //     background: transparent;
    //   }
    // }
  }

  &.Mui-error {
    & .el-input-phone-fieldset {
      border-color: $errorBorderColor;
    }

    & .el-input-phone-label {
      color: $errorBorderColor;
    }
  }
}

.el-input-phone-in {
  position: relative;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-right: 7px;

  & input.form-control.invalid-number {
    border: unset;
    border-left-color: unset;
  }

  & .form-control {
    border: none;
    width: 100%;
    height: unset;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 16px 24px 16px 48px;
    min-height: 60px;
    box-sizing: border-box;
    transition: all ease-in-out 0.3s;
    width: 100%;
    background: transparent;
    letter-spacing: 0;
    line-height: 125%;
    font-family: var(--font-reg);
    @include s-1440 {
      font-size: 16px;
      padding: 16px 16px 16px 48px;
      min-height: 56px;
    }

    @include s-1024 {
      font-size: 15px;
      min-height: 54px;
      padding: 16px 12px 16px 40px;
    }
  }

  & .flag-dropdown {
    border: none;
    background-color: transparent;
  }

  & .selected-flag {
    padding: 0 0 0 16px;
    pointer-events: none;
  }

  & .arrow {
    display: none;
  }

  &-label {
    display: block;
    position: absolute;
    top: -1px;
    left: 30px;
    transform: translate(14px, 16px) scale(1);
    font-size: 16px;
    line-height: 100%;
    color: #6d757d;
    transition:
      color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
    padding: 5px;
  }

  &-fieldset {
    text-align: left;
    position: absolute;
    bottom: 0;
    right: 0;
    top: -5px;
    left: 0;
    margin: 0;
    padding: 0 8px;
    pointer-events: none;
    border-radius: inherit;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: #41464b;
    border-radius: 12px;

    @include s-1440 {
      border-radius: 8px;
    }

    & legend {
      float: unset;
      width: auto;
      overflow: hidden;
      display: block;
      padding: 0;
      height: 11px;
      font-size: 0.75em;
      visibility: hidden;
      max-width: 0.01px;
      transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      white-space: nowrap;
      font-family: var(--font-reg);
      & > span {
        padding-left: 5px;
        padding-right: 5px;
        display: inline-block;
        opacity: 0;
        visibility: visible;
        font-family: var(--font-reg);
      }
    }
  }

  &:hover {
    // & fieldset {
    //   background: #f8f8f8;
    // }
    // & .Mui-focused {
    //   & fieldset {
    //     background: transparent;
    //   }
    // }
  }

  &.Mui-focused {
    & .el-input-phone-label {
      transform: translate(-32px, -10px) scale(0.75);
    }
    & .form-control {
      background: transparent;
    }
    & .el-input-phone-fieldset {
      border-color: $borderColorFocused;
    }
    & legend {
      max-width: 87px;
      width: 100%;
    }
    // &:hover {
    //   & .form-control {
    //     background: transparent;
    //   }
    // }
  }

  &.Mui-error {
    & .el-input-phone-fieldset {
      border-color: $errorBorderColor;
    }

    & .el-input-phone-label {
      color: $errorBorderColor;
    }
  }
}

.el-input-search.MuiFormControl-root,
.el-input-search {
  width: 100%;

  & .MuiInputBase-root {
    // border-color: #c4c8cb;
    border-color: var(--color-gray-3);
    color: #0b0c0d;
    background-color: #fff;
    border-radius: 20px;
    padding: 12px 8px 12px 12px;
    @include s-1440 {
      border-radius: 16px;
      padding: 8px 8px 8px 14px;
    }
    & .MuiOutlinedInput-notchedOutline {
      // border-color: #c4c8cb;
      border-color: var(--color-gray-3);
    }
    & input {
      padding: 6px 0;
      // padding: 16px 0;

      // @include s-1440 {
      //   padding: 14px 0;
      // }
    }

    &:hover {
      // border-color: #41464b;
      border-color: var(--color-gray-6);
      background: #ffffff !important;
      color: #0b0c0d;

      & .MuiOutlinedInput-notchedOutline {
        // border-color: #41464b;
        border-color: var(--color-gray-6);
      }
    }

    &:focus {
      border-color: var(--color-orange-main);
      color: #0b0c0d;
      & .MuiOutlinedInput-notchedOutline {
        border-color: var(--color-orange-main);
      }
    }

    &.Mui-error {
      border-color: var(--color-red);
      color: #0b0c0d;
      & .MuiOutlinedInput-notchedOutline {
        border-color: var(--color-red);
      }
    }

    &.Mui-focused {
      background-color: #fff;
      border-color: var(--color-orange-main);
      color: #0b0c0d;
      & .MuiOutlinedInput-notchedOutline {
        border-color: var(--color-orange-main);
      }
    }

    &.Mui-disabled {
      background-color: #fff;
      border-color: #a7acb1;
      color: #a7acb1;
    }
  }

  & .MuiInputLabel-root {
    color: #6d757d;
    &.Mui-focused {
      color: #6d757d;
    }
    &.Mui-error {
      color: var(--color-red);
    }
  }

  & .MuiInputAdornment-positionStart {
    width: 52px;
    min-height: 52px;
    padding: 16px;
    justify-content: center;
    @include s-1440 {
      width: 40px;
      min-height: 40px;
      padding: 12px;
    }
    @include s-1024 {
      width: 36px;
      min-height: 36px;
      padding: 10px;
    }
    & .MuiSvgIcon-root {
      // width: 52px;
      // height: 52px;
      // @include s-1440 {
      //   width: 40px;
      //   height: 40px;
      // }
      // @include s-1024 {
      //   width: 36px;
      //   height: 36px;
      // }
    }
  }

  & .MuiInputAdornment-positionEnd {
    width: 40px;
    height: 40px;
    color: var(--color-gray-5);
    @include s-1440 {
      width: 40px;
      height: 40px;
    }
    // @include s-1024 {
    //   width: 36px;
    //   height: 36px;
    // }

    & .MuiSvgIcon-root {
      border-radius: 12px;
      padding: 8px;
      width: 40px;
      height: 40px;
      &:hover {
        background-color: var(--color-gray-1);
      }
    }
  }

  &.el-input-phone {
    & .MuiInputAdornment-positionEnd .MuiSvgIcon-root {
      border-radius: 8px;
    }
  }
}

.el-input-date {
  width: 100%;

  & .MuiButtonBase-root {
    min-width: unset;
    padding: 0;
  }

  & .MuiInputBase-root {
    border-radius: 12px;

    @include s-1440 {
      border-radius: 8px;
    }

    &.Mui-focused {
      & .MuiOutlinedInput-notchedOutline {
        border: solid 1px var(--color-orange-main);
      }
    }
  }

  & .MuiInputLabel-root {
    &.Mui-focused {
      color: #6d757d;
    }
  }
}

.MuiPickersPopper-root {
  & button {
    min-width: unset;
    padding: 0;

    &.Mui-selected {
      background-color: var(--color-orange-main);

      &:hover,
      &:focus {
        background-color: var(--color-orange-main);
      }
    }
  }

  & .MuiPickersArrowSwitcher-button,
  & .MuiPickersCalendarHeader-switchViewButton {
    width: unset;
  }

  & .MuiPaper-root.MuiPaper-elevation {
    border: unset;
    border-radius: 8px;
    box-shadow:
      0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
}

.MuiFormControl-root,
.MuiTextField-root.el-input {
  width: 100%;

  & .MuiInputBase-root {
    border-radius: 12px;
    font-family: inherit;
    font-size: unset;
    line-height: 100%;
    letter-spacing: unset;

    @include s-1440 {
      border-radius: 8px;
    }

    &:hover {
      background-color: $hoverBgColor;
      &.Mui-focused {
        background-color: transparent;
      }
    }
  }

  & fieldset {
    // border-color: var(--color-gray-10);
    border-color: var(--color-gray-6);
  }

  & label {
    line-height: 110%;
    font-size: inherit;
    color: $labelColorDefault;
    top: 5px;
    left: 0;
    letter-spacing: 0;
    font-family: inherit;
    transition:
      left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    @include s-1440 {
      top: 4px;
      left: 6px;
    }

    @include s-768 {
      top: 0;
      font-size: 16px;
    }

    &.MuiFormLabel-filled,
    &.Mui-focused {
      left: 0;
      color: var(--color-gray-5);
    }
  }

  & .MuiInputBase-root {
    &.Mui-focused {
      & fieldset {
        border-color: $borderColorFocused;
        border-width: 1px;
      }
      &:hover {
        &.MuiInputBase-root {
          background-color: transparent;
        }
      }
    }

    &.Mui-disabled {
      & .MuiSelect-select {
        cursor: not-allowed;
        background: inherit;
      }

      & input {
        cursor: not-allowed;
      }
    }
  }

  & .MuiSelect-icon {
    right: 24px;
    width: 28px;
    height: 28px;
    top: calc(50% - 14px);

    @include s-1440 {
      right: 16px;
      width: 24px;
      height: 24px;
      top: calc(50% - 12px);
    }

    @include s-1024 {
      right: 16px;
      width: 20px;
      height: 20px;
      top: calc(50% - 10px);
    }

    &.Mui-disabled {
      opacity: 0.4;
    }
  }

  &.Mui-error {
    & label {
      color: $errorLabelColor;
    }

    & .MuiInputBase-root {
      & fieldset {
        border-color: $errorBorderColor;
      }
    }
  }

  &.Mui-disabled {
    cursor: not-allowed;
  }

  & .MuiButtonBase-root {
    width: 24px;
    height: 24px;
    &:hover {
      border: 0 none;
    }
  }

  & input {
    @include s-768 {
      padding: 14px;
      font-size: 16px;
    }
  }
}

.MuiTextField-root.el-text {
  width: 100%;

  & .MuiInputBase-root {
    border-radius: 12px;
    font-family: inherit;
    font-size: unset;
    line-height: 100%;
    letter-spacing: unset;

    @include s-1440 {
      border-radius: 8px;
    }
  }

  & .MuiSelect-select.MuiInputBase-input,
  & input {
    height: unset;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 16px 24px;
    min-height: 60px;
    box-sizing: border-box;
    transition: all ease-in-out 0.3s;
    width: 100%;
    background: transparent;

    @include s-1440 {
      font-size: 16px;
      padding: 16px;
      min-height: 56px;
    }

    @include s-1024 {
      font-size: 15px;
      min-height: 54px;
      padding: 12px 16px;
    }
  }

  & fieldset {
    border-color: var(--color-gray-6);
  }

  & label {
    // font-size: 18px;
    line-height: 110%;
    font-size: inherit;
    color: $labelColorDefault;
    top: 5px;
    left: 10px;
    letter-spacing: 0;
    font-family: inherit;
    transition:
      left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    @include s-1440 {
      top: 4px;
      left: 6px;
    }

    &.MuiFormLabel-filled,
    &.Mui-focused {
      left: 0;
      color: var(--color-gray-5);
    }
  }

  & .MuiInputBase-root {
    &.Mui-focused {
      & fieldset {
        border-color: $borderColorFocused;
        border-width: 1px;
      }
    }

    &.Mui-disabled {
      & .MuiSelect-select {
        cursor: not-allowed;
        background: inherit;
      }
    }
  }

  & .MuiSelect-icon {
    right: 24px;
    width: 28px;
    height: 28px;
    top: calc(50% - 14px);

    @include s-1440 {
      right: 16px;
      width: 24px;
      height: 24px;
      top: calc(50% - 12px);
    }

    @include s-1024 {
      right: 16px;
      width: 20px;
      height: 20px;
      top: calc(50% - 10px);
    }

    &.Mui-disabled {
      opacity: 0.4;
    }
  }

  &.Mui-error {
    & label {
      color: $errorLabelColor;
    }

    & .MuiInputBase-root {
      & fieldset {
        border-color: $errorBorderColor;
      }
    }
  }

  &:hover {
    // & .MuiSelect-select fieldset,
    // & input fieldset{
    //   background: #f8f8f8;
    // }
  }

  &.Mui-disabled {
    & label {
      color: var(--color-gray-4);
    }

    & .MuiInputBase-root {
      & fieldset {
        border-color: var(--color-gray-4);
      }
    }
  }
}

.el-input-select {
  width: 100%;

  &.MuiInputBase-root {
    border-radius: 12px;
    font-family: inherit;
    font-size: unset;
    line-height: 100%;
    letter-spacing: unset;
    height: 48px;
    display: flex;
    align-items: center;

    @include s-1440 {
      border-radius: 8px;
    }
  }

  & fieldset {
    border-color: var(--color-gray-6);
  }

  & label {
    line-height: 110%;
    font-size: inherit;
    color: $labelColorDefault;
    top: 5px;
    left: 0;
    letter-spacing: 0;
    font-family: inherit;
    transition:
      left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    @include s-1440 {
      top: 4px;
      left: 6px;
    }

    @include s-768 {
      top: 0;
      left: 6px;
      font-size: 16px;
    }

    &.MuiFormLabel-filled,
    &.Mui-focused {
      left: 0;
      color: var(--color-gray-5);

      @include s-375 {
        left: -4px;
        top: 3px;
      }
    }
  }

  & .MuiInputBase-root {
    &.Mui-focused {
      & fieldset {
        border-color: $borderColorFocused;
        border-width: 1px;
      }
    }

    &.Mui-disabled {
      & .MuiSelect-select {
        cursor: not-allowed;
        background: inherit;
      }

      & input {
        cursor: not-allowed;
      }
    }
  }

  & .MuiSelect-icon {
    right: 24px;
    width: 28px;
    height: 28px;
    top: calc(50% - 14px);

    @include s-1440 {
      right: 16px;
      width: 24px;
      height: 24px;
      top: calc(50% - 12px);
    }

    @include s-1024 {
      right: 16px;
      width: 20px;
      height: 20px;
      top: calc(50% - 10px);
    }

    &.Mui-disabled {
      opacity: 0.4;
    }
  }
  &.Mui-focused {
    & fieldset {
      border-color: $borderColorFocused;
      border-width: 1px;
    }
  }
  &.Mui-error {
    & label {
      color: $errorLabelColor;
    }

    & .MuiInputBase-root {
      & fieldset {
        border-color: $errorBorderColor;
      }
    }
  }

  &.Mui-disabled {
    cursor: not-allowed;
  }

  &:hover {
    & .MuiSelect-select,
    & .MuiInputBase-root {
      // background: #f8f8f8;
    }
  }
}

.auth-content {
  & .MuiFormControl-root,
  & .MuiTextField-root.el-input {
    width: 100%;

    & .MuiInputBase-root {
      border-radius: 12px;
      font-family: inherit;
      font-size: unset;
      line-height: 100%;
      letter-spacing: unset;

      @include s-1440 {
        border-radius: 8px;
      }
    }

    & fieldset {
      border-color: var(--color-gray-6);
    }

    & label {
      line-height: 110%;
      font-size: inherit;
      color: $labelColorDefault;
      top: 5px;
      left: 0;
      letter-spacing: 0;
      font-family: inherit;
      transition:
        left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

      @include s-1440 {
        top: 4px;
        left: 6px;
      }

      @include s-375 {
        font-size: 10px;
      }

      &.MuiFormLabel-filled,
      &.Mui-focused {
        left: 0;
        color: var(--color-gray-5);
      }
    }

    & .MuiInputBase-root {
      &.Mui-focused {
        & fieldset {
          border-color: $borderColorFocused;
          border-width: 1px;
        }
        &:hover {
          &.MuiInputBase-root {
            background-color: transparent;
          }
        }
      }

      &.Mui-disabled {
        & .MuiSelect-select {
          cursor: not-allowed;
          background: inherit;
        }

        & input {
          cursor: not-allowed;
        }
      }
    }

    & .MuiSelect-icon {
      right: 24px;
      width: 28px;
      height: 28px;
      top: calc(50% - 14px);

      @include s-1440 {
        right: 16px;
        width: 24px;
        height: 24px;
        top: calc(50% - 12px);
      }

      @include s-1024 {
        right: 12px;
        width: 20px;
        height: 20px;
        top: calc(50% - 10px);
      }

      &.Mui-disabled {
        opacity: 0.4;
      }
    }

    &.Mui-error {
      & label {
        color: $errorLabelColor;
      }

      & .MuiInputBase-root {
        & fieldset {
          border-color: $errorBorderColor;
        }
      }
    }

    &.Mui-disabled {
      cursor: not-allowed;
    }

    &:hover {
      & .MuiSelect-select,
      & .MuiInputBase-root {
        // background: #f8f8f8;
      }
    }
    .MuiButtonBase-root {
      right: 24px;
      width: 28px;
      height: 28px;

      @include s-1440 {
        right: 16px;
        width: 24px;
        height: 24px;
      }

      @include s-1024 {
        right: 12px;
        width: 20px;
        height: 20px;
      }
    }
  }
}
