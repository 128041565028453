.amount {
  &-box {
    padding-top: 48px;
    text-align: center;

    @include s-1440 {
      padding-top: 40px;
    }

    & .contact {
      border-radius: 8px;
      border: 1px solid #d3d6d8;
      background: #fff;
      padding: 8px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      display: inline-flex;
      margin: 0 auto 0;

      &-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;

        &-img {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-size: 16px;
          border-radius: 50%;
          overflow: hidden;
          width: 48px;
          height: 48px;
          text-transform: uppercase;
          position: relative;
          overflow: hidden;

          @include s-1440 {
            width: 40px;
            height: 40px;
          }

          @include s-1024 {
            width: 40px;
            height: 40px;
          }

          @include s-375 {
            width: 30px;
            height: 30px;
          }

          & .MuiSkeleton-root {
            inset: 0;
            position: absolute;
            width: 100%;
            height: 100%;
          }

          & .label-agent {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          }

          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.phone {
              width: 24px;
              height: 24px;
            }
          }

          & .wallet {
            width: 27px;
            height: 27px;

            @include s-1440 {
              width: 24px;
              height: 24px;
            }

            @include s-1024 {
              width: 22px;
              height: 22px;
            }
          }

          &.empty {
            background: linear-gradient(99.55deg, #8ecae6 1.83%, #2487a7 98.07%);
          }
        }

        &-details {
          display: flex;
          align-items: center;
          padding-left: 16px;

          @include s-1440 {
            padding-left: 12px;
          }

          @include s-1024 {
            padding-left: 10px;
          }

          & .name {
            color: #0b0c0d;
            display: block;
            padding-right: 8px;
            text-transform: capitalize;

            &-status {
              display: block;
              font-size: 13px;
              font-family: var(--font-bold);
              color: var(--color-orange-main);
              text-transform: uppercase;

              &-icon {
                display: inline-block;
                width: 16px;
                height: 16px;

                & img {
                  display: block;
                }
              }
            }
          }
        }

        &-link {
          font-family: var(--font-bold);
          text-decoration: underline;
          flex: 0 0 14%;
        }
      }

      .el-button-icon {
        width: 24px;
        height: 24px;
        padding: 6px;
        background-color: transparent;
        min-width: 24px;
        margin-left: 40px;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    & .item {
      padding-top: 48px;
      display: flex;
      justify-content: center;
      @include s-1440 {
        padding-top: 40px;
      }

      @include s-1024 {
        padding-top: 36px;
      }
      &-wrap {
        width: 100%;
      }
      &-count {
        font-size: 44px;
        font-family: var(--font-med);
        line-height: 120%;
        color: var(--color-gray-4);
        position: relative;
        display: flex;
        justify-content: center;
        &.active {
          color: var(--color-black);
        }
        &-input {
          border: 0;
          .MuiOutlinedInput-root {
            border: 0;
            &:hover {
              border: 0;
            }
          }
          &:hover {
            .MuiOutlinedInput-root {
              background-color: transparent;
            }
          }
          .MuiOutlinedInput-notchedOutline {
            border: 0;
          }
          .MuiInputBase-input {
            font-size: 44px;
            font-family: var(--font-med);
            line-height: 120%;
            color: var(--color-black);
            padding: 0;
            text-align: center;

            @include s-768 {
              font-size: 38px;
            }

            &::placeholder {
              color: var(--color-gray-4);
              opacity: 1;
            }
            &::-webkit-input-placeholder {
              color: var(--color-gray-4);
              opacity: 1;
            }
            &::-moz-placeholder {
              color: var(--color-gray-4);
              opacity: 1;
            }
            &:-moz-placeholder {
              color: var(--color-gray-4);
              opacity: 1;
            }
            &:-ms-input-placeholder {
              color: var(--color-gray-4);
              opacity: 1;
            }
            &:focus {
              &::-webkit-input-placeholder {
                color: transparent;
              }
              &::-moz-placeholder {
                color: transparent;
              }
              &:-moz-placeholder {
                color: transparent;
              }
              &:-ms-input-placeholder {
                color: transparent;
              }
            }
          }
        }
      }

      &-balance {
        text-align: center;
        // font-size: 13px;
        // line-height: 150%;
        color: var(--color-gray-5);

        &-count {
          text-transform: uppercase;
          // font-family: var(--font-med);
        }
      }

      &.error {
        & .MuiInputBase-input {
          color: var(--color-send);
        }
      }
    }

    & .select {
      padding-top: 16px;
      text-align: center;
      max-width: 142px;
      margin: 0 auto;
      @include s-1440 {
        max-width: 112px;
      }
      @include s-1024 {
        max-width: 106px;
      }

      .el-input-select {
        fieldset {
          border-color: var(--color-gray-2);
          background-color: transparent;
        }
        &.MuiInputBase-root {
          border-radius: 28px;
          padding: 12px;
          height: 44px;
          @include s-1440 {
            border-radius: 24px;
            padding: 6px 8px;
            height: 36px;
          }
          @include s-1024 {
            height: 35px;
          }
          .MuiSelect-select {
            padding: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 16px;
            font-family: var(--font-reg);
            background-color: transparent;
            img.MuiSelect-icon {
              margin-right: 12px;
              width: 28px;
              height: 28px;
              @include s-1440 {
                margin-right: 8px;
                width: 24px;
                height: 24px;
              }
              @include s-1024 {
                width: 20px;
                height: 20px;
              }
            }
          }
          svg.MuiSelect-icon {
            right: 12px;
            width: 20px;
            height: 20px;
            top: calc(50% - 10px);
            @include s-1440 {
              right: 8px;
              width: 16px;
              height: 16px;
              top: calc(50% - 8px);
            }
          }
        }
        &.Mui-focused fieldset {
          border-color: var(--color-orange-main);
          border-width: 1px;
        }
      }
    }

    & .flow-message {
      margin-top: 48px;

      @include s-1440 {
        margin-top: 40px;
      }

      @include s-1024 {
        margin-top: 36px;
      }
    }

    & .flow-estimate {
      margin-top: 48px;

      @include s-1440 {
        margin-top: 40px;
      }

      @include s-1024 {
        margin-top: 36px;
      }

      @include s-375 {
        text-align: left;
      }
    }

    & .transaction {
      &-cost {
        padding-top: 40px;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--color-gray-4);
        &-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include s-768 {
            flex-wrap: wrap;
          }
        }
        &-left {
          display: flex;
          flex: 1;
          align-items: center;

          @include s-768 {
            padding-bottom: 8px;
          }
        }
        &-count {
          font-family: var(--font-med);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
        }
        &-gray {
          padding-left: 8px;
          font-size: 13px;
          color: var(--color-gray-5);
        }
        &-label {
          padding-left: 8px;
          font-size: 18px;
          line-height: 150%;
          color: var(--color-gray-7);
          @include s-1440 {
            font-size: 16px;
          }

          @include s-1024 {
            font-size: 15px;
          }

          @include s-768 {
            margin: 0 0 0 auto;
          }
        }

        &-switch {
          max-width: 556px;

          @include s-1440 {
            max-width: 492px;
          }

          @include s-768 {
            width: 100%;
          }

          & .MuiList-root {
            padding: 6px;
            overflow: hidden;
            display: flex;
            border-radius: 28px;
            border: solid 1px #d3d6d8;
            gap: 4px;

            @include s-1440 {
              padding: 4px;
            }

            @include s-768 {
              border: none;
              background-color: var(--color-gray-1);
              padding: 12px;
              gap: 12px;
            }
          }

          & .MuiListItem-root {
            overflow: hidden;
            padding: 6px 16px;
            border-radius: 28px;
            font-size: 16px;
            line-height: 150%;
            justify-content: center;
            color: var(--color-gray-7);
            cursor: pointer;
            font-family: var(--font-reg);
            width: 88px;

            @include s-1440 {
              padding: 4px 12px;
              font-size: 14px;
              width: 67px;
            }

            @include s-1024 {
              font-size: 13px;
              width: 57px;
            }

            @include s-768 {
              width: 50%;
              padding: 16px;
              border: 1px solid #d3d6d8;
              opacity: 0.5;
              justify-content: flex-start;
              align-items: flex-start;
              font-size: 16px;
              font-family: var(--font-med);
              display: flex;
              flex-wrap: wrap;
              background: #ffffff;
            }

            &.active {
              background: #ecedef;
              font-family: var(--font-reg);

              @include s-768 {
                opacity: 1;
                background: #ffffff;
              }
            }

            &:hover {
              background: #ecedef;

              @include s-768 {
                background: #ffffff;
              }
            }

            & .transaction-cost-gray {
              padding: 0;
              width: 100%;
            }

            & .MuiSelect-icon {
              width: 24px;
              aspect-ratio: 1;
              margin: 0 3px 4px 0;
            }
          }
        }
      }
      &-total {
        padding-top: 24px;

        &-wrap {
          display: flex;
          justify-content: space-between;
        }

        &-label {
          font-size: 18px;
          color: var(--color-black);
        }

        &-count {
          display: flex;
          align-items: center;
          text-transform: uppercase;
        }

        &-equivalent {
          color: var(--color-gray-6);
          font-size: 16px;
          @include s-1440 {
            font-size: 14px;
          }

          @include s-1024 {
            font-size: 13px;
          }
          span {
            font-family: var(--font-med);
          }
        }

        &-item {
          font-size: 18px;
          line-height: 150%;
          @include s-1440 {
            font-size: 16px;
          }

          @include s-1024 {
            font-size: 15px;
          }
          &.bold {
            font-family: var(--font-bold);
          }
        }
        &-gray {
          padding-right: 5px;
          padding-left: 6px;
          font-size: 14px;
          line-height: 150%;
          color: var(--color-gray-5);
          @include s-1440 {
            font-size: 13px;
          }

          @include s-1024 {
            font-size: 12px;
          }
        }
      }
      &-reward {
        padding-top: 40px;
        &-wrap {
          padding: 8px 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 8px;
          background-color: var(--color-gray-1);
        }
        &-left {
          display: flex;
          align-items: center;
          img {
            width: 55px;
          }
        }
        &-count {
          padding-left: 16px;
          font-size: 14px;
          font-family: var(--font-bold);
          line-height: 150%;
        }
        &-note {
          display: flex;
          align-items: center;

          &-text {
            font-size: 14px;
            line-height: 150%;
            color: var(--color-black);
            padding-right: 8px;
          }
          &-icon {
            img {
              display: block;
            }
          }
        }
      }
    }

    & .comment {
      position: relative;
      padding-top: 48px;

      @include s-1440 {
        padding-top: 40px;
      }

      @include s-1024 {
        padding-top: 36px;
      }
      .MuiInputBase-root {
        padding-right: 60px;
      }

      &-counter {
        position: absolute;
        right: 16px;
        bottom: 0;
        font-size: 14px;
        color: var(--color-gray-5);
      }

      &-text {
        & .MuiFormControl-root {
          font-size: 16px;
        }
      }

      textarea {
        line-height: 150%;

        @include s-375 {
          font-size: 12px;
        }
      }
    }
  }
}
