.Toastify__toast {
  color: var(--color-black);
  box-shadow: unset;
  font-size: 18px;
  text-align: center;
  padding: 24px 16px;

  @include s-1440 {
    font-size: 16px;
  }

  @include s-1024 {
    font-size: 15px;
  }

  @include s-768 {
    font-size: 14px;
    padding: 18px 10px;
  }

  @include s-375 {
    font-size: 13px;
  }

  &-container {
    width: 832px;

    @include s-768 {
      width: 632px;
    }

    @include s-375 {
      width: 100%;
    }
  }

  &-body {
    padding: 0;

    @include s-768 {
      max-width: 500px;
      margin: 0 auto;
    }

    @include s-375 {
      max-width: 250px;
    }
  }

  &-icon {
    display: none;
  }

  &-close {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
  }

  &--success {
    background: #6ed28a;
  }

  &--error {
    background: var(--color-red-2);
  }

  &--info {
    background: #61bdff;
  }

  &--warning {
    background: #ffde69;
  }
}
