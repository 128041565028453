.MuiStepper-root {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 12px;
  z-index: 12;
  display: flex;
  justify-content: center;

  @include s-375 {
    bottom: 0;
    top: unset;
    background-color: white;
    width: 100%;
    padding: 20px;
  }

  &.steps-agent {
    & .MuiStep-root {
      width: 80px;
    }
  }
  & .MuiStep-root {
    width: 270px;

    @include s-1440 {
      width: 190px;
    }

    @include s-1024 {
      width: 160px;
    }

    @include s-768 {
      width: 80px;
    }

    &.current {
      & .MuiStepLabel-iconContainer {
        & svg circle {
          stroke: var(--color-orange-main);
          fill: transparent;
        }
      }

      & .MuiStepConnector-line {
        border-color: var(--color-orange-main);
      }
    }

    &.Mui-completed {
      & .MuiStepLabel-iconContainer {
        & svg circle {
          stroke: var(--color-orange-main);
          fill: var(--color-orange-main);
        }
      }

      & .MuiStepConnector-line {
        border-color: var(--color-orange-main);
      }
    }
  }

  & .MuiStepLabel-root.MuiStepLabel-alternativeLabel {
    flex-direction: column-reverse;
  }

  & .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 0;
    margin-bottom: 4px;
    font-size: 14px;
    font-family: var(--font-reg);
    line-height: 150%;
    display: inline-block;

    @include s-1440 {
      font-size: 13px;
    }

    @include s-1024 {
      font-size: 12px;
    }

    @include s-768 {
      font-size: 10px;
    }
  }

  & .MuiStepConnector-root {
    left: calc(-50% + 5px);
    right: calc(50% + 5px);
    top: unset;
    bottom: 4px;
  }

  & .MuiStepLabel-iconContainer {
    & svg circle {
      stroke: var(--color-gray-2);
      fill: var(--color-gray-2);
    }
  }
}
