.c-contact {
  &-box {
    &-wrap {
      padding-top: 48px;

      @include s-1440 {
        padding-top: 40px;
      }

      @include s-1024 {
        padding-top: 36px;
      }
      .el-text-m {
        color: var(--color-gray-6);
      }
    }

    &--end {
      display: block;
      text-align: center;
      padding-top: 48px;

      @include s-1440 {
        padding-top: 40px;
      }

      @include s-1024 {
        padding-top: 36px;
      }
    }

    &-el {
      padding-top: 48px;

      @include s-1440 {
        padding-top: 40px;
      }

      @include s-1024 {
        padding-top: 36px;
      }
      &:last-of-type {
        padding-bottom: 40px;
      }
      &.flow {
        padding-top: 24px;
        @include s-1440 {
          padding-top: 16px;
        }

        @include s-1024 {
          padding-top: 12px;
        }
      }

      &.recent {
        padding: 0;
      }
      &.remit {
        & .contact {
          border-radius: 12px;
          @include s-1440 {
            border-radius: 8px;
          }

          @include s-1024 {
            border-radius: 8px;
          }
          &-info {
            align-items: center;
            &--img-item {
              img {
                pointer-events: none;
              }
            }
          }
        }
      }
      & .contact {
        margin-top: 24px;
        border-radius: 28px;
        border: 1px solid #d3d6d8;
        background: #fff;
        padding: 32px 48px 32px 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: box-shadow ease 0.3s;
        position: relative;
        overflow: hidden;
        min-height: 128px;

        @include s-1440 {
          margin-top: 20px;
          padding: 24px 40px 24px 24px;
          min-height: 96px;
        }

        @include s-1024 {
          margin-top: 16px;
          padding: 20px 36px 20px 20px;
          min-height: 86px;
        }

        @include s-768 {
          border-radius: 18px;
        }

        @include s-375 {
          padding: 12px;
          min-height: 66px;
        }

        &:hover {
          box-shadow: 0px 40px 40px -50px rgba(0, 0, 0, 0.3);
        }

        &-info {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

          @include s-375 {
            align-items: center;
          }

          &-img {
            width: 80px;
            height: 80px;

            @include s-1440 {
              width: 64px;
              height: 64px;
            }

            @include s-1024 {
              width: 56px;
              height: 56px;
            }

            @include s-375 {
              width: 46px;
              height: 46px;
            }

            &-item {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          &-name {
            display: inline-flex;
            align-items: center;

            &-agent {
              color: var(--color-orange-main);
              font-family: var(--font-semi);
              font-size: 14px;
              line-height: 150%;
              text-transform: uppercase;
              display: block;
              margin-left: 12px;

              @include s-1440 {
                font-size: 13px;
                margin-left: 8px;
              }

              @include s-1024 {
                font-size: 11px;
              }
            }

            &-star {
              display: block;
              margin-left: 12px;
              width: 20px;
              height: 20px;

              @include s-1440 {
                margin-left: 8px;
              }
              img {
                width: 100%;
                height: 100%;
              }
            }

            &-kyc {
              display: flex;
              padding: 4px 8px;
              border-radius: 8px;
              overflow: hidden;
              background-color: var(--color-blue-2);
              margin-left: 8px;
              align-items: center;

              & img {
                margin-right: 12px;
                width: 16px;
                height: 16px;

                @include s-1440 {
                  margin-right: 8px;
                }
              }

              &.yellow {
                background-color: var(--color-unregistred);
              }
            }
          }
          &-details {
            display: flex;
            flex-direction: column;
            padding-left: 16px;

            @include s-1440 {
              padding-left: 12px;
            }

            @include s-1024 {
              padding-left: 10px;
            }

            & .name {
              color: #0b0c0d;
              line-height: 150%;
              font-family: var(--font-semi);
              font-size: 18px;
              text-transform: capitalize;

              @include s-1440 {
                font-size: 16px;
              }

              @include s-1024 {
                font-size: 14px;
              }

              @include s-375 {
                font-size: 13px;
              }
            }
            & .address {
              display: flex;
              align-items: center;
              font-size: 16px;
              color: var(--color-gray-6);
              font-family: var(--font-med);
              cursor: copy;

              @include s-1440 {
                font-size: 14px;
              }

              @include s-1024 {
                font-size: 13px;
              }

              &-copy {
                margin-left: 8px;
                width: 20px;
                height: 20px;

                @include s-1440 {
                  width: 16px;
                  height: 16px;
                }
              }
            }
            & a {
              font-size: 16px;
              font-family: var(--font-med);
              color: var(--color-gray-6);
              @include s-1440 {
                font-size: 14px;
              }

              @include s-1024 {
                font-size: 13px;
              }
            }
          }
          &-right {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          &-link {
            text-decoration: none;
            color: var(--color-gray-6);
            display: flex;
            align-items: center;
            padding: 0;
            text-transform: initial;
            background-color: transparent !important;
            &:hover,
            &:active {
              border: 0 none;
              background-color: transparent;
            }
            .icon {
              margin-right: 8px;
              width: 16px;
              height: 16px;
            }

            &:hover {
              background-color: transparent;
              color: var(--color-gray-5);
              span {
                color: var(--color-gray-5);
                &::after {
                  border-color: var(--color-gray-5);
                }
              }
            }

            span {
              font-family: var(--font-semi);
              position: relative;
              line-height: 140%;
              &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                box-sizing: border-box;
                border-bottom: 2px solid var(--color-gray-6);
              }
            }
            .MuiTouchRipple-root {
              display: none !important;
            }
          }
        }

        & .el-button {
          margin-left: 80px;
          width: unset;
          min-width: 100px;

          @include s-1440 {
            margin-left: 64px;
          }

          @include s-1024 {
            margin-left: 56px;
          }

          @include s-375 {
            margin-left: 10px;
          }
        }

        & .MuiSkeleton-root {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
}
