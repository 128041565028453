.p-account {
  & .contacts {
    &-search {
      padding: 32px;
      border-radius: 28px;
      background: #ecedef;

      @include s-1024 {
        padding: 28px;
      }

      &-field {
        padding-top: 32px;

        @include s-1440 {
          padding-top: 24px;
        }

        @include s-1024 {
          padding-top: 20px;
        }
      }
    }

    &-filters {
      max-width: 556px;
      padding-top: 48px;

      @include s-1440 {
        max-width: 492px;
        padding-top: 40px;
      }

      @include s-1024 {
        padding-top: 36px;
      }

      & .MuiList-root {
        padding: 4px;
        overflow: hidden;
        display: flex;
        border-radius: 28px;
        border: solid 1px #d3d6d8;
        gap: 4px;

        @include s-1440 {
          padding: 4px;
        }
      }

      & .MuiListItem-root {
        overflow: hidden;
        padding: 8px;
        border-radius: 28px;
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
        justify-content: center;
        text-transform: capitalize;
        cursor: pointer;

        @include s-1440 {
          padding: 6px;
          font-size: 16px;
        }

        @include s-1024 {
          font-size: 15px;
        }

        &.active {
          background: #ecedef;
          font-family: var(--font-med);
        }

        &:hover {
          background: #ecedef;
        }
      }

      &.agents-flow {
        max-width: 188px;
      }
    }
  }
}
