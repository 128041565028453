.c-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 12;
  user-select: none;
  pointer-events: none;
  opacity: 0;
  transition: opacity ease 0.2s;
  background-color: #ffffff;

  &-anim {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 160px;
    height: 160px;

    @include s-1440 {
      width: 120px;
      height: 120px;
    }

    @include s-1024 {
      width: 100px;
      height: 100px;
    }

    @include s-768 {
      width: 80px;
      height: 80px;
    }

    & video {
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
    }
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}
