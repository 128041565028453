:root {
  --font-reg: 'neue_montreal_regular', sans-serif;
  --font-bold: 'neue_montreal_bold', sans-serif;
  --font-med: 'neue_montreal_medium', sans-serif;
  --font-semi: 'neue_montreal_semibold', sans-serif;
}

h1,
.el-title-h1 {
  font-family: var(--font-reg);
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;

  @include s-1440 {
    font-size: 96px;
  }

  @include s-1024 {
    font-size: 68px;
  }

  @include s-768 {
    font-size: 58px;
  }

  @include s-375 {
    font-size: 48px;
  }

  & .orange {
    color: var(--color-orange-light1);
  }
}

h3,
.el-title-h3 {
  font-family: var(--font-reg);
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  @include s-1440 {
    font-size: 80px;
  }

  @include s-1024 {
    font-size: 60px;
  }

  @include s-768 {
    font-size: 48px;
  }

  @include s-375 {
    font-size: 38px;
  }
}

h4,
.el-title-h4 {
  font-family: var(--font-med);
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  @include s-1440 {
    font-size: 48px;
  }

  @include s-1024 {
    font-size: 40px;
  }

  @include s-768 {
    font-size: 32px;
  }

  @include s-375 {
    font-size: 28px;
  }
}

h5,
.el-title-h5 {
  font-family: var(--font-reg);
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  @include s-1440 {
    font-size: 40px;
  }

  @include s-1024 {
    font-size: 32px;
  }

  @include s-768 {
    font-size: 26px;
  }

  &.med {
    font-family: var(--font-med);
    font-weight: 500;
  }
}

h6,
.el-title-h6 {
  font-family: var(--font-reg);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  @include s-1440 {
    font-size: 20px;
  }

  @include s-1024 {
    font-size: 16px;
  }

  @include s-768 {
    font-size: 18px;
  }

  &.med {
    font-family: var(--font-med);
    font-weight: 500;
  }

  & .orange {
    color: var(--color-orange-main);
  }
}

.el-title-h6-med {
  font-family: var(--font-med);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  @include s-1440 {
    font-size: 20px;
  }

  @include s-1024 {
    font-size: 18px;
  }

  @include s-375 {
    font-size: 16px;
  }
}

.el-title {
  &-error {
    text-align: center;
  }
}

.el-text {
  font-family: var(--font-reg);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @include s-1440 {
    font-size: 22px;
  }

  @include s-1024 {
    font-size: 18px;
  }

  @include s-768 {
    font-size: 16px;
  }

  @include s-375 {
    font-size: 14px;
  }
}

.el-text-l {
  font-size: 22px;
  line-height: 150%;
  font-family: var(--font-reg);
  font-style: normal;

  @include s-1440 {
    font-size: 18px;
  }

  @include s-1024 {
    font-size: 18px;
  }

  &.med {
    font-family: var(--font-med);
  }
  &.semi {
    font-family: var(--font-semi);
  }
}

.el-text-m {
  font-family: var(--font-reg);
  font-size: 18px;
  font-style: normal;
  line-height: 150%;

  @include s-1440 {
    font-size: 16px;
  }

  &.upper {
    text-transform: uppercase;
  }

  &.med {
    font-family: var(--font-med);
  }

  &.bold {
    font-family: var(--font-bold);
  }

  &.semi {
    font-family: var(--font-semi);
  }

  &.user {
    line-height: 120%;
  }
}

.el-text-s {
  font-family: var(--font-reg);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @include s-1440 {
    font-size: 14px;
  }

  &.semi {
    font-family: var(--font-semi);
  }

  &.med {
    font-family: var(--font-med);
  }
}

.el-text-xs {
  font-family: var(--font-reg);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @include s-1440 {
    font-size: 13px;
  }

  @include s-1024 {
    font-size: 12px;
  }

  &.med {
    font-family: var(--font-med);
  }
}

.el-link-m {
  font-size: 18px;
  font-family: var(--font-semi);
  line-height: 150%;
  cursor: pointer;
  position: relative;
  color: var(--color-gray-6);

  &.semi {
    font-family: var(--font-semi);
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    box-sizing: border-box;
    border-bottom: 2px solid var(--color-gray-6);
  }

  &:hover {
    color: var(--color-gray-5);

    &::after {
      border-color: var(--color-gray-5);
    }
  }

  @include s-1440 {
    font-size: 16px;
  }

  @include s-1024 {
    font-size: 15px;
  }

  @include s-768 {
    font-size: 14px;
  }

  @include s-375 {
    font-size: 13px;
  }

  &.disabled {
    pointer-events: none;
    user-select: none;
    cursor: not-allowed;
  }
}

.el-link-s {
  font-size: 16px;
  line-height: 150%;
  cursor: pointer;
  position: relative;
  color: var(--color-gray-6);

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    box-sizing: border-box;
    border-bottom: 2px solid var(--color-gray-6);
  }

  &:hover {
    color: var(--color-gray-5);

    &::after {
      border-color: var(--color-gray-5);
    }
  }

  @include s-1440 {
    font-size: 14px;
  }

  @include s-1024 {
    font-size: 13px;
  }

  @include s-768 {
    font-size: 12px;
  }

  &.semi {
    font-family: var(--font-semi);
  }

  &-m {
    color: var(--color-gray-6);
    cursor: pointer;
    position: relative;
    font-family: var(--font-semi);
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      box-sizing: border-box;
      border-bottom: 2px solid var(--color-gray-6);
    }

    &:hover {
      color: var(--color-gray-5);

      &::after {
        border-color: var(--color-gray-5);
      }
    }
  }
}

.el-link-xs {
  font-size: 14px;
  line-height: 150%;
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    box-sizing: border-box;
    border-bottom: 2px solid var(--color-gray-6);
  }

  @include s-1440 {
    font-size: 14px;
  }

  @include s-1024 {
    font-size: 13px;
  }

  @include s-768 {
    font-size: 12px;
  }

  @include s-375 {
    font-size: 11px;
  }

  &.semi {
    font-family: var(--font-semi);
  }
}
