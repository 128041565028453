.p-contact {
  background: #f0f1f2;

  & .s {
    &-hero {
      padding-top: 104px;

      @include s-1440 {
        padding-top: 94px;
      }

      @include s-1024 {
        padding-top: 80px;
      }

      @include s-768 {
        padding-top: 68px;
      }

      &-top {
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        @include s-768 {
          flex-wrap: wrap;
        }

        &-desc {
          flex: 0 0 49%;
          margin-right: 10px;
          position: relative;
          z-index: 1;
          padding: 50px;
          color: var(--color-white);
          aspect-ratio: 874/872;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          border-radius: 20px;
          overflow: hidden;

          @include s-1440 {
            padding: 40px;
            aspect-ratio: 640/706;
            flex: 0 0 49.2%;
          }

          @include s-1024 {
            padding: 30px;
            flex: 0 0 49%;
            aspect-ratio: 460/672;
          }

          @include s-768 {
            flex: 0 0 100%;
            padding: 26px;
            aspect-ratio: unset;
            flex: 0 0 100%;
            min-height: 250px;
          }

          @include s-375 {
            min-height: 272px;
          }

          & h1 {
            text-transform: uppercase;
            color: var(--color-white);
          }

          &-bottom {
            color: var(--color-black);
            font-size: 18px;
            line-height: 150%;
            display: block;
            padding-top: 12px;

            @include s-1440 {
              font-size: 16px;
              padding-top: 8px;
            }

            @include s-1024 {
              font-size: 15px;
            }

            @include s-375 {
              padding-top: 14px;
            }

            & .subtitle {
              display: block;
              padding-bottom: 12px;

              @include s-1440 {
                padding-bottom: 10px;
              }

              @include s-1024 {
                padding-bottom: 8px;
              }

              @include s-375 {
                padding-bottom: 6px;
              }
            }
          }

          & .bg {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 100%;
            z-index: -1;
            transform: translate(-50%, -50%) scale(1.8);

            @include s-1440 {
              transform: translate(-50%, -50%) scale(2);
            }

            @include s-1024 {
              transform: translate(-50%, -50%) scale(2.8);
            }

            @include s-768 {
              transform: translate(-50%, -50%) scale(2);
            }

            &.loaded {
              & img {
                display: none;

                @include s-375 {
                  display: block;
                }
              }
            }

            &--layout {
              display: block;
              background: linear-gradient(100deg, #e98000 1.83%, #ff9c25 98.07%);
              height: 100%;
            }

            & img {
              object-fit: cover;
              position: absolute;
              left: 50%;
              top: 50%;
              width: 100%;
              height: 100%;
              transform: translate(-50%, -50%);
              transform-origin: center;
            }

            & iframe {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              height: 100%;
              opacity: 0.2;

              @include s-375 {
                display: none;
              }
            }
          }

          & a {
            color: inherit;
            text-decoration: underline;
            font-family: var(--font-bold);
          }
        }

        &-office {
          flex: 0 0 49%;
          border-radius: 20px;
          overflow: hidden;
          border: solid 1px var(--color-orange-main);
          background-color: var(--color-white);
          padding: 48px 48px 82px;

          @include s-1440 {
            flex: 0 0 49.2%;
            padding: 40px 40px 52px;
          }

          @include s-1024 {
            flex: 0 0 49%;
            padding: 30px 30px 52px;
          }

          @include s-768 {
            flex: 0 0 100%;
            margin-top: 30px;
            padding: 30px 30px 22px;
          }

          @include s-375 {
            margin-top: 24px;
            padding: 24px 24px 22px;
          }

          & h2 {
            color: var(--color-orange-main);
          }

          &-el {
            padding-top: 50px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include s-1440 {
              padding-top: 40px;
            }

            @include s-1024 {
              padding-top: 26px;
            }

            @include s-375 {
              padding-top: 22px;
            }

            & .phone {
              margin-top: 14px;

              @include s-1440 {
                margin-top: 12px;
              }

              @include s-1024 {
                margin-top: 8px;
              }
            }

            & h6 {
              padding-bottom: 8px;
              border-bottom: 1px solid #c5c8cb;
              width: 100%;

              @include s-1440 {
                padding-bottom: 6px;
              }
            }

            & a {
              margin-top: 20px;
              display: inline-flex;
              align-items: flex-start;

              @include s-1440 {
                margin-top: 16px;
              }

              @include s-1024 {
                margin-top: 14px;
              }
            }

            & svg {
              width: 30px;
              min-width: 30px;
              height: 30px;
              margin-right: 10px;
              margin-top: -4px;

              @include s-1440 {
                width: 24px;
                min-width: 24px;
                height: 24px;
              }

              @include s-1024 {
                width: 30px;
                min-width: 30px;
                height: 30px;
              }

              @include s-768 {
                margin-right: 8px;
                width: 26px;
                min-width: 26px;
                height: 30px;
              }
            }
          }
        }
      }
    }
  }

  & .c-footer {
    margin-top: 200px;

    @include s-1440 {
      margin-top: 160px;
    }

    @include s-1024 {
      margin-top: 140px;
    }

    @include s-768 {
      margin-top: 120px;
    }

    @include s-375 {
      margin-top: 100px;
    }
  }
}
