.filter {
  padding-top: 64px;

  @include s-1440 {
    padding-top: 40px;
  }

  @include s-1024 {
    padding-top: 30px;
  }

  @include s-768 {
    padding-top: 20px;
  }

  @include s-375 {
    padding-top: 30px;
  }

  &-select {
    display: flex;
    margin: 0 -16px;

    @include s-768 {
      margin: 0 -8px;
    }

    @include s-375 {
      flex-wrap: wrap;
      gap: 20px;
    }

    &-item {
      flex: 0 0 25%;
      padding-left: 16px;
      padding-right: 16px;

      @include s-768 {
        padding-left: 8px;
        padding-right: 8px;
      }

      @include s-375 {
        flex: 0 0 calc(50% - 10px);
      }

      .el-input-select {
        width: 100%;

        &.MuiInputBase-root {
          border-radius: 12px;
          font-family: inherit;
          font-size: unset;
          line-height: 100%;
          letter-spacing: unset;
          height: 48px;
          display: flex;
          align-items: center;
          border-color: var(--color-gray-2);

          @include s-1440 {
            border-radius: 8px;
          }

          @include s-768 {
            height: 38px;
          }
        }
        & fieldset {
          border-color: var(--color-gray-2);
        }

        & label {
          line-height: 110%;
          font-size: inherit;
          color: $labelColorDefault;
          top: 5px;
          left: 0;
          letter-spacing: 0;
          font-family: inherit;
          transition:
            left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

          @include s-1440 {
            top: 4px;
            left: 6px;
          }

          &.MuiFormLabel-filled,
          &.Mui-focused {
            left: 0;
            color: var(--color-gray-5);
          }
        }

        & .MuiInputBase-root {
          &.Mui-focused {
            & fieldset {
              border-color: $borderColorFocused;
              border-width: 1px;
            }
          }

          &.Mui-disabled {
            & .MuiSelect-select {
              cursor: not-allowed;
              background: inherit;
            }

            & input {
              cursor: not-allowed;
            }
          }
        }

        & .MuiSelect-icon {
          right: 24px;
          width: 28px;
          height: 28px;
          top: calc(50% - 14px);

          @include s-1440 {
            right: 16px;
            width: 24px;
            height: 24px;
            top: calc(50% - 12px);
          }

          @include s-1024 {
            right: 12px;
            width: 20px;
            height: 20px;
            top: calc(50% - 10px);
          }

          &.Mui-disabled {
            opacity: 0.4;
          }
        }
        &.Mui-focused {
          &:hover {
            & .MuiSelect-select,
            & .MuiInputBase-root {
              background-color: transparent;
            }
          }
          & fieldset {
            // border-color: $borderColorFocused;
            border-color: var(--color-gray-6);
            border-width: 1px;
          }
        }
        &.Mui-error {
          & label {
            color: $errorLabelColor;
          }

          & .MuiInputBase-root {
            & fieldset {
              border-color: $errorBorderColor;
            }
          }
        }

        &.Mui-disabled {
          cursor: not-allowed;
        }

        &:hover {
          & .MuiSelect-select,
          & .MuiInputBase-root {
            background: transparent;
          }
        }
      }
      .MuiTextField-root.el-input .MuiSelect-select.MuiInputBase-input,
      .el-input-select .MuiSelect-select.MuiInputBase-input,
      .el-input-select input {
        // height: 100%;
        // min-height: 48px;
        padding: 12px 24px;
        line-height: 150%;
        // display: flex;
        // align-items: center;
        font-family: var(--font-med);
        font-size: 16px;
        @include s-1440 {
          // min-height: 37px;
          padding: 8px 16px;
          font-size: 15px;
        }
        @include s-1024 {
          // min-height: 36px;
          padding: 0 12px;
          font-size: 13px;
        }
      }
    }
  }
}
