.c-acc-sidebar {
  max-width: 320px;
  width: 100%;

  @include s-1440 {
    max-width: 270px;
  }

  @include s-1024 {
    max-width: 240px;
  }

  @include s-768 {
    max-width: 200px;
  }

  @include s-375 {
    max-width: 100%;
    background: white;
    width: 260px;
    height: 100%;
    overflow-y: auto;
    padding: 0 16px;
  }

  &-box {
    padding: 40px 48px;
    position: sticky;
    top: 102px;

    @include s-1440 {
      padding: 24px 40px;
      top: 78px;
    }

    @include s-1024 {
      padding: 16px 32px;
      top: 68px;
    }

    @include s-768 {
      padding: 12px 20px;
    }

    @include s-375 {
      padding: 0;
    }
  }

  & .MuiList-root {
    padding: 16px 0;

    @include s-1440 {
      padding: 12px 0;
    }

    @include s-1024 {
      padding: 10px 0;
    }
  }

  & .MuiListItem-root {
    padding: 0;

    @include s-768 {
      padding: 4px 0;
    }

    &[disabled] {
      opacity: 0.65;

      &:hover {
        cursor: not-allowed;
      }

      & a {
        pointer-events: none;

        &:active {
          pointer-events: none;
        }
      }
    }

    & .MuiButtonBase-root {
      display: flex;
      padding: 0 10px 0 0;
      border-radius: 8px;
      overflow: hidden;
      font-size: 18px;
      font-family: var(--font-med);
      color: var(--color-gray-6);

      @include s-1440 {
        font-size: 16px;
      }

      @include s-1024 {
        font-size: 15px;
      }

      &:hover {
        background-color: transparent;
      }
      .MuiTouchRipple-root {
        display: none !important;
      }
    }
  }

  .MuiButton-root {
    &.icon-logout {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-transform: capitalize;
      padding: 0;
      font-family: var(--font-med);
      color: var(--color-gray-5);

      & svg {
        width: 52px;
        min-width: 52px;
        height: 52px;
        margin-right: 16px;

        @include s-1440 {
          width: 40px;
          min-width: 40px;
          height: 40px;
          margin-right: 12px;
        }

        @include s-1024 {
          width: 36px;
          min-width: 36px;
          height: 36px;
          margin-right: 10px;
        }

        & .icon-bg {
          fill: var(--color-white);
          transition: fill ease 0.3s;
        }

        & .icon-color {
          fill: var(--color-white);
          transition: fill ease 0.3s;
        }

        & .icon-path {
          fill: #6d757d;
          transition: fill ease 0.3s;
        }

        & .icon-path-stroke {
          stroke: #6d757d;
          transition: stroke ease 0.3s;
        }

        & .icon-color-stroke {
          stroke: #6d757d;
          fill: var(--color-white);
          transition: all ease 0.3s;
        }
      }

      & svg {
        & .icon-color {
          fill: white;
          transition: fill ease 0.3s;
        }
      }

      &:hover {
        color: unset;
        border: 0 none;
        color: var(--color-gray-5);

        & svg {
          & .icon-color {
            fill: #c5c8cb;
          }
        }
      }
    }
  }

  & a {
    width: 100%;
    font-family: var(--font-med);

    &:hover {
      color: unset;
      & svg {
        & .icon-color {
          fill: #c5c8cb;
        }
      }
    }

    & svg {
      width: 52px;
      min-width: 52px;
      height: 52px;
      margin-right: 16px;

      @include s-1440 {
        width: 40px;
        min-width: 40px;
        height: 40px;
        margin-right: 12px;
      }

      @include s-1024 {
        width: 36px;
        min-width: 36px;
        height: 36px;
        margin-right: 10px;
      }

      @include s-768 {
        width: 30px;
        min-width: 30px;
        height: 30px;
        margin-right: 8px;
      }

      & .icon-bg {
        fill: var(--color-white);
        transition: fill ease 0.3s;
      }

      & .icon-color {
        fill: var(--color-white);
        transition: fill ease 0.3s;
      }

      & .icon-path {
        fill: #6d757d;
        transition: fill ease 0.3s;
      }

      & .icon-path-stroke {
        stroke: #6d757d;
        transition: stroke ease 0.3s;
      }

      & .icon-color-stroke {
        stroke: #6d757d;
        fill: var(--color-white);
        transition: all ease 0.3s;
      }
    }

    &.active {
      & svg {
        // & .icon-bg {
        // fill: var(--color-orange-bg);
        // }

        & .icon-color {
          fill: #ffbd81;
          stroke: var(--color-orange-dark);
        }

        & .icon-color-stroke {
          stroke: var(--color-orange-dark);
          fill: var(--color-orange-bg);
        }

        & .icon-path {
          fill: var(--color-orange-dark);
        }

        & .icon-path-stroke {
          stroke: var(--color-orange-dark);
        }
      }
    }

    &.account {
      margin-bottom: 24px;

      @include s-1440 {
        margin-bottom: 20px;
      }

      @include s-1024 {
        margin-bottom: 18px;
      }
    }

    &.logo {
      display: flex;
      align-items: center;
      // margin-bottom: 50px;
      margin-bottom: 64px;
      max-width: 200px;
      @include s-1440 {
        margin-bottom: 40px;
      }

      @include s-1024 {
        margin-bottom: 28px;
      }
    }
  }
}
