.MuiButtonBase-root.el-button,
a.el-button,
button {
  cursor: pointer;
  width: 100%;
  background-color: var(--color-white);
  text-transform: none;
  color: var(--color-black);
  border: 1px solid var(--color-orange-main);
  border-radius: 8px;
  font-size: 18px;
  padding: 18px 24px;
  min-width: 180px;
  text-align: center;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
  letter-spacing: 0;
  box-shadow: unset;
  font-family: var(--font-med);
  text-decoration: none;

  @include s-1440 {
    min-width: 160px;
    font-size: 16px;
    padding: 15px 24px;
    border-radius: 8px;
  }

  @include s-1024 {
    min-width: 120px;
    font-size: 15px;
    padding: 13px 20px;
  }

  @include s-768 {
    min-width: 110px;
    font-size: 14px;
    padding: 11px 18px;
  }

  @include s-375 {
    min-width: 100px;
    font-size: 16px;
    padding: 16px;
  }

  &:hover {
    background: var(--color-orange-bg);
    box-shadow: unset;
    border: 1px solid var(--color-orange-main);
  }

  &:active {
    background: var(--colors-brand-bg-accent);
    box-shadow: none;
  }

  &.orange {
    background: var(--color-orange-light);
    color: var(--color-black);
    border: 1px solid var(--color-orange-light);

    &:hover {
      background: var(--colors-btn-hover);
    }
    &:active {
      background: var(--color-orange-main);
    }

    &[disabled],
    &.Mui-disabled {
      cursor: not-allowed;
      border-color: #ecedef;
      background: #ecedef;
      pointer-events: all;

      &:hover {
        border-color: #ecedef;
      }

      &:active {
        pointer-events: none;
      }
    }
    & .MuiTouchRipple-root {
      & * {
        background-color: var(--color-orange-light3) !important;
      }
    }
  }
  &.black {
    background: var(--color-black);
    color: var(--color-white);
    border: 1px solid var(--color-black);

    &:hover {
      background: var(--color-black);
    }
    &:active {
      background: var(--color-black);
    }
    &[disabled],
    &.Mui-disabled {
      pointer-events: all;
      cursor: not-allowed;
      border-color: #ecedef;
      background: #ecedef;
    }
    & .MuiTouchRipple-root {
      & * {
        background-color: var(--color-orange-light3) !important;
      }
    }
  }
  &.account {
    padding: 8px;
    text-transform: none;
    line-height: 27px;
    &:focus,
    &:hover {
      border: 1px solid var(--color-orange-light);
    }
  }

  &[disabled],
  &.Mui-disabled {
    color: #6d757d;
    border-color: #c4c8cb;
    background: transparent;
  }

  &.MuiLoadingButton-loading {
    color: var(--color-gray-5);

    & .MuiCircularProgress-svg {
      transform: scale(1.25);

      & circle {
        stroke: var(--color-gray-5);
      }
    }

    & .MuiLoadingButton-loadingIndicator {
      position: relative;
      left: unset;
      order: 1;
      margin-left: 30px;
    }
  }
  & .MuiTouchRipple-root {
    display: none !important;
  }
}
.el-button-link,
.MuiButtonBase-root.el-button-link,
.MuiLink-root.el-button-link {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  color: var(--color-gray-6);
  box-shadow: none;
  border-radius: 0;
  min-width: auto;
  text-transform: none;
  display: inline-flex;
  font-family: var(--font-semi);
  position: relative;
  line-height: 130%;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    box-sizing: border-box;
    border-bottom: 2px solid var(--color-gray-6);
  }
  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
    color: var(--color-gray-5);
    &::after {
      border-color: var(--color-gray-5);
    }
  }
}

.el-button-text,
.MuiButtonBase-root.el-button-text,
.MuiLink-root.el-button-text {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  color: var(--color-gray-6);
  box-shadow: none;
  border-radius: 0;
  min-width: auto;
  text-transform: none;
  display: inline-flex;
  font-family: var(--font-semi);
  position: relative;
  line-height: 130%;
  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
    color: var(--color-gray-5);
  }
}

.MuiButton-root.el-button-icon {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: none;
  border-radius: 0;
  img {
    width: 100%;
    height: 100%;
  }
  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
  }
}

.el-button-edit {
  min-width: 130px;
  padding: 4px 16px 4px 14px;
  min-height: 32px;
  border-radius: 16px;
  color: var(--color-black);
  background: var(--color-gray-1);
  border: 1px solid var(--color-gray-1);
  font-size: 16px;

  @include s-1440 {
    font-size: 14px;
  }

  @include s-1024 {
    font-size: 13px;
  }

  @include s-768 {
    font-size: 12px;
  }

  @include s-375 {
    font-size: 11px;
  }

  &:hover {
    background: var(--color-gray-2);
    border: 1px solid var(--color-gray-2);
  }

  &:active {
    background: var(--color-gray-3);
    border: 1px solid var(--color-gray-3);
  }

  &-icon {
    margin-right: 8px;
    width: 28px;
    height: 28px;

    @include s-1440 {
      width: 24px;
      height: 24px;
    }
    @include s-1024 {
      width: 20px;
      height: 20px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.p-account {
  & .MuiSwitch-root {
    padding: 4px;

    @include s-768 {
      width: 48px;
      height: 28px;
    }

    @include s-375 {
      padding: 0;
    }

    & .MuiSwitch-switchBase {
      @include s-768 {
        padding: 6px;
      }
    }

    & .MuiSwitch-thumb {
      @include s-768 {
        width: 16px;
        height: 16px;
      }
    }
  }
}
