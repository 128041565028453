.modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.MuiDrawer-root,
.MuiDialog-root {
  &.modal-right {
    & .MuiPaper-root,
    & .MuiDialog {
      &-scrollPaper {
        margin: 0;
        height: 100vh;
        min-height: 820px;
        align-items: flex-start;
        justify-content: flex-end;

        @include s-768 {
          min-height: 400px;
        }
      }

      &-paper {
        margin: 0;
        height: 100vh;
        min-height: 820px;

        box-shadow: none;
        max-height: 100%;
        border-radius: 0;
        width: 100%;
        max-width: 800px;

        @include s-1440 {
          max-width: 560px;
        }

        @include s-768 {
          max-width: 460px;
        }

        @include s-375 {
          max-width: calc(100% - 40px);
        }
      }

      & .modal {
        &-top {
          position: relative;
          padding: 32px 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @include s-768 {
            padding: 20px 28px;
          }

          @include s-375 {
            padding: 16px 22px;
          }

          & .MuiTypography-root {
            padding: 0;
          }
        }

        &-wrap {
          height: 100%;
          position: relative;
          overflow: auto;
          padding-bottom: 100px;
          // @include s-1440 {
          //   padding-bottom: 48px;
          // }
          // @include s-1024 {
          //   padding-bottom: 45px;
          // }
        }

        &-close {
          min-width: 40px;
          & svg {
            & path {
              fill: var(--color-gray-5);
            }
          }
          &:hover {
            & svg {
              & path {
                fill: var(--color-orange-main);
              }
            }
          }
        }

        &-desc {
          padding-top: 32px;

          @include s-1440 {
            padding-top: 24px;
          }

          @include s-1024 {
            padding-top: 20px;
          }

          &-wrap {
            padding-top: 32px;
            @include s-1440 {
              padding-top: 24px;
            }
            @include s-1024 {
              padding-top: 20px;
            }
            &:first-of-type {
              padding-top: 0;
            }
          }

          &-bottom {
            padding-top: 32px;
            @include s-1440 {
              padding-top: 24px;
            }
            @include s-1024 {
              padding-top: 20px;
            }
          }

          &-item {
            padding: 8px 0;
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            display: flex;
            justify-content: space-between;
          }

          &-label {
            color: var(--color-gray-6);
          }

          & .verification-icon {
            width: 16px;
            height: 16px;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-left: 8px;
            img {
              object-fit: contain;
              display: block;
            }
          }

          &-text {
            text-align: right;
            padding-left: 8px;
            color: var(--color-black);
            &.verification {
              display: flex;
            }
            &.name {
              text-transform: capitalize;
            }
            & .address {
              display: flex;
              align-items: center;

              &-copy {
                margin-left: 8px;
                width: 20px;
                height: 20px;
                cursor: copy;

                @include s-1440 {
                  width: 16px;
                  height: 16px;
                }
              }
            }

            &-reward {
              display: flex;
              align-items: center;
            }
            &-icon {
              margin-left: 8px;
              display: block;
              cursor: pointer;
            }
          }
        }

        &-reward {
          padding-top: 24px;
          &-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: var(--color-gray-1);
            border-radius: 8px;
            overflow: hidden;
            padding: 8px 16px;
            height: 56px;
          }

          &-left {
            display: flex;
            align-items: center;

            &-img {
              max-width: 55px;
              display: flex;
            }
          }

          &-label {
            font-size: 14px;
            font-family: var(--font-bold);
            line-height: 150%;
            padding: 0 16px;
          }
          &-text {
            font-size: 14px;
            line-height: 150%;
            display: flex;
            align-items: center;

            &-icon {
              display: flex;
            }

            & svg {
              cursor: pointer;
              margin-left: 8px;
            }
          }
        }

        &-btn {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          padding: 80px 40px 40px;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0.23%, #fff 47.42%);
        }
      }
    }

    & .MuiPaper-root {
      width: 720px;
      border-radius: 0;
      margin: 0;

      @include s-1440 {
        width: 640px;
      }

      @include s-1024 {
        width: 580px;
      }

      @include s-768 {
        width: 480px;
      }

      @include s-375 {
        width: 100%;
      }
    }

    & .MuiDialogActions-root {
      padding: 0;
      .MuiButton-root {
        padding: 0;
        width: 48px;
        height: 48px;
        background-color: transparent !important;
        &:hover,
        &:active {
          border: 0 none;
          background-color: transparent;
        }
        @include s-1440 {
          width: 40px;
          height: 40px;
        }
        @include s-1024 {
          width: 36px;
          height: 36px;
        }
      }
    }

    & .MuiDialogContent-root {
      padding: 24px 48px 48px;

      @include s-1440 {
        padding: 16px 40px 40px;
      }

      @include s-1024 {
        padding: 12px 36px 36px;
      }

      @include s-768 {
        padding: 12px 28px 28px;
      }

      @include s-375 {
        padding: 10px 22px 22px;
      }
    }

    &.agent {
      & .MuiPaper-root {
        width: 846px;

        @include s-1440 {
          width: 720px;
        }

        @include s-1024 {
          width: 672px;
        }

        @include s-768 {
          width: 570px;
        }

        @include s-375 {
          width: 100%;
        }
      }

      & .modal-btn {
        padding: 0;

        @include s-375 {
          padding: 0;
          position: fixed;
          left: 20px;
          width: 336px;
          bottom: 20px;
        }
      }
    }
  }

  &.modal-center {
    .MuiDialog {
      &-scrollPaper {
        margin: 0;
        // height: 100vh;
        // min-height: 820px;
        align-items: center;
        justify-content: center;

        @include s-375 {
          padding: 16px;
        }
      }
      &-paper {
        margin: 0;
        box-shadow: none;
        max-height: 100%;
        border-radius: 16px;
        width: 100%;
        max-width: 800px;

        @include s-1440 {
          max-width: 560px;
        }

        @include s-1024 {
          max-width: 560px;
        }

        @include s-768 {
          max-width: 460px;
        }

        @include s-375 {
          max-width: 375px;
        }

        .modal {
          &-top {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            padding: 20px 12px 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @include s-1440 {
              padding: 20px 20px 0;
            }

            @include s-1024 {
              padding: 20px 24px 0;
            }

            & .MuiTypography-root {
              padding: 0;
            }
          }
          &-close {
            min-width: 40px;
            & svg {
              & path {
                fill: var(--color-gray-5);
              }
            }
            &:hover {
              & svg {
                & path {
                  fill: var(--color-orange-main);
                }
              }
            }
          }

          &-img {
            width: 240px;
            height: 240px;
            margin: 0 auto;
            position: relative;

            @include s-1440 {
              width: 200px;
              height: 200px;
            }

            @include s-1024 {
              width: 160px;
              height: 160px;
            }

            @include s-768 {
              width: 120px;
              height: 120px;
            }

            & video {
              -webkit-mask-image: -webkit-radial-gradient(white, black);
              -webkit-backface-visibility: hidden;
              -moz-backface-visibility: hidden;
            }
          }

          &-desc {
            text-align: center;

            &-title {
              color: var(--color-black);
              padding-top: 48px;
              font-family: var(--font-med);
              font-size: 40px;
              line-height: 125%;

              @include s-1440 {
                padding-top: 40px;
                font-size: 32px;
              }

              @include s-1024 {
                padding-top: 36px;
                font-size: 26px;
              }

              @include s-768 {
                padding-top: 30px;
                font-size: 20px;
              }

              @include s-375 {
                padding-top: 26px;
                font-size: 18px;
              }
            }

            &-text {
              padding-top: 24px;

              @include s-1440 {
                padding-top: 16px;
              }

              @include s-1024 {
                padding-top: 12px;
              }
            }

            &-input {
              padding-top: 48px;

              @include s-1440 {
                padding-top: 40px;
              }

              @include s-1024 {
                padding-top: 36px;
              }
              & .el-input {
                margin-bottom: 0;
              }
            }
          }
          &-btns {
            padding-top: 48px;
            display: flex;
            flex-direction: column;
            // gap: 24px;

            @include s-1440 {
              padding-top: 40px;
              // gap: 16px;
            }

            @include s-1024 {
              padding-top: 36px;
              // gap: 12px;
            }
            & > * {
              margin-bottom: 16px;
            }
            & > *:last-child {
              margin-bottom: 0;
            }
            // & .el-button-continue {
            //   margin-top: 16px;
            // }
          }
        }
      }
    }
    .MuiDialogActions-root {
      padding: 0;
      .MuiButton-root {
        padding: 0;
        width: 48px;
        height: 48px;
        background-color: transparent !important;
        &:hover,
        &:active {
          border: 0 none;
          background-color: transparent;
        }
        @include s-1440 {
          width: 40px;
          height: 40px;
        }
        @include s-1024 {
          width: 36px;
          height: 36px;
        }
      }
    }

    & .MuiDialogContent-root {
      padding: 96px 48px 48px;

      @include s-1440 {
        padding: 80px 40px 40px;
      }

      @include s-1024 {
        padding: 64px 36px 36px;
      }
    }
  }
  .MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.85);
  }
}

.qr-modal {
  & .MuiDialogContent-root {
    max-width: 800px;
    padding: 32px;
    text-align: center;
    @include s-1440 {
      max-width: 560px;
    }
    @include s-1024 {
      max-width: 560px;
    }
  }

  & .el-title-h6,
  & .el-text-m {
    display: block;
  }

  & .el-text-m {
    padding-top: 8px;
  }

  &-img {
    max-width: 240px;
    border-radius: 12px;
    overflow: hidden;
    border: solid 1px var(--color-gray-1);
    margin: 32px auto;
    padding: 16px;
  }
}

.activity-modal {
  .item {
    padding-top: 20px;
    &:first-of-type {
      padding-top: 0;
    }
    &-wrap {
      border-radius: 8px;
      border: 1px solid var(--color-gray-2);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px;
      @include s-1440 {
        padding: 16px;
      }
      @include s-1440 {
        padding: 12px;
      }
    }
    &-info {
      display: flex;
      align-items: center;

      &-avatar {
        width: 48px;
        height: 48px;
        @include s-1440 {
          width: 40px;
          height: 40px;
        }
        @include s-1440 {
          width: 36px;
          height: 36px;
        }
      }

      &-name {
        display: flex;
        align-items: center;
        &-title {
          color: var(--color-black);
          // font-family: var(--font-semi);
          // font-size: 16px;
          // line-height: 150%;
          padding-right: 8px;
          text-transform: capitalize;
        }
      }
      &-agent {
        color: var(--color-orange-main);
        font-family: var(--font-semi);
        font-size: 14px;
        line-height: 1;
        text-transform: uppercase;
        display: block;
        margin-right: 12px;
        margin-top: 2px;
        @include s-1440 {
          font-size: 13px;
          margin-right: 8px;
        }
        @include s-1024 {
          font-size: 11px;
        }
      }
      &-star {
        width: 20px;
        height: 20px;
        @include s-1440 {
          width: 16px;
          height: 16px;
        }
      }
      &-you {
        height: 20px;
        display: block;
        @include s-1440 {
          height: 16px;
        }
      }
      &-date {
        font-size: 13px;
        line-height: 150%;
      }
      &-desc {
        padding-left: 12px;
      }
    }
    &-right {
      width: 100%;
      max-width: 262px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include s-375 {
        width: unset;
        display: inline-flex;
      }
    }
    &-quantity {
      max-width: 200px;
      width: 100%;
      &-wrap {
        display: flex;
        align-items: center;
      }
      .icon {
        width: 32px;
        height: 32px;

        @include s-768 {
          width: 28px;
          height: 28px;
        }

        img {
          width: 32px;
          height: 32px;

          @include s-768 {
            width: 28px;
            height: 28px;
          }
        }
      }
      .text {
        font-size: 16px;
        font-family: var(--font-med);
        line-height: 150%;
        padding: 4px 12px;
        border-radius: 100px;
        overflow: hidden;

        @include s-768 {
          font-size: 14px;
        }
      }
      & .sent-quan {
        & .text {
          color: var(--color-send);
          background-color: var(--color-send-bg);
        }
      }
      & .wallet-quan {
        & .text {
          color: var(--color-deposit);
          background-color: var(--color-deposit-bg);
        }
      }
      & .buy-quan {
        & .text {
          color: var(--color-buy);
          background-color: var(--color-buy-bg);
        }
      }
      & .withdrawal-quan {
        & .text {
          color: var(--color-withdraw);
          background-color: var(--color-withdraw-bg);
        }
      }
      & .received-quan {
        & .text {
          color: var(--color-receive);
          background-color: var(--color-receive-bg);
        }
      }
    }
    &-status {
      padding-left: 24px;
      &-wrap {
        display: flex;
        align-items: center;
      }
      .icon {
        width: 16px;
        height: 16px;
        img {
          width: 16px;
          height: 16px;
          display: block;
        }
      }
      .text {
        padding-left: 4px;
        font-size: 16px;
        font-family: var(--font-med);
        line-height: 150%;

        @include s-768 {
          font-size: 14px;
        }
      }
      & .initiated,
      .assigned,
      .pending {
        .text {
          color: var(--color-gray-4);
        }
      }
      & .success {
        .text {
          color: var(--color-green);
        }
      }
      & .failed {
        .text {
          color: var(--color-red);
        }
      }
    }
  }
}

.avatar-modal {
  &.modal-center .MuiPaper-root .modal-top {
    display: none;
  }

  &.modal-center .MuiDialogContent-root {
    padding: 40px;

    @include s-1440 {
      padding: 32px;
    }

    @include s-1024 {
      padding: 28px;
    }
  }

  & h6 {
    padding: 0;
  }

  & .modal-content {
    &-upload {
      max-width: 400px;
      margin: 0 auto;
      margin-top: 40px;

      @include s-1440 {
        max-width: 320px;
        margin-top: 32px;
      }

      @include s-1024 {
        max-width: 280px;
        margin-top: 28px;
      }

      & .editor {
        line-height: 0;
        width: 400px;
        margin-bottom: 12px;

        @include s-1440 {
          width: 320px;
          margin-bottom: 8px;
        }

        @include s-1024 {
          width: 280px;
        }
      }

      & canvas {
        width: 100% !important;
        height: 100% !important;
      }
    }

    & .modal-content-btns {
      margin-top: 40px;

      @include s-1440 {
        margin-top: 32px;
      }

      @include s-1024 {
        margin-top: 28px;
      }
    }

    &-btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      @include s-1440 {
        gap: 16px;
      }

      @include s-1024 {
        gap: 12px;
      }
    }
  }

  & .slider {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 22px;

      @include s-1440 {
        gap: 16px;
      }

      &-scale {
        cursor: pointer;
        display: flex;
        width: 21px;
        min-width: 21px;
        aspect-ratio: 1;
        position: relative;
        z-index: 1;

        &.rotate {
          display: flex;
          cursor: pointer;
          width: 26px;
          min-width: 26px;
          aspect-ratio: 1;

          @include s-1440 {
            width: 22px;
            min-width: 22px;
          }

          @include s-1024 {
            width: 18px;
            min-width: 18px;
          }
        }

        &:before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 40px;
          height: 40px;
          z-index: -1;
          opacity: 0;
          border-radius: 50%;
          background-color: var(--color-gray-1);
          transition: opacity 0.4s ease;

          @include s-1440 {
            width: 34px;
            height: 34px;
          }

          @include s-1024 {
            width: 30px;
            height: 30px;
          }
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }

        @include s-1440 {
          min-width: 18px;
        }

        @include s-1024 {
          min-width: 15px;
        }
      }
    }

    & .MuiSlider-root {
      min-width: 190px;
      color: var(--color-orange-main);
      height: 2px;
    }

    & .MuiSlider-thumb {
      width: 10px;
      height: 10px;

      &:after,
      &:before {
        content: none;
      }
    }
  }
}

// date picker mob styles
.MuiPickersCalendarHeader-root {
  @include s-375 {
    margin: 0 !important;
    padding: 22px;
    border-bottom: solid 1px #0b0c0d21;
  }
}

.MuiPickersLayout-actionBar {
  @include s-375 {
    box-shadow: 0px -4px 20px 0px #0b0c0d0a;
    border-top: solid 1px #0b0c0d21;
  }
}

.MuiPickersArrowSwitcher-root,
.MuiPickersCalendarHeader-switchViewButton {
  @include s-375 {
    display: none !important;
  }
}

.MuiPickersDay-root {
  @include s-375 {
    min-width: unset;
  }
}
