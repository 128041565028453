.c-agent {
  &-sidebar {
    max-width: 480px;
    flex: 0 0 480px;

    @include s-1440 {
      max-width: 390px;
      flex: 0 0 390px;
    }

    @include s-1024 {
      max-width: 360px;
      flex: 0 0 360px;
    }

    @include s-768 {
      max-width: 240px;
      flex: 0 0 240px;
    }

    @include s-375 {
      max-width: 640px;
      flex: 0 0 100%;
    }

    & a.logo {
      max-width: 200px;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
    }

    &-box {
      padding: 40px 48px;
      height: auto;
      min-height: calc(100vh - 102px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &.notmore {
        justify-content: flex-start;
      }
      @include s-1440 {
        padding: 24px 40px;
      }

      @include s-1024 {
        padding: 16px 25px;
      }

      @include s-375 {
        min-height: unset;
      }
    }
    &-top {
    }
    &-bottom {
    }
  }

  &-transaction {
    border-top: 1px solid var(--color-gray-2);
    padding-top: 24px;
    padding-bottom: 24px;

    @include s-1440 {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    @include s-1024 {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    &-btns {
      padding-bottom: 24px;

      @include s-1440 {
        padding-bottom: 16px;
      }

      @include s-1024 {
        padding-bottom: 20px;
      }
    }

    &-copy {
      font-size: 24px;
      font-family: var(--font-med);
      line-height: 1;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: space-between;
      @include s-1440 {
        font-size: 20px;
      }

      & .text {
        margin-left: 12px;
      }

      & .copy {
        margin-left: 12px;
        width: 24px;
        height: 24px;
        display: block;
        @include s-1440 {
          width: 20px;
          height: 20px;
        }
      }

      & .left .label {
        @include s-768 {
          display: block;
        }
      }

      & .left .text {
        @include s-768 {
          display: block;
          margin: 6px 0;
        }
      }
    }

    &-st {
      display: flex;
      padding-top: 8px;
      @include s-1440 {
        padding-top: 6px;
      }
      .quantity {
        margin-right: 8px;
        &-wrap {
          display: flex;
          align-items: center;
        }
        .icon {
          width: 26px;
          height: 26px;
          @include s-1440 {
            width: 24px;
            height: 24px;
          }
          @include s-1024 {
            width: 20px;
            height: 20px;
          }
          img {
            width: 26px;
            height: 26px;
            @include s-1440 {
              width: 24px;
              height: 24px;
            }
            @include s-1024 {
              width: 20px;
              height: 20px;
            }
          }
        }
        .text {
          font-size: 14px;
          font-family: var(--font-med);
          line-height: 1;
          padding: 6px 24px;
          border-radius: 100px;
          overflow: hidden;
          text-transform: capitalize;
          height: 26px;
          @include s-1440 {
            padding: 4px 16px;
            font-size: 13px;
            height: 24px;
          }
          @include s-1024 {
            padding: 4px 12px;
            font-size: 12px;
            height: 20px;
          }
        }
        & .sent-quan {
          & .text {
            color: var(--color-send);
            background-color: var(--color-send-bg);
          }
        }
        & .received-quan {
          & .text {
            color: var(--color-receive);
            background-color: var(--color-receive-bg);
          }
        }
      }
      .status {
        font-size: 14px;
        font-family: var(--font-med);
        line-height: 1;
        padding: 6px 12px;
        border-radius: 100px;
        overflow: hidden;
        text-transform: capitalize;
        color: var(--color-gray-5);
        background-color: var(--color-gray-1);
        @include s-1440 {
          padding: 4px 16px;
          font-size: 13px;
          height: 24px;
        }
        @include s-1024 {
          padding: 4px 12px;
          font-size: 12px;
          height: 20px;
        }
      }
    }
  }

  &-description {
    padding-top: 24px;
    border-top: 1px solid var(--color-gray-2);
    @include s-1440 {
      padding-top: 20px;
    }

    & .block {
      &-content {
        height: 180px;
        overflow: hidden;
        transition: all 0.4s ease;
        position: relative;
        padding-bottom: 24px;

        @include s-1440 {
          padding-bottom: 20px;
        }

        @include s-1024 {
          padding-bottom: 20px;
        }

        @include s-375 {
          height: 80px;
        }

        &:after {
          position: absolute;
          left: 0;
          bottom: -45px;
          content: '';
          height: 144px;
          width: 100%;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0.23%, #fff 67.1%);
        }

        &.open {
          height: auto;

          &:after {
            content: none;
          }
        }
        & .verification-icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            object-fit: contain;
            display: block;
          }
        }
        &-wrap {
          padding-top: 24px;

          &:first-of-type {
            padding-top: 0;
          }

          @include s-1440 {
            padding-top: 20px;
          }

          @include s-1024 {
            padding-top: 12px;
          }
        }

        &-item {
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          // padding: 4px 7px;
          padding: 12px 0 15px;
          @include s-1440 {
            padding: 4px 0 7px;
          }
        }

        &-label {
          color: var(--color-gray-5);
          flex: 0 0 50%;
          max-width: 172px;
          // padding-right: 10px;
          padding-right: 32px;
          @include s-1440 {
            max-width: 144px;
            padding-right: 24px;
          }
          @include s-1024 {
            max-width: 140px;
            padding-right: 20px;
          }
        }
        &-text {
          flex: 1;
          color: var(--color-black);
          text-transform: capitalize;

          &.comment {
            text-transform: none;
          }

          &.red {
            color: var(--color-red-1);
          }

          & b {
            font-family: var(--font-semi);
          }
        }
      }
      &-more {
        border-top: 1px solid var(--color-gray-2);
        display: flex;
        justify-content: center;
        padding-top: 16px;
        padding-bottom: 32px;
        @include s-1440 {
          padding-top: 12px;
          padding-bottom: 24px;
        }

        @include s-1024 {
          padding-top: 10px;
          padding-bottom: 20px;
        }
        &-btn {
          border: 0 none;
          background: transparent;
          padding: 0;
          margin: 0;
          box-shadow: none;
          width: auto;
          font-size: 16px;
          font-family: var(--font-med);
          display: flex;
          align-items: center;

          @include s-1440 {
            font-size: 14px;
          }

          @include s-1024 {
            font-size: 13px;
          }

          &:hover,
          &:focus {
            background-color: transparent;
            box-shadow: none;
            border: 0 none;
          }

          &.open {
            .icon {
              transform: rotate(180deg);
            }
          }

          & span {
            display: block;
          }

          & .icon {
            transition: transform 0.3s linear;
            width: 20px;
            height: 20px;
            margin-left: 8px;
          }
        }
      }
    }
  }

  &-note {
    padding-bottom: 32px;

    @include s-1440 {
      padding-bottom: 32px;
    }

    @include s-1024 {
      padding-bottom: 32px;
    }

    & .flow-estimate {
      margin-top: 24px;

      @include s-1440 {
        margin-top: 16px;
      }

      @include s-1024 {
        margin-top: 12px;
      }

      &:first-of-type {
        margin-top: 0;
      }

      &.small {
        max-width: 100%;
        width: 100%;
      }

      .subtitle {
        color: var(--color-gray-6);
        font-family: var(--font-reg);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @include s-1440 {
          font-size: 13px;
        }

        @include s-1024 {
          font-size: 12px;
        }
      }
    }
  }

  &-btns {
    display: flex;
    justify-content: center;

    &-btn {
      & .el-button-link {
        font-size: 16px;
        font-family: var(--font-semi);

        @include s-1440 {
          font-size: 14px;
        }

        @include s-1024 {
          font-size: 13px;
        }
      }
    }
  }
}
