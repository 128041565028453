@font-face {
  font-family: 'neue_montreal_regular';
  src: url('../fonts/NeueMontreal-Regular.eot');
  src:
    local('Neue Montreal Regular'),
    local('NeueMontreal-Regular'),
    url('../fonts/NeueMontreal-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NeueMontreal-Regular.woff2') format('woff2'),
    url('../fonts/NeueMontreal-Regular.woff') format('woff'),
    url('../fonts/NeueMontreal-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'neue_montreal_bold';
  src: url('../fonts/NeueMontreal-Bold.eot');
  src:
    local('Neue Montreal Bold'),
    local('NeueMontreal-Bold'),
    url('../fonts/NeueMontreal-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NeueMontreal-Bold.woff2') format('woff2'),
    url('../fonts/NeueMontreal-Bold.woff') format('woff'),
    url('../fonts/NeueMontreal-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'neue_montreal_medium';
  src: url('../fonts/NeueMontreal-Medium.eot');
  src:
    local('Neue Montreal Medium'),
    local('NeueMontreal-Medium'),
    url('../fonts/NeueMontreal-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NeueMontreal-Medium.woff2') format('woff2'),
    url('../fonts/NeueMontreal-Medium.woff') format('woff'),
    url('../fonts/NeueMontreal-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'neue_montreal_semibold';
  src: url('../fonts/PPNeueMontreal-SemiBold.eot');
  src:
    local('PP Neue Montreal SemiBold'),
    local('PPNeueMontreal-SemiBold'),
    url('../fonts/PPNeueMontreal-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PPNeueMontreal-SemiBold.woff2') format('woff2'),
    url('../fonts/PPNeueMontreal-SemiBold.woff') format('woff'),
    url('../fonts/PPNeueMontreal-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
