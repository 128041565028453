:root {
  // TODO
  --color-white: #ffffff;
  --color-black: #0b0c0d;
  
  // brand colors
  --color-orange-light: #FF9C25;
  --color-orange-main: #FC8500;
  --color-orange-dark: #E98000;
  --color-orange-ex-dark: #AB552B;
  --color-orange-bg: #FDF3EE;
  --colors-brand-bg-accent: #FFCEA0;
  --colors-btn-hover: #FD8B0A;
  
  // neutral colors
  --color-gray-1: #F2F2F2;
  --color-gray-2: #D3D6D8;
  --color-gray-3: #c5c8cb;
  --color-gray-4: #a7acb1;
  --color-gray-5: #6d757d;
  --color-gray-6: #41464b;
  --color-gray-7: #2c2f32;
  --color-gray-8: #f0f1f2;
  --color-gray-9: #f8f8f8;
  --color-gray-10: #c4c8cb;

  // actions colors
  --color-deposit: #1B5EC3;
  --color-send: #C31B1B;
  --color-receive: #1C6E5A;
  --color-buy: #2A6985;
  --color-withdraw: #8E3F9B;

    // actions bg colors
  --color-deposit-bg: #E8EEF9;
  --color-send-bg: #FAEDED;
  --color-receive-bg: #E8F0EE;
  --color-buy-bg: #E9F0F2;
  --color-withdraw-bg: #F3EBF5;

  --color-red: #C56565;
  --color-red-2: #F67E7E;
  --color-red-1: #FDDFDF;

  --color-msg-warning: #E9C46A;
  --color-unregistred: #F9F0DA;

  // TODO
  --color-green: #219653;
  --color-blue: #def1ff; 
  --color-blue-2: #def1ff;
  --color-red-error: #ffe2e2;
}
