.success {
  &-box {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include s-1440 {
      max-width: 800px;
    }

    @include s-375 {
      padding: 0 16px;
    }

    &-img {
      width: 240px;
      height: 240px;
      margin: 0 auto;
      position: relative;
      margin-bottom: 96px;

      @include s-1440 {
        width: 200px;
        height: 200px;
        margin-bottom: 80px;
      }

      @include s-1024 {
        width: 160px;
        height: 160px;
        margin-bottom: 64px;
      }

      @include s-375 {
        margin-bottom: 32px;
      }

      & video {
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
      }
    }

    &-text {
      padding-top: 24px;
      font-size: 18px;
      line-height: 150%;
      color: var(--color-gray-7);

      @include s-1440 {
        padding-top: 16px;
        font-size: 16px;
      }

      @include s-1024 {
        padding-top: 12px;
        font-size: 14px;
      }

      @include s-375 {
        padding-top: 16px;
        max-width: 310px;
        margin: 0 auto;
      }

      .el-button-link {
        color: var(--color-gray-6);
        margin-left: 8px;
        font-size: 18px;
        font-family: var(--font-semi);
        position: relative;
        @include s-1440 {
          font-size: 16px;
        }
        @include s-1024 {
          font-size: 14px;
        }
        &:before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: var(--color-gray-6);
        }
        &:hover {
          color: var(--color-gray-5);
          &:before {
            background-color: var(--color-gray-5);
          }
        }
      }
      &-wrap {
        border-radius: 8px;
        background-color: var(--color-red-error);
        padding: 6px 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
      &-note {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }

    &-btns {
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      max-width: 480px;
      margin: 0 auto;

      @include s-1440 {
        gap: 16px;
        max-width: 400px;
      }

      @include s-1024 {
        gap: 12px;
        max-width: 360px;
      }
    }
  }
}
