.p-account-chat {
  & .chat {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    @include s-375 {
      flex-wrap: wrap;
    }

    &-box {
      padding: 40px 48px 28px;
      width: 100%;

      @include s-1440 {
        padding: 24px 40px 20px;
      }

      @include s-1024 {
        padding: 16px 36px 16px;
      }

      @include s-375 {
        padding: 16px 20px 16px;
      }

      &-inner {
        padding: 24px;
        border-radius: 16px;
        border: 1px solid var(--color-gray-2);
        height: calc(100vh - 144px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 395px;
        @include s-1440 {
          padding: 16px;
        }
        @include s-1024 {
          padding: 12px;
        }
      }
    }

    &-dialogue {
      text-align: center;
      overflow-y: auto;
      flex: 1;
      display: block;
      b {
        font-family: var(--font-semi);
      }

      // &-block {
      //   display: flex;
      //   align-items: flex-end;
      // }

      &-wrap {
        // flex: 0 0 100%;
        // height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 100%;
        & > div {
          padding-top: 32px;
          @include s-1440 {
            padding-top: 24px;
          }
          @include s-1024 {
            padding-top: 20px;
          }
          &:last-of-type {
            padding-bottom: 32px;
            @include s-1440 {
              padding-bottom: 24px;
            }
            @include s-1024 {
              padding-bottom: 20px;
            }
          }
        }
        &-anchor {
          padding-bottom: 0 !important;
        }
      }

      & .message-btns {
        padding-top: 8px;
        @include s-1440 {
          padding-top: 8px;
        }
        @include s-1024 {
          padding-top: 8px;
        }
        .btns {
          display: flex;
          gap: 24px;
          margin-left: 92px;
          @include s-1440 {
            gap: 16px;
            margin-left: 72px;
          }

          @include s-1024 {
            gap: 12px;
          }
        }
        & .el-button {
          max-width: 200px;

          @include s-1440 {
            max-width: 160px;
          }

          @include s-1024 {
            max-width: 160px;
          }
        }
      }
    }

    &-initiated {
      color: var(--color-gray-6);
      font-size: 14px;
      // margin-bottom: 24px;
      @include s-1440 {
        font-size: 13px;
      }
      @include s-1024 {
        font-size: 12px;
      }
    }

    &-agent {
      &-invited {
        font-size: 14px;
        // margin-bottom: 24px;
        @include s-1440 {
          font-size: 13px;
        }
        @include s-1024 {
          font-size: 12px;
        }
      }
      &-declined {
        font-size: 14px;
        @include s-1440 {
          font-size: 13px;
        }
        @include s-1024 {
          font-size: 12px;
        }
      }
      &-links {
        font-size: 14px;
        margin-bottom: 24px;
        @include s-1440 {
          font-size: 13px;
        }
        @include s-1024 {
          font-size: 12px;
        }

        a {
          font-size: 14px;
          @include s-1440 {
            font-size: 13px;
          }
          @include s-1024 {
            font-size: 12px;
          }
        }
      }
    }

    &-message {
      display: flex;
      align-items: flex-start;
      text-align: left;
      & .avatar {
        min-width: 80px;
        width: 80px;
        height: 80px;
        margin-right: 12px;
        border-radius: 100%;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(100deg, #8ecae6 1.83%, #2487a7 98.07%);

        @include s-1440 {
          width: 64px;
          min-width: 64px;
          height: 64px;
          margin-right: 12px;
        }

        @include s-1024 {
          min-width: 56px;
          width: 56px;
          height: 56px;
          margin-right: 8px;
        }

        @include s-375 {
          width: 36px;
          height: 36px;
          min-width: 36px;
        }

        &-label {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
        &-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &-init {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          overflow: hidden;
          background: linear-gradient(100deg, #8ecae6 1.83%, #2487a7 98.07%);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .message {
        flex: 1;
        border-radius: 12px;
        padding: 24px;
        position: relative;
        background-color: var(--color-gray-1);
        @include s-1440 {
          padding: 16px;
        }
        @include s-1024 {
          font-size: 12px;
        }
        &-wrap {
          width: 100%;
        }

        &-name {
          font-size: 16px;
          font-family: var(--font-semi);
          line-height: 150%;
          text-transform: capitalize;
          @include s-1440 {
            font-size: 14px;
          }
          @include s-1024 {
            font-size: 13px;
          }
        }
        &-text {
          padding-top: 6px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          // color: var(--color-gray-5);

          @include s-1440 {
            font-size: 14px;
          }
          @include s-1024 {
            font-size: 13px;
          }

          & .btns {
            display: flex;
            gap: 20px;
            max-width: 400px;
            margin-top: 10px;
          }
        }

        &-my {
          background-color: var(--color-orange-bg);
        }

        &-right {
          position: absolute;
          right: 24px;
          top: 24px;
          display: inline-flex;
        }

        &-status {
          width: 16px;
          height: 16px;
        }

        &-timestamp {
          padding-left: 10px;
          color: var(--color-gray-5);
        }

        & .btns {
          display: flex;
          gap: 24px;
          margin-top: 32px;
          @include s-1440 {
            gap: 16px;
            margin-top: 24px;
          }

          @include s-1024 {
            gap: 12px;
            margin-top: 20px;
          }

          & .el-button {
            max-width: 200px;

            @include s-1440 {
              max-width: 160px;
            }

            @include s-1024 {
              max-width: 160px;
            }
          }
        }
      }
    }

    &-editer {
      // margin-top: 16px;
      // border-top: 1px solid var(--color-gray-2);
      padding-top: 16px;
      flex: 0 0 195px;
      &-top {
        padding-top: 16px;
        padding-bottom: 16px;
        border-top: 1px solid var(--color-gray-2);
        .MuiTextField-root.el-text label {
          font-size: 18px;
          @include s-1440 {
            font-size: 16px;
          }
          @include s-1024 {
            font-size: 15px;
          }
        }
        legend {
          width: 120px !important;
          @include s-1440 {
            width: 110px !important;
          }
          // @include s-1024 {
          //   width: 80px!important;
          // }
        }
        .MuiTextField-root.el-text .MuiInputBase-root {
          padding: 16px 24px;
          height: 112px;
          @include s-1440 {
            height: 96px;
            padding: 16px;
          }
          @include s-1024 {
            height: 96px;
            padding: 16px 12px;
          }
          textarea {
            font-size: 18px;
            // color: var(--color-gray-5);
            color: var(--color-black);
            line-height: 24px;
            @include s-1440 {
              font-size: 16px;
            }
            @include s-1024 {
              font-size: 15px;
            }
          }
        }
      }

      &-bottom {
        display: flex;
        justify-content: space-between;
      }
      &-left {
        display: flex;
        .el-button-icon {
          margin-right: 24px;
          width: 28px;
          height: 28px;
          min-width: 28px;

          @include s-1440 {
            margin-right: 16px;
            width: 24px;
            height: 24px;
            min-width: 24px;
          }

          @include s-1024 {
            margin-right: 12px;
            width: 20px;
            height: 20px;
            min-width: 20px;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
      &-btn {
        .el-button {
          min-width: 85px;
        }
      }
    }
  }
}
