.p-404 {
  position: relative;

  &-box {
    max-width: 832px;
    margin: 0 auto;
    padding: 0 16px;
    z-index: 1;

    @include s-1440 {
      max-width: 632px;
    }

    @include s-1024 {
      max-width: 432px;
    }

    &-image {
      position: absolute;
      inset: 0;
      margin: auto;
      z-index: -1;
      width: 1080px;
      height: 560px;

      @include s-1440 {
        width: 850px;
        height: 444px;
      }

      @include s-1024 {
        width: 704px;
        height: 366px;
      }

      & img {
        width: 100%;
        height: 100%;
        aspect-ratio: 1080/560;
      }
    }

    & h4 {
      padding-top: pxVh(540, 900);
      text-align: center;
      user-select: none;
      pointer-events: none;
    }

    & .el-button {
      margin-top: 48px;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;

      @include s-1440 {
        margin-top: 40px;
        max-width: 300px;
      }

      @include s-1024 {
        margin-top: 36px;
        max-width: 200px;
      }
    }
  }
}
