.p-account {
  & .settings {
    &-box {
      border-radius: 32px;
      border: 1px solid #d3d6d8;
      padding: 40px;
      margin-top: 48px;

      @include s-1440 {
        padding: 32px;
        margin-top: 40px;
      }

      @include s-1024 {
        padding: 28px;
        margin-top: 36px;
      }

      @include s-768 {
        padding: 20px;
        margin-top: 26px;
      }

      @include s-375 {
        padding: 16px;
        margin-top: 20px;
      }

      &-el {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 24px 0;

        @include s-1440 {
          padding: 16px 0;
        }

        @include s-1024 {
          padding: 12px 0;
        }

        &:first-of-type {
          margin-top: 30px;

          @include s-1440 {
            margin-top: 24px;
          }

          @include s-1024 {
            margin-top: 20px;
          }
        }

        &--icon {
          display: flex;
          width: 48px;

          @include s-1440 {
            width: 40px;
          }

          @include s-1024 {
            width: 36px;
          }
        }

        &--model {
          margin-left: 24px;
        }

        &--os {
          margin-left: 40px;
        }

        &--app {
          margin-left: 40px;
        }

        &--empty {
          color: var(--color-gray-5);
          margin-left: 8px;
          display: inline-flex;
        }
      }

      &-logout {
        margin-top: 24px;
        display: inline-flex;

        @include s-1440 {
          margin-top: 16px;
        }

        @include s-1024 {
          margin-top: 12px;
        }
      }

      &:first-of-type {
        margin-top: 0;
      }

      & .MuiFormControlLabel-root {
        align-items: flex-start;
        padding: 24px 0;
        margin-left: 0;

        @include s-1440 {
          padding: 16px 0;
        }

        @include s-1024 {
          padding: 12px 0;
        }

        &:first-of-type {
          margin-top: 24px;

          @include s-1440 {
            margin-top: 16px;
          }

          @include s-1024 {
            margin-top: 12px;
          }
        }
      }

      & .MuiSwitch-root {
        padding: 4px;
      }

      & .MuiSwitch-track {
        border-radius: 28px;
        background: transparent;
        border: 1px solid #c4c8cb;
        background-color: transparent;
        opacity: 1;
      }

      & .MuiSwitch-thumb {
        color: #a7acb1;
        box-shadow: none;
      }

      & .MuiSwitch-switchBase.Mui-checked {
        & .MuiSwitch-thumb {
          color: var(--color-orange-main);
        }

        & + .MuiSwitch-track {
          background-color: transparent;
        }
      }

      & .MuiSwitch-switchBase {
        &:hover {
          background-color: rgba(255, 11, 37, 0.04);
        }
      }

      & .MuiFormGroup-root.processing {
        & .MuiSwitch-thumb {
          color: #a7acb1;
        }
      }

      & .MuiTypography-root {
        margin-left: 32px;

        @include s-1440 {
          margin-left: 24px;
        }

        @include s-1024 {
          margin-left: 20px;
        }

        & .title {
          color: var(--color-black);
        }

        & .text {
          padding-top: 12px;
          display: block;
          color: var(--color-black);

          @include s-1440 {
            padding-top: 8px;
          }
        }
      }

      & .MuiTouchRipple-root {
        color: var(--color-orange-main);
      }
    }
  }
}
