.c-acc {
  &-content {
    & .deposit {
      &-wrap {
        margin: 0 auto;
        max-width: 760px;
        padding-bottom: 260px;

        @include s-1440 {
          padding-bottom: 200px;
          max-width: 600px;
        }

        @include s-1024 {
          padding-bottom: 160px;
          max-width: 544px;
        }
      }

      &-address {
        &-copy {
          .title {
            text-align: center;
            padding-bottom: 48px;

            @include s-1440 {
              padding-bottom: 40px;
            }

            @include s-1024 {
              padding-bottom: 36px;
            }
          }
          &-block {
            display: flex;
            border-radius: 12px;
            border: 1px solid var(--color-gray-3);
            align-items: center;
            justify-content: space-between;
            padding: 12px;

            & button {
              margin: 0 0 0 auto;
            }
          }
          &-ref {
            padding-right: 24px;
            font-size: 18px;
            line-height: 150%;

            @include s-1440 {
              font-size: 16px;
            }

            @include s-1024 {
              font-size: 15px;
            }

            @include s-768 {
              font-size: 13px;
            }

            @include s-375 {
              font-size: 11px;
              word-break: break-word;
            }
          }
          &-btn {
            min-width: 106px;
            width: 106px;
            height: 51px;
            @include s-1440 {
              min-width: 86px;
              width: 86px;
              height: 44px;
            }
            @include s-1024 {
              min-width: 75px;
              width: 75px;
              height: 39px;
            }
          }
        }
      }

      &-scan {
        padding-top: 48px;
        @include s-1440 {
          padding-top: 40px;
        }
        @include s-1024 {
          padding-top: 36px;
        }
        .title {
          text-align: center;
        }
        &-wrap {
          padding-top: 24px;
          display: flex;
          @include s-1440 {
            padding-top: 16px;
          }
          @include s-1024 {
            padding-top: 12px;
          }
        }
        &-code {
          width: 280px;
          height: 280px;
          border-radius: 14px;
          overflow: hidden;
          padding: 18px;
          border: 1px solid var(--color-gray-10);

          @include s-1440 {
            width: 240px;
            height: 240px;
            border-radius: 12px;
          }

          @include s-1024 {
            width: 200px;
            height: 200px;
          }

          & canvas {
            width: 100% !important;
            height: 100% !important;
          }
        }
        &-desc {
          flex: 1;
          padding-left: 24px;
          display: flex;
          align-items: center;
          @include s-1440 {
            padding-left: 16px;
          }
          @include s-1024 {
            padding-left: 12px;
          }
        }
        &-list {
          list-style-type: number;
          padding-left: 20px;
        }
        &-li {
          padding-bottom: 18px;
          color: var(--color-gray-7);
          font-size: 18px;
          font-style: normal;
          line-height: 150%;

          @include s-1440 {
            font-size: 16px;
            padding-bottom: 15px;
          }

          @include s-1024 {
            font-size: 15px;
            padding-bottom: 10px;
          }

          &:last-of-type {
            padding-bottom: 0;
          }
        }
      }

      &-note {
        padding-top: 48px;
        @include s-1440 {
          padding-top: 40px;
        }
        @include s-1024 {
          padding-top: 36px;
        }
        &-wrap {
          background-color: var(--color-blue);
          border-radius: 24px;
          padding: 40px;
          @include s-1440 {
            padding: 32px;
          }
          @include s-1024 {
            padding: 28px;
          }
        }
        &-list {
          padding: 24px 0 0 20px;
          list-style-type: initial;
          @include s-1440 {
            padding: 16px 0 0 20px;
          }
          @include s-1024 {
            padding: 12px 0 0 20px;
          }
        }
        &-li {
          padding-bottom: 15px;
          color: var(--color-gray-6);
          font-size: 18px;
          line-height: 150%;
          @include s-1440 {
            font-size: 16px;
            padding-bottom: 15px;
          }
          @include s-1024 {
            font-size: 15px;
            padding-bottom: 10px;
          }
          &:last-of-type {
            padding-bottom: 0;
          }
        }
      }

      &-pesa {
        padding-top: 48px;
        @include s-1440 {
          padding-top: 40px;
        }
        @include s-1024 {
          padding-top: 36px;
        }
        &-wrap {
          background: linear-gradient(97deg, #ecedef 41.66%, var(--color-orange-light) 100%);
          border-radius: 24px;
          padding: 40px;
          @include s-1440 {
            padding: 32px;
          }
          @include s-1024 {
            padding: 28px;
          }
        }

        &-list {
          padding: 24px 0 0 20px;
          list-style-type: initial;
          @include s-1440 {
            padding: 16px 0 0 20px;
          }
          @include s-1024 {
            padding: 12px 0 0 20px;
          }
        }
        &-li {
          padding-bottom: 15px;
          color: var(--color-gray-6);
          font-size: 18px;
          line-height: 150%;
          @include s-1440 {
            font-size: 16px;
            padding-bottom: 15px;
          }
          @include s-1024 {
            font-size: 15px;
            padding-bottom: 10px;
          }
          &:last-of-type {
            padding-bottom: 0;
          }
        }
        &-btn {
          padding-top: 24px;
          .el-button {
            max-width: 180px;
            font-size: 18px;
            font-family: var(--font-med);
            line-height: 150%;
            padding: 0;
            height: 51px;
            @include s-1440 {
              max-width: 152px;
              font-size: 16px;
              height: 44px;
            }
            @include s-1024 {
              max-width: 137px;
              font-size: 15px;
              height: 39px;
            }
          }
        }
      }

      &-accordion {
        padding-top: 48px;
        @include s-1440 {
          padding-top: 40px;
        }
        @include s-1024 {
          padding-top: 36px;
        }

        & .MuiAccordionSummary-root {
          padding: 0 24px;
          min-height: unset;

          @include s-1440 {
            padding: 0 16px;
          }

          @include s-1024 {
            padding: 0 12px;
          }
        }

        & .MuiAccordion-root {
          margin: 0;
          border-radius: 0;
          box-shadow: unset;
          margin-bottom: -1px;

          &:before {
            display: none;
          }

          &:first-of-type {
            border-radius: 16px 16px 0 0;
          }

          &:last-of-type {
            border-radius: 0 0 16px 16px;
          }

          & h6 {
            all: unset;
          }

          &.Mui-expanded {
            & .MuiAccordionSummary-content svg {
              transform: rotate(90deg);
            }
          }
        }

        & .MuiAccordionSummary-content {
          margin: 24px 0;

          @include s-1440 {
            margin: 16px 0;
          }

          @include s-1024 {
            margin: 12px 0;
          }

          & svg {
            margin-right: 24px;
            height: 28px;
            width: 28px;
            transition: transform ease 0.4s;

            @include s-1440 {
              height: 24px;
              width: 24px;
              margin-right: 16px;
            }

            @include s-1024 {
              height: 20px;
              width: 20px;
              margin-right: 12px;
            }
          }
        }

        & .MuiAccordionDetails-root {
          padding: 24px 24px 40px;

          @include s-1440 {
            padding: 16px 16px 32px;
          }

          @include s-1024 {
            padding: 12px 12px 28px;
          }
        }

        & .MuiTypography-root {
          all: unset;
          font-size: 16px;
          font-family: var(--font-reg);
          line-height: 125%;

          @include s-1440 {
            font-size: 14px;
          }

          @include s-1024 {
            font-size: 13px;
          }
        }
      }

      &-office {
        .title {
          text-align: center;
        }
        &-list {
          .item {
            padding-top: 40px;

            &-wrap {
              padding: 32px;
              border-radius: 24px;
              border: 1px solid var(--color-gray-3);

              @include s-375 {
                padding: 14px;
              }

              & .title {
                padding-bottom: 12px;
                text-align: left;
              }
            }
            &-list {
              border-top: 1px solid var(--color-gray-2);

              &-block {
                padding-top: 12px;
              }

              &-row {
                display: flex;
                padding: 8px 0 11px;
              }

              &-title {
                flex: 0 0 120px;
                color: var(--color-gray-5);
              }

              &-value {
                color: var(--color-black);
                flex: 1;
                padding-left: 24px;
                & .react-tel-input {
                  all: unset;

                  & .flag-dropdown {
                    display: none;
                  }

                  & input {
                    all: unset;
                  }
                }

                & li {
                  padding-bottom: 10px;
                  &:last-of-type {
                    padding-bottom: 0;
                  }
                }

                & a {
                  font-family: var(--font-reg);
                  font-size: 18px;
                  line-height: 150%;
                  @include s-1440 {
                    font-size: 16px;
                  }
                  @include s-1024 {
                    font-size: 15px;
                  }
                }
                .form-control {
                  color: var(--color-black);
                  font-family: var(--font-reg);
                  font-size: 18px;
                  line-height: 150%;
                  @include s-1440 {
                    font-size: 16px;
                  }
                  @include s-1024 {
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
