.c-pay-system {
  &--title {
    text-align: center;
  }

  &-list {
    display: flex;
    padding-top: 40px;
    gap: 32px;
    flex-wrap: wrap;

    @include s-1440 {
      gap: 24px;
    }

    @include s-1024 {
      gap: 20px;
    }

    @include s-768 {
      gap: 16px;
    }

    @include s-375 {
      justify-content: center;
    }

    &-btn {
      width: calc(50% - 16px);
      padding: 0;
      border-radius: 16px;
      border: 1px solid var(--color-gray-2);
      background-color: var(--color-white);
      display: block;
      transition: box-shadow ease 0.4s;
      box-shadow: 0px 8px 8px 0px #0b0c0d14;

      @include s-1440 {
        width: calc(50% - 12px);
      }

      @include s-1024 {
        width: calc(50% - 10px);
      }

      @include s-375 {
        min-height: 112px;
        width: 100%;
        display: flex;
        align-items: center;
      }

      &:hover {
        background-color: var(--color-white);
        box-shadow: 0 8px 12px 0 rgba(44, 47, 50, 0.1);
      }

      & .img {
        border-radius: 16px;
        overflow: hidden;

        @include s-375 {
          width: 80px;
          min-width: 80px;
          height: 80px;
          display: flex;
          order: 1;
          margin: 0 18px 0 auto;
        }

        img {
          width: 100%;
          display: block;
          object-fit: cover;
        }
      }

      & .title {
        font-size: 16px;
        font-family: var(--font-med);
        text-align: center;
        line-height: 150%;
        padding: 12px 16px;
        margin: 0;
        color: var(--color-gray-6);
        text-transform: none;

        @include s-375 {
          font-size: 18px;
          padding: 12px 32px;
          text-align: left;
        }

        .MuiTypography-root {
          margin: 0;
        }
      }
    }
  }
}
