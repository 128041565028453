.p-faq {
  & .s {
    &-hero {
      padding-top: 104px;

      @include s-1440 {
        padding-top: 94px;
      }

      @include s-1024 {
        padding-top: 80px;
      }

      @include s-375 {
        padding-top: 60px;
      }

      &-top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        padding-bottom: 240px;
        @include s-1440 {
          padding-bottom: 204px;
        }
        @include s-1024 {
          padding-bottom: 148px;
        }
        @include s-768 {
          flex-wrap: wrap;
          padding-bottom: 160px;
        }
        @include s-375 {
          padding-bottom: 140px;
        }
        &-title {
          padding-left: 50px;
          border-right: 1px solid var(--color-gray-3);
          @include s-1440 {
            padding-left: 40px;
          }
          @include s-1024 {
            padding-left: 32px;
          }
        }
        &-wrap {
          display: flex;
          width: 100%;
        }
        &-tabs {
          flex: 0 0 552px;

          @include s-1440 {
            flex: 0 0 420px;
          }
          @include s-1024 {
            flex: 0 0 300px;
          }
          .MuiPaper {
            &-elevation {
              background-color: transparent;
              border: 0 none;
              box-shadow: none;
              border-right: 1px solid var(--color-gray-3);
              &:before {
                display: none;
              }
            }
          }
          .MuiTabs {
            &-vertical {
              border: 0 none;
            }
            &-fixed {
              padding-left: 50px;
              @include s-1440 {
                padding-left: 40px;
              }
              @include s-1024 {
                padding-left: 32px;
              }
            }
            &-flexContainer {
              .MuiTab-textColorPrimary {
                justify-content: flex-start;
                align-items: flex-start;
                text-align: left;
                width: 100%;
                max-width: 100%;
                text-transform: none;
                color: var(--color-black);
                display: flex;
                color: var(--black, #0b0c0d);
                font-family: var(--font-reg);
                font-size: 20px;
                font-weight: 500;
                line-height: 120%;
                font-style: normal;
                padding-left: 0;
                &:hover {
                  transform: scale(1);
                }
                &.Mui-selected {
                  background-color: transparent;
                  font-style: italic;
                  font-family: var(--font-med);
                }
              }
            }
            &-indicator {
              left: 11px;
              display: flex;
              align-items: center;
              background-color: transparent;
              width: 48px;
              svg {
                width: 28px;
                height: 28px;
                @include s-1440 {
                  width: 24px;
                  height: 24px;
                }
                @include s-1024 {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }
        &-accordion {
          flex: 1;
          padding-left: 32px;
          @include s-1440 {
            padding-left: 20px;
          }
          @include s-768 {
            // padding-left: 0;
            // padding-top: 40px;
          }
          .MuiPaper {
            &-elevation {
              background-color: transparent;
              border: 0 none !important;
              box-shadow: none;
              margin-top: 0;
              &:before {
                display: none;
              }
            }
          }
          .MuiAccordionDetails {
            &-root {
              padding: 20px 20px 80px;
              background-color: var(--color-gray-8);
              border-radius: 20px;
              .tab-content {
                font-size: 18px;
                font-style: normal;
                line-height: 150%;
              }
            }
          }
          .MuiAccordionSummary {
            &-root {
              margin: 0;
              min-height: 0;
              margin-bottom: 20px;
              border: 0 none;
              padding: 0;
              &.Mui-expanded {
                min-height: 0;
                .MuiAccordionSummary-content {
                  background-color: var(--color-gray-7);
                }
                .position {
                  color: var(--color-white);
                }
                .icon {
                  #plus {
                    transition: transform 0.3s;
                    transform: translate(18px, -7px) rotate(48grad);
                  }
                }
              }
            }
            &-content {
              margin: 0;
              display: inline-flex;
              align-items: center;
              border: 1px solid var(--color-gray-3);
              padding: 20px;
              justify-content: space-between;
              border-radius: 20px;
              &.Mui-expanded {
                margin: 0;
              }
              .icon {
                height: 42px;
                height: 42px;
                @include s-1440 {
                  height: 36px;
                  height: 36px;
                }
                svg {
                  width: 100%;
                  height: 100%;
                  #plus {
                    transition: transform 0.3s;
                  }
                }
              }
            }
            &-expandIcon {
              padding: 0;
              margin: 0;
              &.Mui-expanded {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
