.c-footer {
  position: relative;
  color: #d3d6d8;
  padding-top: 50px;
  z-index: 1;

  &--bg {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    & picture {
      position: absolute;
      left: 50%;
      top: 50%;
      height: 100%;
      transform: translate(-50%, -50%);
      line-height: 0;
      width: 100%;
    }

    & img {
      object-fit: cover;
      height: 100%;
    }
  }

  &-top {
    border: 1px solid #2c2f32;
    border-radius: 20px;
    display: flex;

    @include s-375 {
      flex-wrap: wrap;
    }

    &-desc {
      padding: 60px;
      max-width: 570px;
      border-right: 1px solid #2c2f32;

      @include s-1440 {
        padding: 50px;
        max-width: 430px;
      }

      @include s-1024 {
        padding: 20px 40px;
        max-width: 340px;
      }

      @include s-768 {
        padding: 40px;
        max-width: 240px;
      }

      @include s-375 {
        max-width: 100%;
        border: none;
        padding: 20px;
      }

      &--title {
        color: var(--color-orange-main);
        font-size: 26px;
        @include s-1440 {
          font-size: 20px;
        }
      }

      &--subtitle {
        display: block;
        padding-top: 60px;
        font-size: 18px;
        line-height: 150%;

        @include s-1440 {
          padding-top: 50px;
          font-size: 16px;
        }

        @include s-1024 {
          font-size: 15px;
          padding-top: 34px;
        }

        @include s-768 {
          font-size: 15px;
        }

        @include s-375 {
          padding-top: 18px;
        }
      }

      & a {
        display: block;
        margin-top: 20px;

        &:hover {
          color: inherit;
          text-decoration: underline;
        }

        @include s-375 {
          margin-top: 10px;
        }
      }
    }

    &-links {
      width: 100%;

      @include s-375 {
        border-top: solid 1px #2c2f32;
      }

      &-inner {
        padding: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;
        gap: 60px;
        @include s-1440 {
          gap: 50px;
        }
        @include s-1024 {
          padding: 20px 24px;
          gap: 40px;
        }

        @include s-768 {
          padding: 40px 30px;
        }

        @include s-375 {
          padding: 22px 20px 10px 20px;
        }
      }

      & .links-list {
        // padding-right: 20px;
        // flex: 0 0 22.4%;
        flex: 0 0 170px;
        max-width: 170px;
        @include s-1024 {
          flex: 0 0 33%;
          padding-right: 20px;
        }

        @include s-375 {
          flex: 0 0 100%;
          padding-right: 0;
          padding-top: 10px;
        }

        &:first-of-type {
          padding-top: 0;
        }

        &--title {
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 26px;
          @include s-1440 {
            font-size: 20px;
          }

          & .icon-dropdown {
            display: none;

            @include s-375 {
              display: flex;
              width: 36px;
              height: 36px;
            }
          }
        }

        & a {
          line-height: 150%;
          margin-top: 10px;
          font-family: var(--font-reg);
          font-size: 18px;
          @include s-1440 {
            margin: 5px 0;
            font-size: 16px;
          }
        }

        & ul {
          overflow: hidden;

          @include s-375 {
            height: 0;
          }
        }
      }

      & .links-store {
        padding: 64px 60px;
        border-top: solid 1px #2c2f32;

        @include s-1440 {
          padding: 50px;
        }

        @include s-1024 {
          padding: 64px 24px 20px 24px;
        }

        @include s-768 {
          padding: 40px 24px 40px 40px;
        }

        @include s-375 {
          padding: 20px;
        }

        &--title {
          display: flex;
          justify-content: flex-start;
          align-items: stretch;
          color: var(--color-white);
        }

        &--items {
          padding-top: 20px;

          @include s-1024 {
            padding-top: 14px;
          }

          & a {
            border: 1px solid #6d757d;
            border-radius: 5px;
            line-height: 0;
            margin-right: 50px;
            max-width: 180px;
            transition: transform ease 0.3s;

            &:hover {
              transform: scale(1.05);
            }

            @include s-1440 {
              margin-right: 40px;
            }

            @include s-1024 {
              margin-right: 20px;
              max-width: 168px;
            }

            @include s-375 {
              margin-right: 20px;
              max-width: 136px;
            }

            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  &-bottom {
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-white);
    font-size: 16px;
    line-height: 150%;

    @include s-1440 {
      font-size: 14px;
      padding: 20px 0;
    }

    @include s-1024 {
      padding: 32px 0;
    }

    @include s-768 {
      font-size: 13px;
      padding: 18px 0;
    }

    @include s-375 {
      flex-wrap: wrap;
      font-size: 14px;
      padding: 12px 0 72px 0;
    }

    & .development {
      display: flex;
      align-items: center;
      margin: 0 0 0 auto;

      @include s-375 {
        margin: 12px 0 0 0;
        order: 3;
      }
      &--text {
        font-size: 16px;
        @include s-1440 {
          font-size: 14px;
        }
        @include s-1024 {
          font-size: 13px;
        }
      }
      & img {
        margin: 0 10px;
        width: 24px;
        height: 16px;
        display: inline-block;

        @include s-375 {
          margin: 0 8px;
          width: 22px;
          height: 14px;
        }
      }

      & a {
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        @include s-1440 {
          font-size: 14px;
        }
        @include s-1024 {
          font-size: 13px;
        }
      }
    }

    & .scrollup {
      margin-left: 24px;
      cursor: pointer;
      transition: transform ease 0.3s;

      &:hover {
        transform: scale(1.05);
      }

      @include s-1024 {
        margin-left: 10px;
      }

      @include s-375 {
        display: flex;
        order: 2;
      }

      & svg {
        transform: rotate(90deg);
        width: 42px;
        height: 42px;

        @include s-1440 {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
}
