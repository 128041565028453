.c-acc {
  &-footer {
    border-radius: 24px 0px 0px 0px;
    overflow: hidden;
    position: relative;
    padding: 48px 48px 48px 260px;
    margin: auto 0 0 0;

    @include s-1440 {
      padding: 48px 48px 48px 160px;
    }

    @include s-1024 {
      padding: 36px 40px;
    }

    @include s-768 {
      padding: 26px 34px;
      margin-right: -20px;
    }

    @include s-375 {
      padding: 20px 30px;
    }

    &-wrap {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      @include s-375 {
        flex-wrap: wrap;
      }
    }

    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background:
          radial-gradient(
            58.96% 270.71% at 58.96% 50%,
            rgba(0, 0, 0, 0.9) 0%,
            rgba(0, 0, 0, 0) 100%
          ),
          lightgray 50% / cover no-repeat;
        mix-blend-mode: exclusion;
        width: 100%;
        height: 100%;
        opacity: 0.2;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-app {
      & .title {
        font-size: 24px;
        font-family: var(--font-med);
        line-height: 120%;

        @include s-1440 {
          font-size: 20px;
        }

        @include s-1024 {
          font-size: 18px;
        }

        @include s-768 {
          font-size: 16px;
        }

        @include s-375 {
          font-size: 14px;
        }
      }

      & .list {
        padding-top: 32px;
        @include s-1440 {
          padding-top: 24px;
        }
        @include s-1024 {
          padding-top: 20px;
        }

        &-btn {
          cursor: pointer;
          display: inline-block;
          max-width: 225px;
          @include s-1440 {
            max-width: 180px;
          }
          @include s-1024 {
            max-width: 157px;
          }

          @include s-375 {
            max-width: 100px;
          }

          &.store {
            margin-left: 48px;

            @include s-1440 {
              margin-left: 40px;
            }

            @include s-1024 {
              margin-left: 36px;
            }

            @include s-375 {
              margin-left: 14px;
            }
          }
        }
      }
    }

    &-get {
      align-items: center;
      display: inline-flex;

      @include s-768 {
        margin: 0 0 0 auto;
      }

      & .title {
        padding-right: 16px;
        font-size: 16px;
        font-family: var(--font-med);
        line-height: 150%;

        @include s-1440 {
          font-size: 14px;
        }

        @include s-1024 {
          font-size: 13px;
        }

        @include s-768 {
          font-size: 11px;
        }

        @include s-375 {
          font-size: 10px;
        }
      }
      & .link {
        display: block;
        width: 48px;
        @include s-1440 {
          width: 40px;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
