.c-impact {
  &-top {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include s-1440 {
      padding-top: 24px;
    }
    @include s-1024 {
      padding-top: 16px;
    }
  }

  &-title {
    font-size: 24px;
    font-family: var(--font-med);
    line-height: 120%;
    @include s-1440 {
      font-size: 20px;
    }
  }

  &-filter {
    padding-left: 5px;
    .el-button {
      padding: 4px 16px 4px 14px;
      min-height: 32px;
      border-radius: 16px;
      background: var(--color-gray-1);
      border: 1px solid var(--color-gray-1);
    }
  }

  &-list {
    padding-top: 24px;
    @include s-1440 {
      padding-top: 16px;
    }
    @include s-1024 {
      padding-top: 12px;
    }
  }

  &-block {
    padding-top: 32px;
    @include s-1440 {
      padding-top: 16px;
    }
    @include s-1024 {
      padding-top: 12px;
    }
    &:first-of-type {
      padding-top: 0;
    }

    &-wrap {
      display: flex;
      border-radius: 16px;
      overflow: hidden;
      border: 1px solid var(--color-gray-2);
      
      &:hover {
        box-shadow: 0 8px 12px 0 rgba(44, 47, 50, 0.1);
      }
    }

    &-img {
      flex: 0 0 424px;
      @include s-1440 {
        flex: 0 0 348px;
      }
      img {
        object-fit: cover;
        height: 100%;
      }
    }

    &-content {
      flex: 1;
      padding: 40px;
      min-height: 270px;
      @include s-1440 {
        padding: 32px;
      }
      @include s-1024 {
        padding: 28px;
      }
      &-wrap {
        position: relative;
        height: 100%;
      }
    }
    &-name {
      font-family: var(--font-med);
      font-size: 18px;
      line-height: 150%;
      @include s-1440 {
        font-size: 16px;
      }
      @include s-1024 {
        font-size: 15px;
      }
    }

    &-desc {
      padding-top: 5px;
      font-size: 16px;
      line-height: 150%;
      @include s-1440 {
        font-size: 14px;
      }
      @include s-1024 {
        font-size: 13px;
      }
    }

    &-btn {
      position: absolute;
      left: 0;
      bottom: 0;
      .el-button {
        max-width: 200px;
        @include s-1440 {
          max-width: 160px;
        }
      }
    }
  }
}
