.p-post {
  background: #f0f1f2;

  & .s {
    &-post {
      margin-top: 104px;

      @include s-1440 {
        margin-top: 94px;
      }

      @include s-1024 {
        margin-top: 80px;
      }

      @include s-375 {
        margin-top: 64px;
      }

      & .c-container {
        @include s-375 {
          padding: 0;
        }
      }

      & .bottom {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include s-768 {
          flex-direction: row;
          padding-top: 16px;
        }

        &--title {
          font-size: 16px;
          text-transform: uppercase;
          color: #2c2f32;
          display: block;
          padding-bottom: 10px;

          @include s-1440 {
            padding-bottom: 6px;
            font-size: 14px;
          }

          @include s-1024 {
            font-size: 13px;
          }

          @include s-768 {
            padding: 0;
            line-height: 100%;
          }

          @include s-375 {
            display: none;
          }
        }

        &-row {
          @include s-768 {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          }

          & ul {
            display: flex;
            list-style-type: none;
          }

          & li {
            padding: 0;
            margin-right: 16px;

            &:hover {
              & svg {
                transform: scale(1.05);
              }
            }

            @include s-1024 {
              margin-right: 12px;
            }
          }

          & svg {
            width: 42px;
            height: 42px;
            margin-right: 12px;
            transform-origin: center;
            transition: transform ease 0.3s;

            @include s-1440 {
              width: 36px;
              height: 36px;
            }

            @include s-768 {
              margin: 0 0 0 12px;
            }
          }
        }
      }

      &-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        background: #ffffff;
        border-radius: 20px;
        padding: 50px;

        @include s-1440 {
          padding: 40px;
        }

        @include s-1024 {
          padding: 30px;
          border-radius: 15px;
        }

        @include s-768 {
          flex-wrap: wrap;
        }

        @include s-375 {
          padding: 20px 20px 26px;
        }
      }

      &-aside {
        max-width: 502px;
        width: 100%;

        @include s-1440 {
          max-width: 360px;
        }

        @include s-1024 {
          max-width: 270px;
        }

        @include s-768 {
          max-width: 668px;
        }

        @include s-375 {
          max-width: 335px;
        }

        &-top {
          min-height: 90vh;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: stretch;
          position: sticky;
          top: 80px;

          @include s-1440 {
            top: 60px;
          }

          @include s-1024 {
            top: 40px;
          }

          @include s-768 {
            top: 0;
            position: relative;
            min-height: unset;
          }

          &-image {
            border-radius: 19px;
            overflow: hidden;

            & img {
              aspect-ratio: 502/346;

              @include s-1440 {
                aspect-ratio: 360/248;
              }

              @include s-1024 {
                aspect-ratio: 270/186;
              }

              @include s-768 {
                aspect-ratio: 668/452;
              }

              @include s-375 {
                aspect-ratio: 335/227;
              }
            }
          }

          &-back {
            margin-top: 24px;
            display: inline-flex;
            align-items: center;
            line-height: 150%;
            text-transform: uppercase;
            color: #2c2f32;
            font-size: 16px;

            &:hover {
              & svg {
                transform: scale(1.05);
              }
            }

            @include s-1440 {
              margin-top: 20px;
              font-size: 14px;
            }

            @include s-1024 {
              margin-top: 16px;
              font-size: 13px;
            }

            @include s-768 {
              margin: 0;
            }

            & svg {
              width: 42px;
              height: 42px;
              margin-right: 12px;
              transform-origin: center;
              transition: transform ease 0.3s;

              @include s-1440 {
                width: 36px;
                height: 36px;
              }
            }
          }
        }

        &-bottom {
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: space-between;

          @include s-768 {
            flex-direction: row;
            padding-top: 14px;
          }

          @include s-375 {
            padding-top: 16px;
          }
        }

        & .bottom-row {
          & li {
            margin-right: 0;
          }

          & svg {
            @include s-768 {
              margin-right: 0;
              margin-left: 12px;
            }
          }
        }
      }

      &-article {
        max-width: 844px;
        width: 100%;
        margin-left: 32px;
        padding-bottom: 194px;

        @include s-1440 {
          padding-bottom: 160px;
          margin-left: 40px;
          max-width: 640px;
        }

        @include s-1024 {
          margin-left: 20px;
          max-width: 590px;
          padding-bottom: 130px;
        }

        @include s-768 {
          max-width: 668px;
          margin: 30px 0 0 0;
          padding-bottom: 0;
        }

        @include s-375 {
          margin: 26px 0 0 0;
          max-width: 335px;
        }

        &--date {
          color: #6d757d;
          display: block;
          padding-top: 16px;
          font-size: 16px;
          line-height: 150%;

          @include s-1440 {
            padding-top: 12px;
            font-size: 14px;
          }

          @include s-1024 {
            font-size: 13px;
          }
        }

        & h6,
        h5 {
          display: block;
          padding-top: 50px;

          @include s-1440 {
            padding-top: 40px;
          }

          @include s-1024 {
            padding-top: 32px;
          }

          @include s-768 {
            padding-top: 28px;
          }
        }

        ul {
          line-height: 150%;
          color: #2c2f32;
          list-style-type: disc;
          padding-left: 26px;
          padding-top: 20px;
          font-size: 18px;

          @include s-1440 {
            padding-left: 20px;
            padding-top: 16px;
            font-size: 16px;
          }

          @include s-1024 {
            padding-left: 22px;
            padding-top: 12px;
            font-size: 15px;
          }

          & li:not(:first-of-type) {
            padding-top: 10px;
          }
        }

        & p {
          padding-top: 20px;
          line-height: 140%;
          padding-bottom: 0;
          font-size: 18px;

          @include s-1440 {
            padding-top: 16px;
            font-size: 16px;
          }

          @include s-1024 {
            font-size: 15px;
          }
        }

        & b,
        strong {
          font-weight: bold;
        }

        &-img {
          margin-top: 46px;
          margin-bottom: 16px;
          overflow: hidden;

          @include s-1440 {
            margin-top: 36px;
          }

          @include s-1024 {
            margin-top: 30px;
          }

          @include s-375 {
            margin-top: 26px;
          }

          &--subtitle {
            display: block;
            padding-top: 16px;
            font-size: 16px;
            line-height: 150%;
            color: #6d757d;
            padding-bottom: 6px;

            @include s-1440 {
              padding-top: 8px;
              font-size: 14px;
            }

            @include s-1024 {
              padding-bottom: 0;
              font-size: 13px;
            }
          }

          & img {
            aspect-ratio: 844/586;
            height: 100%;
            object-fit: cover;

            @include s-1440 {
              aspect-ratio: 640/446;
            }

            @include s-1024 {
              aspect-ratio: 590/412;
            }
          }
        }

        & .bottom-row {
          display: none;

          @include s-768 {
            display: block;
            padding-top: 76px;
          }

          @include s-375 {
            padding-top: 46px;
          }

          & svg {
            margin: 0;
          }

          & ul {
            padding: 10px 0 0 0;
          }

          & li {
            padding: 0 !important;
          }

          & .bottom--title {
            @include s-375 {
              display: block;
            }
          }
        }
      }

      & a {
        display: inline-block;
        font-family: var(--font-bold);
        margin: 0 4px;

        &:hover {
          text-decoration: underline;
          color: inherit;
        }
      }

      & mark {
        margin: 0 4px;
      }
    }

    &-recent {
      @include pad240;

      & h2 {
        padding-bottom: 10px;

        @include s-1440 {
          padding-bottom: 40px;
        }

        @include s-1024 {
          padding-bottom: 30px;
        }

        @include s-375 {
          padding-bottom: 22px;
        }
      }
    }
  }

  & .c-footer {
    margin-top: 200px;

    @include s-1440 {
      margin-top: 160px;
    }

    @include s-1024 {
      margin-top: 140px;
    }

    @include s-768 {
      margin-top: 120px;
    }

    @include s-375 {
      margin-top: 100px;
    }
  }
}
