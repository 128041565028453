h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  font-family: var(--font-semi);
  color: inherit;
  text-decoration: none;
  display: inline-block;
  transition: color ease 0.3s;
  &:hover {
    text-decoration: none;
  }
}

p {
  padding-bottom: 24px;
  line-height: 150%;

  @include s-1440 {
    padding-bottom: 24px;
  }

  @include s-1024 {
    padding-bottom: 16px;
  }
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.el-title--h8 {
  font-weight: 400;
  font-size: 22px;
  line-height: 120%;
}

blockquote {
  position: relative;
  z-index: 1;
  margin: 100px -40px 0 -40px;
  background-color: var(--color-categories-10);
  padding: 40px;
  line-height: 140%;
  font-family: var(--font-semibold);
}

b,
strong {
  font-family: var(--font-semi);
  font-weight: 600;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 600000s 0s,
    color 600000s 0s;
}

.el {
  &-checkbox {
    position: relative;

    & label {
      padding-left: 44px;
      font-size: 20px;
      display: block;
      cursor: pointer;
      letter-spacing: 0.02em;
    }

    & a {
      text-decoration: underline;
      display: inline;
      margin-left: 5px;
    }
  }
}

.el-link-underline {
  text-decoration: underline;
  color: var(--color-gray-6);
  // font-weight: 600;
  font-family: var(--font-semi);
  &:hover {
    text-decoration: none;
    color: var(--color-gray-6);
  }
}

.c-tooltip {
  & .MuiTooltip-tooltip {
    margin-top: 8px !important;
    max-width: 270px;
  }
}

.infinite-scroll-component  {
  overflow: unset !important;
}
