.p-decline-agent {
  .send-step {
    max-width: 100%;
    margin: 0 auto;
    max-width: 760px;
    @include s-1440 {
      max-width: 600px;
    }
    @include s-1024 {
      max-width: 544px;
    }
  }
  .decline {
    padding: 32px;
    border-radius: 24px;
    border: 1px solid var(--color-gray-2);
    &-list {
      list-style: none;
    }
    &-li {
      padding: 9px;
      label {
        font-size: 14px;
        line-height: 150%;
      }
    }
    &-comment {
      padding-top: 8px;
    }
  }
}
