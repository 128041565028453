@import '../components/acc/c-acc-sidebar';
@import '../components/acc/c-acc-header';
@import '../components/acc/c-acc-content';
@import '../components/acc/c-acc-impact';
@import '../components/acc/c-acc-footer';
@import '../components/acc/c-acc-deposit';
@import '../components/acc/c-acc-activityItem';
@import '../components/acc/c-acc-pay-system';
@import '../components/acc/c-acc-arrow-back';

@import '../pages/p-acc-activity';
@import '../pages/p-acc-contacts';
@import '../pages/p-acc-settings';
@import '../pages/p-acc-dashboard';

.p-account {
  display: flex;
  flex: 1;
  align-items: stretch;
  padding-top: 100px;

  @include s-1440 {
    padding-top: 78px;
  }

  @include s-1024 {
    padding-top: 68px;
  }

  @include s-768 {
    flex-wrap: nowrap;
  }

  @include s-375 {
    flex-wrap: wrap;
  }

  & .account-edits {
    &--subtitle {
      text-align: center;
      color: var(--color-gray-6);
    }

    &-form {
      padding-top: 48px;

      @include s-1440 {
        padding-top: 40px;
      }
      @include s-1024 {
        padding-top: 36px;
      }
    }
  }

  & .c-header {
    background: var(--color-white);
    padding: 26px 48px;
    height: 102px;

    @include s-1440 {
      padding: 18px 40px;
      height: 78px;
    }

    @include s-1024 {
      padding: 12px 32px;
      height: 68px;
    }

    @include s-375 {
      padding: 10px 20px;
      height: 60px;
      position: fixed;
    }

    & .c-header-logo {
      @include s-375 {
        max-width: 100px;
      }
    }
  }

  &.without {
    background-color: transparent;

    & .c-acc-content {
      padding-top: 48px;

      @include s-1440 {
        padding-top: 32px;
      }

      @include s-1024 {
        padding-top: 24px;
      }

      @include s-375 {
        padding: 40px 16px 16px;
      }
    }

    & .payment-title {
      text-align: center;

      & .el-title-h6 {
        & .orange {
          color: var(--color-orange-main);
        }
      }

      &--subtitle {
        display: block;
        text-align: center;
        color: var(--color-gray-7);
        padding-top: 16px;

        @include s-1440 {
          padding-top: 12px;
        }

        @include s-1024 {
          padding-top: 10px;
        }
      }
    }

    & .deposit {
      max-width: 760px;
      margin: 0 auto;
    }

    & .buy {
      max-width: 760px;
      margin: 0 auto;
      padding-bottom: 260px;

      @include s-1440 {
        padding-bottom: 200px;
        max-width: 600px;
      }

      @include s-1024 {
        padding-bottom: 160px;
        max-width: 544px;
      }

      &-step {
        &-3 {
          h4 {
            font-size: 24px;

            @include s-1440 {
              font-size: 20px;
            }

            @include s-768 {
              font-size: 18px;
            }

            @include s-375 {
              font-size: 16px;
            }
          }
          .buy-step-wrap {
            padding-top: 0;
          }
          .c-tfa-box-btns {
            max-width: 100%;
          }
        }
        &-4 {
          padding-top: 32px;
        }

        &-wrap {
          padding-top: 48px;
          @include s-1440 {
            padding-top: 40px;
          }
          @include s-1024 {
            padding-top: 36px;
          }
        }

        &-exchange {
          display: flex;
          justify-content: center;
          padding-bottom: 16px;

          &-block {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 8px;
            border-radius: 8px;
            background-color: var(--color-buy-bg);
          }
          &-note {
            width: 24px;
            height: 24px;
            @include s-1440 {
              width: 16px;
              height: 16px;
            }
          }
          &-text {
            font-size: 16px;
            line-height: 150%;
            padding-left: 8px;
            @include s-1440 {
              font-size: 14px;
            }
            @include s-1024 {
              font-size: 13px;
            }
          }
        }

        &-content {
          .item {
            &-wrap {
              display: flex;
              padding: 32px;
              border-radius: 24px;
              border: 1px solid var(--color-gray-2);
              align-items: center;
              justify-content: space-between;

              @include s-1440 {
                font-size: 14px;
              }

              @include s-1024 {
                font-size: 13px;
              }
            }

            &-left {
              flex: 1;
            }

            &-title {
              font-family: var(--font-med);
              line-height: 150%;
              font-size: 16px;

              @include s-1440 {
                font-size: 14px;
              }

              @include s-1024 {
                font-size: 13px;
              }
            }

            &-count {
              &.value-entered {
                color: var(--color-black);
              }
              &-input {
                border: 0;
                &.value-entered {
                  .MuiInputBase-input {
                    color: var(--color-black);
                  }
                }

                & .MuiOutlinedInput-root {
                  border: 0;

                  &:hover {
                    border: 0;
                    background-color: transparent;
                  }
                }
                .MuiOutlinedInput-notchedOutline {
                  border: 0;
                }
                .MuiInputBase-input {
                  font-size: 56px;
                  font-family: var(--font-med);
                  line-height: 120%;
                  color: var(--color-gray-4);
                  padding: 0;
                  text-align: left;

                  @include s-1440 {
                    font-size: 44px;
                  }

                  @include s-1024 {
                    font-size: 40px;
                  }
                }
              }
            }

            &-right {
              width: 110px;
              @include s-1440 {
                width: 88px;
              }
              @include s-1024 {
                width: 82px;
              }
            }

            &-currency {
              padding: 8px 12px;
              border-radius: 24px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              border: 1px solid var(--color-gray-2);

              @include s-1440 {
                padding: 6px 8px;
              }
              @include s-1024 {
                padding: 6px 8px;
              }
              &-icon {
                width: 28px;
                height: 28px;
                min-width: 28px;
                @include s-1440 {
                  width: 24px;
                  height: 24px;
                  min-width: 24px;
                }
                @include s-1024 {
                  width: 20px;
                  height: 20px;
                  min-width: 20px;
                }
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              &-text {
                padding-left: 12px;
                color: var(--color-gray-7);
                font-size: 18px;
                line-height: 150%;
                @include s-1440 {
                  font-size: 16px;
                  padding-left: 8px;
                }
                @include s-1024 {
                  // padding-left: 6px;
                  font-size: 15px;
                }
              }
            }

            &-balance {
              // font-size: 14px;
              color: var(--color-gray-5);

              // @include s-1440 {
              //   font-size: 13px;
              // }
              // @include s-1024 {
              //   font-size: 12px;
              // }
            }

            &.error {
              & .item-balance,
              & .MuiInputBase-input {
                color: var(--color-send);
              }
            }
          }
          .el-title-h6 {
            padding-bottom: 0;
          }
          .c-tfa-box-code {
          }
        }

        &-arrow {
          height: 4px;
          position: relative;
          &-wrap {
            height: 4px;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            display: flex;
            justify-content: center;
          }
          &-icon {
            transform: translateY(-50%);
            width: 64px;
            height: 64px;
            @include s-1440 {
              width: 48px;
              height: 48px;
            }
            @include s-1024 {
              width: 44px;
              height: 44px;
            }
          }
        }
      }

      &-img {
        max-width: 200px;
        margin: 0 auto;
      }

      &-desc {
        text-align: center;
        &-title {
          padding-top: 80px;
          font-size: 44px;
          font-family: var(--font-med);
          line-height: 120%; /* 52.8px */
        }
        &-text {
          padding-top: 16px;
          font-size: 16px;
          line-height: 150%;
        }
        .el-button-link {
          color: var(--color-black);
          margin-left: 8px;
          font-family: var(--font-bold);
        }
      }

      &-btns {
        padding-top: 48px;
        @include s-1440 {
          padding-top: 40px;
        }
        @include s-1024 {
          padding-top: 36px;
        }

        &-cancel {
          padding-top: 24px;
          display: flex;
          justify-content: center;

          @include s-1440 {
            padding-top: 16px;
          }

          @include s-1024 {
            padding-top: 12px;
          }

          &-wrap {
            display: inline-flex;
          }
        }

        &-bottom {
          padding-top: 16px;
        }
      }

      & .flow-message {
        margin-top: 48px;

        @include s-1440 {
          margin-top: 40px;
        }

        @include s-1024 {
          margin-top: 36px;
        }
      }
    }

    & .send {
      // max-width: 760px;
      max-width: 100%;
      margin: 0 auto;
      padding-bottom: 260px;

      @include s-1440 {
        padding-bottom: 200px;
        // max-width: 600px;
      }

      @include s-1024 {
        padding-bottom: 160px;
        // max-width: 544px;
      }

      &-step {
        max-width: 760px;
        margin: 0 auto;

        @include s-1440 {
          max-width: 600px;
        }

        @include s-1024 {
          max-width: 544px;
        }
        &-3 {
          h4 {
            font-size: 24px;

            @include s-1440 {
              font-size: 20px;
            }

            @include s-768 {
              font-size: 18px;
            }

            @include s-375 {
              font-size: 16px;
            }
          }
          h6 {
            padding-bottom: 0;
            & .orange {
              color: var(--color-orange-main);
            }
          }
          .c-tfa-box-btns {
            max-width: 100%;
          }
        }
        &-4 {
          padding-top: 32px;
        }
        &-wrap {
          padding-top: 48px;
          @include s-1440 {
            padding-top: 40px;
          }
          @include s-1024 {
            padding-top: 36px;
          }
        }
        &-exchange {
          display: flex;
          justify-content: center;
          padding-bottom: 16px;
          &-block {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 8px;
            border-radius: 8px;
            background-color: var(--color-buy-bg);
          }
          &-note {
            width: 24px;
            height: 24px;
          }
          &-text {
            font-size: 14px;
            line-height: 150%;
            padding-left: 8px;
          }
        }
        &-content {
          .item {
            &-wrap {
              display: flex;
              padding: 32px;
              border-radius: 24px;
              border: 1px solid var(--color-gray-2);
              align-items: center;
              justify-content: space-between;
            }
            &-left {
              flex: 1;
            }
            &-title {
              font-size: 14px;
              font-family: var(--font-med);
              line-height: 150%; /* 21px */
            }
            &-count {
              font-size: 44px;
              font-family: var(--font-med);
              line-height: 120%;
              color: var(--color-gray-4);
              &.value-entered {
                color: var(--color-black);
              }
              &-input {
                border: 0;
                .MuiOutlinedInput-root {
                  border: 0;
                  &:hover {
                    border: 0;
                  }
                }
                .MuiOutlinedInput-notchedOutline {
                  border: 0;
                }
                .MuiInputBase-input {
                  font-size: 44px;
                  font-family: var(--font-med);
                  line-height: 120%;
                  color: var(--color-gray-4);
                  padding: 0;
                }
              }
            }

            &-right {
              width: 88px;
            }
            &-currency {
              padding: 8px 0 8px 8px;
              border-radius: 24px;
              display: flex;
              align-items: center;
              justify-content: centeru;
              border: 1px solid var(--color-gray-2);
              &-icon {
                width: 24px;
                height: 24px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              &-text {
                padding-left: 8px;
                color: var(--color-gray-7);
                font-size: 16px;
                line-height: 150%; /* 24px */
              }
            }
          }
        }
        &-arrow {
          height: 4px;
          position: relative;
          &-wrap {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            display: flex;
            justify-content: center;
          }
          &-icon {
            transform: translateY(-50%);
          }
        }
      }

      &-img {
        max-width: 200px;
        margin: 0 auto;
      }

      &-desc {
        text-align: center;
        &-title {
          padding-top: 80px;
          font-size: 44px;
          font-family: var(--font-med);
          line-height: 120%; /* 52.8px */
        }
        &-text {
          padding-top: 16px;
          font-size: 16px;
          line-height: 150%;
        }
        .el-button-link {
          color: var(--color-black);
          margin-left: 8px;
          font-family: var(--font-bold);
        }
      }

      &-btns {
        padding-top: 48px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        @include s-1440 {
          padding-top: 40px;
        }
        @include s-1024 {
          padding-top: 36px;
        }
        &-link {
          margin: 0 auto;
        }

        &-cancel {
          margin: 0 auto;
        }
        &-users {
          &-wrap {
            padding-top: 40px;
            button {
              // background-color: var(--color-gray-2);
              width: auto;
              font-family: var(--font-reg);
              font-size: 16px;
              color: var(--color-black);
              @include s-1440 {
                font-size: 14px;
              }
              @include s-1024 {
                font-size: 13px;
              }
            }
          }
        }
      }

      &-subtitle {
        padding-top: 12px;
        display: block;

        @include s-1440 {
          padding-top: 8px;
        }
      }

      & .contacts {
        &-search {
          padding: 32px;
          border-radius: 28px;
          background: #ecedef;

          @include s-1024 {
            padding: 28px;
          }

          &-field {
            padding-top: 32px;

            @include s-1440 {
              padding-top: 24px;
            }

            @include s-1024 {
              padding-top: 20px;
            }
          }

          &-empty {
            padding-top: 48px;

            @include s-1440 {
              padding-top: 40px;
            }

            @include s-1024 {
              padding-top: 36px;
            }
            &-wrap {
              padding-top: 24px;
              padding-bottom: 24px;
              font-size: 16px;
              line-height: 150%;
              color: var(--color-gray-5);
            }
          }
        }

        &-block {
          padding-top: 48px;

          @include s-1440 {
            padding-top: 40px;
          }

          @include s-1024 {
            padding-top: 36px;
          }
        }

        &-box {
          &-el {
            padding-top: 16px;
            &-symbol {
              font-family: var(--font-med);
            }
          }
        }
      }
    }

    & .code {
      &-row {
        display: flex;
        gap: 16px;
        justify-content: center;
      }
      &-item {
        width: 65px;
        // height: 86px;
        & .MuiTextField-root {
        }
        & .MuiOutlinedInput-root {
          border-radius: 16px;
          width: 65px;
          height: 86px;
        }
        & .MuiOutlinedInput-input {
          padding: 24px;
          text-align: center;
          font-size: 24px;
          font-family: var(--font-med);
          line-height: 120%;
          color: var(--color-black);
        }
      }
      &-resend {
        padding-top: 16px;
        display: flex;
        justify-content: center;
        &-block {
          display: inline;
          color: var(--color-gray-6);
          .el-button-link {
            display: inline;
            margin-left: 8px;
            text-decoration: underline;
            color: var(--color-gray-6);
            font-family: var(--font-med);
          }
        }
      }
    }

    & .location {
      &-select {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        // 16 12

        @include s-1440 {
          gap: 16px;
        }

        @include s-1024 {
          gap: 12px;
        }

        @include s-768 {
          gap: 16px;
        }

        & .el-input-select {
          margin-bottom: 0;
        }
      }
    }

    .tx {
      &-detail {
        max-width: 640px;
        padding-left: 20px;
        padding-right: 20px;
        margin: 0 auto;
        padding-bottom: 250px;

        @include s-768 {
          max-width: 540px;
        }

        @include s-375 {
          max-width: calc(100% + 40px);
          padding-left: 0;
          padding-right: 0;
          margin: 0 -20px;
        }

        &-agent {
          max-width: 680px;
        }

        &.pool {
          .txagent {
            &-item {
              &-wrap {
                padding: 16px;
              }
              &-quantity {
                display: flex;
                justify-content: flex-end;
              }
              &-info {
                &-bottom {
                  & .name {
                    font-family: var(--font-med);
                  }
                }
              }
            }
          }
          .tx {
            &-desc {
              padding-top: 40px;
            }

            &-btn {
              & .el-button {
                margin-bottom: 16px;
                &:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        &-top {
          display: flex;
          justify-content: space-between;
          margin-bottom: 48px;
          align-items: center;

          & h6 {
            padding-right: 14px;
            flex: 1;
          }

          & .el-button {
            width: 145px;
            min-width: 145px;
            height: 32px;
            border-radius: 16px;
            padding: 4px 16px;
            background-color: var(--color-gray-1);
            border-color: var(--color-gray-1);
            font-size: 14px;

            &-icon {
              position: relative;
              width: 24px;
              height: 24px;

              path {
                fill: var(--color-orange-main);
              }
            }
          }
        }
      }

      &-info {
        .item {
          padding-top: 20px;
          &:first-of-type {
            padding-top: 0;
          }
          &-wrap {
            border-radius: 8px;
            border: 1px solid var(--color-gray-2);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 18px;

            @include s-375 {
              flex-wrap: wrap;
            }
          }
          &-info {
            display: flex;

            @include s-375 {
              width: 100%;
            }

            &-avatar {
              width: 48px;
              height: 48px;

              @include s-1440 {
                width: 40px;
                height: 40px;
              }
            }
            &-name {
              display: flex;
              align-items: center;
              &-title {
                color: var(--color-black);
                font-size: 16px;
                line-height: 150%;
                padding-right: 8px;
              }
            }
            &-agent {
              color: var(--color-orange-main);
              font-family: var(--font-semi);
              font-size: 14px;
              line-height: 1;
              text-transform: uppercase;
              display: block;
              margin-right: 12px;
              margin-top: 2px;
              @include s-1440 {
                font-size: 13px;
                margin-right: 8px;
              }
              @include s-1024 {
                font-size: 11px;
              }
            }
            &-star {
              width: 20px;
              height: 20px;
              @include s-1440 {
                width: 16px;
                height: 16px;
              }
            }
            &-you {
              height: 20px;
              display: block;
              @include s-1440 {
                height: 16px;
              }
            }
            &-bottom {
              display: flex;
              align-items: center;

              & .c-avatar {
                width: 20px;
                height: 20px;

                &-init-text {
                  font-size: 8px;
                  font-style: normal;
                  line-height: 150%;
                  color: var(--color-white);
                  text-transform: uppercase;
                  font-family: var(--font-reg);
                }
              }
            }
            &-ag {
              display: flex;
              align-items: center;
              padding-right: 12px;

              & .name {
                text-transform: capitalize;
                padding-left: 8px;
                font-size: 14px;
                font-family: var(--font-med);
              }

              & .text {
                padding-left: 8px;
                font-size: 14px;
                font-family: var(--font-med);
              }

              & .avatar {
                display: flex;
              }
            }
            &-date {
              font-size: 13px;
              line-height: 150%;
            }
            &-desc {
              padding-left: 12px;
            }
          }
          &-phone {
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
              margin-left: 8px;
              margin-right: 8px;
              @include s-1440 {
                width: 16px;
                height: 16px;
              }
            }
            &-num {
              font-family: var(--font-semi);
            }
          }
          &-name {
            font-family: var(--font-semi);
          }
          &-rew {
            font-family: var(--font-semi);
            &::first-letter {
              text-transform: uppercase;
            }
          }
          &-right {
            width: 100%;
            max-width: 262px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include s-375 {
              margin: 10px 0 0 auto;
              justify-content: flex-end;
            }
          }

          &-quantity {
            // max-width: 200px;
            // width: 100%;
            &-wrap {
              display: flex;
              align-items: center;
            }
            .icon {
              width: 32px;
              height: 32px;
              img {
                width: 32px;
                height: 32px;
              }
            }
            .text {
              font-size: 16px;
              font-family: var(--font-med);
              line-height: 150%;
              padding: 4px 12px;

              border-radius: 100px;
              overflow: hidden;
            }
            & .sent-quan {
              & .text {
                color: var(--color-send);
                background-color: var(--color-send-bg);
              }
            }

            & .wallet-quan {
              & .text {
                color: var(--color-deposit);
                background-color: var(--color-deposit-bg);
              }
            }
            & .buy-quan {
              & .text {
                color: var(--color-buy);
                background-color: var(--color-buy-bg);
              }
            }
            & .withdrawal-quan {
              & .text {
                color: var(--color-withdraw);
                background-color: var(--color-withdraw-bg);
              }
            }
            & .received-quan {
              & .text {
                color: var(--color-receive);
                background-color: var(--color-receive-bg);
              }
            }
          }
          &-status {
            padding-left: 24px;
            &-wrap {
              display: flex;
              align-items: center;
            }
            .icon {
              width: 16px;
              height: 16px;
              img {
                width: 16px;
                height: 16px;
                display: block;
              }
            }
            .text {
              padding-left: 4px;
              font-size: 16px;
              font-family: var(--font-med);
              line-height: 150%;
            }
            & .initiated,
            .assigned,
            .pending {
              .text {
                color: var(--color-gray-4);
              }
            }
            & .success {
              .text {
                color: var(--color-green);
              }
            }
            & .failed {
              .text {
                color: var(--color-red);
              }
            }
          }
        }
      }
      &-top {
        position: relative;
        padding: 32px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .MuiTypography-root {
          padding: 0;
        }
      }
      &-close {
        min-width: 40px;
        & svg {
          & path {
            fill: var(--color-gray-5);
          }
        }
        &:hover {
          & svg {
            & path {
              fill: var(--color-orange-main);
            }
          }
        }
      }
      &-desc {
        padding-top: 32px;
        @include s-1440 {
          padding-top: 24px;
        }
        @include s-1024 {
          padding-top: 20px;
        }
        &-bottom {
          padding-top: 32px;
          @include s-1440 {
            padding-top: 24px;
          }
          @include s-1024 {
            padding-top: 20px;
          }
        }
        &-list {
        }
        &-wrap {
          padding-top: 32px;
          @include s-1440 {
            padding-top: 24px;
          }
          @include s-1024 {
            padding-top: 20px;
          }
          &:first-of-type {
            padding-top: 0;
          }
        }
        &-item {
          padding: 8px 0;
          font-weight: 400;
          line-height: 150%;
          display: flex;
          justify-content: space-between;
        }

        &-label {
          color: var(--color-gray-6);
        }

        & .verification-icon {
          width: 16px;
          height: 16px;
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-left: 8px;
          img {
            object-fit: contain;
            display: block;
          }
        }

        &-text {
          text-align: right;
          padding-left: 8px;
          color: var(--color-black);

          &.verification {
            display: flex;
          }
          &.name {
            text-transform: capitalize;
          }
          & .address {
            display: flex;
            align-items: center;

            &-copy {
              margin-left: 8px;
              width: 20px;
              height: 20px;
              cursor: copy;

              @include s-1440 {
                width: 16px;
                height: 16px;
              }
            }
          }
          &-reward {
            display: flex;
            align-items: center;
          }
          &-icon {
            margin-left: 8px;
            display: block;
            cursor: pointer;
          }
        }
      }

      &-reward {
        padding-top: 24px;
        &-wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: var(--color-gray-1);
          border-radius: 8px;
          overflow: hidden;
          padding: 8px 16px;
          height: 56px;
        }
        &-left {
          display: flex;
          align-items: center;
          &-img {
            max-width: 55px;
            display: flex;
          }
        }
        &-bg {
          img {
          }
        }
        &-label {
          font-size: 14px;
          font-family: var(--font-bold);
          line-height: 150%;
          padding: 0 16px;
        }
        &-text {
          font-size: 14px;
          line-height: 150%;
          display: flex;
          align-items: center;
          &-icon {
            display: flex;
            align-items: center;
          }

          & svg {
            cursor: pointer;
            margin-left: 8px;
          }
        }
      }
      &-btn {
        padding-top: 40px;
        .el-button-bottom {
          margin-top: 16px;
        }
      }
    }
  }

  & .acc {
    padding-top: 50px;

    @include s-1440 {
      padding-top: 26px;
    }

    @include s-1024 {
      padding-top: 12px;
    }

    &-avatar {
      &-wrap {
        display: flex;
        border-radius: 24px;
        border: 1px solid var(--color-gray-1);
        background-color: var(--color-gray-1);
        padding: 40px;

        @include s-1440 {
          padding: 32px;
        }

        @include s-1024 {
          padding: 28px;
        }

        @include s-768 {
          padding: 20px;
        }

        @include s-375 {
          padding: 16px;
          flex-wrap: wrap;
          justify-content: center;
        }
      }

      &-icon {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        overflow: hidden;
        background-color: transparent;

        @include s-1440 {
          width: 80px;
          height: 80px;
        }

        @include s-1024 {
          width: 72px;
          height: 72px;
        }

        @include s-768 {
          width: 62px;
          height: 62px;
        }

        @include s-375 {
          width: 52px;
          height: 52px;
        }

        &.empty {
          background: linear-gradient(99.55deg, #8ecae6 1.83%, #2487a7 98.07%);
        }

        & img {
          object-fit: cover;
          height: 100%;
        }
      }
      &-item {
        width: 160px;
      }
      &-block {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-link {
        display: inline-flex;
      }
      &-copy {
        padding-left: 32px;
        @include s-1440 {
          padding-left: 24px;
        }
        @include s-1024 {
          padding-left: 20px;
        }

        @include s-375 {
          padding: 0;
        }

        &--title {
          padding-top: 10px;
          padding-bottom: 12px;

          @include s-1440 {
            padding-bottom: 8px;
          }

          @include s-375 {
            text-align: center;
          }
        }
        &-block {
          display: flex;
          border-radius: 12px;
          border: 1px solid var(--color-gray-3);
          background-color: var(--color-white);
          align-items: center;
          justify-content: space-between;
          padding: 12px 12px 12px 24px;

          @include s-1440 {
            padding: 8px 8px 8px 16px;
          }

          @include s-1024 {
            padding: 8px 8px 8px 12px;
          }
        }
        &-ref {
          padding-right: 24px;
          font-size: 18px;

          @include s-1440 {
            font-size: 16px;
          }

          @include s-1024 {
            font-size: 15px;
            max-width: unset;
          }

          @include s-768 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 270px;
          }

          @include s-375 {
            max-width: 190px;
          }
        }
        &-btn {
          min-width: 106px;
          width: 106px;
          height: 51px;
          margin: 0 0 0 auto;

          @include s-1440 {
            min-width: 86px;
            width: 86px;
            height: 44px;
          }
          @include s-1024 {
            min-width: 75px;
            width: 75px;
            height: 39px;
          }
        }
      }
      &-btn {
        .MuiButtonBase-root {
          &::after {
            display: none;
          }
        }

        &-link {
          margin-top: 12px;
          display: inline-flex;

          @include s-1440 {
            margin-top: 8px;
          }
        }
      }
    }
    &-block {
      padding-top: 48px;
      @include s-1440 {
        padding-top: 40px;
      }
      @include s-1024 {
        padding-top: 32px;
      }
      &-wrap {
        padding: 40px;
        border-radius: 24px;
        border: 1px solid var(--color-gray-2);

        @include s-1440 {
          padding: 32px;
        }

        @include s-1024 {
          padding: 28px;
        }

        @include s-768 {
          padding: 20px;
        }

        @include s-375 {
          padding: 16px;
        }
      }
      &-top {
        border-bottom: 1px solid var(--color-gray-2);
        padding: 8px 0 18px;
        display: flex;
        justify-content: space-between;
        @include s-1440 {
          padding: 8px 0 16px;
        }
      }
      &-title {
        color: var(--color-black);
        font-size: 24px;
        font-family: var(--font-med);
        line-height: 120%;

        @include s-1440 {
          font-size: 20px;
        }

        @include s-768 {
          font-size: 18px;
        }

        @include s-375 {
          font-size: 16px;
        }
      }
      &-edit {
        .el-button {
          min-width: 154px;
          padding: 4px 16px 4px 14px;
          min-height: 40px;
          border-radius: 20px;
          color: var(--color-black);
          background: var(--color-gray-1);
          border: 1px solid var(--color-gray-1);
          font-size: 16px;
          @include s-1440 {
            font-size: 14px;
            min-width: 130px;
            min-height: 32px;
            border-radius: 16px;
          }
          @include s-1024 {
            font-size: 13px;
            min-width: 116px;
            min-height: 28px;
          }
          &:hover {
            background: var(--color-gray-2);
            border: 1px solid var(--color-gray-2);
          }
          &:active {
            background: var(--color-gray-3);
            border: 1px solid var(--color-gray-3);
          }
          &.disabled {
            background: var(--color-gray-1);
            border: 1px solid var(--color-gray-1);
            color: var(--color-gray-5);
            .el-button-icon {
              path {
                fill: var(--color-gray-5);
              }
            }
          }

          &-icon {
            margin-right: 12px;
            width: 28px;
            height: 28px;
            @include s-1440 {
              margin-right: 8px;
              width: 24px;
              height: 24px;
            }
            @include s-1024 {
              width: 20px;
              height: 20px;
            }
            svg {
              width: 100%;
              height: 100%;
            }
            path {
              fill: var(--color-orange-dark);
            }
          }
        }
      }
      &-content {
        padding-top: 12px;

        @include s-1440 {
          padding-top: 8px;
        }
      }

      &-item {
        padding: 12px 0 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @include s-1440 {
          padding: 8px 0 11px;
        }
      }

      &-text {
        &.name {
          text-transform: capitalize;
        }
      }

      &-label {
        color: var(--color-gray-5);
        width: 164px;
        padding-right: 24px;

        @include s-1440 {
          width: 144px;
        }
      }

      & .react-tel-input input,
      &-text {
        font-size: 18px;
        line-height: 150%;
        color: var(--color-black);

        @include s-1440 {
          font-size: 16px;
        }

        @include s-1024 {
          font-size: 15px;
        }
      }
      &-change {
        display: inline-flex;
      }

      & .react-tel-input {
        all: unset;

        & .flag-dropdown {
          display: none;
        }

        & input {
          all: unset;
        }
      }
    }
    &-delete {
      padding-top: 48px;
      display: inline-flex;
      @include s-1440 {
        padding-top: 40px;
      }
      @include s-1024 {
        padding-top: 36px;
      }
    }
  }
}

// select currency
.MuiPopover-paper {
  .select {
    &-input {
      &-item {
        display: flex;
        align-items: center;
      }
      &-icon {
        display: block;
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      &-currency {
        display: block;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
}

// elements
.flow-message {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  overflow: hidden;
  background: #def1ff;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 130%;

  @include s-1440 {
    font-size: 14px;
  }
  @include s-1024 {
    font-size: 13px;
  }

  &-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 4px 8px 0 0;

    @include s-1440 {
      width: 16px;
      height: 16px;
      margin: 2px 6px 0 0;
    }
  }

  & svg {
    fill: transparent;
  }

  & .link {
    cursor: pointer;
    font-family: var(--font-semi);
    margin-left: 8px;
    position: relative;
    color: var(--color-gray-6);
    font-size: 16px;
    line-height: 130%;

    @include s-1440 {
      font-size: 14px;
    }

    @include s-1024 {
      font-size: 13px;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      box-sizing: border-box;
      border-bottom: 2px solid var(--color-gray-6);
    }

    &:hover {
      color: var(--color-gray-5);

      &::after {
        border-color: var(--color-gray-5);
      }
    }
  }

  &.info {
    & svg {
      & path:not(.letter) {
        fill: #0094ff;
      }

      & .letter {
        stroke: #0094ff;
      }
    }
  }

  &.error {
    background: #ffe2e2;

    & svg {
      & path:not(.letter) {
        fill: var(--color-red-1);
      }

      & .letter {
        stroke: var(--color-red-1);
      }
    }
  }

  &.text {
    background: #ecedef;
    color: var(--color-black);

    & svg {
      & path:not(.letter) {
        fill: #6d757d;
      }

      & .letter {
        stroke: #6d757d;
      }
    }
  }
}

.flow-not-found {
  color: var(--color-gray-5);
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 40px;
  color: var(--color-gray-5);
  font-size: 18px;

  @include s-1440 {
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: 16px;
  }

  &-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 12px;

    @include s-1440 {
      margin-right: 8px;
    }
  }
}

.flow-rewards {
  position: relative;
  padding: 16px;
  border-radius: 16px;
  background: #def1ff;

  &-img {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
    max-width: 100px;

    @include s-768 {
      max-width: 70px;
    }
  }

  &-text {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;

    @include s-768 {
      flex-wrap: wrap;
    }

    & svg {
      cursor: pointer;
    }

    & b {
      margin: 0 4px;
    }
  }
}

.flow-estimate {
  border-radius: 12px;
  background: #ecedef;
  display: flex;
  width: 100%;
  padding: 12px 24px;
  align-items: center;
  @include s-1440 {
    padding: 8px 16px;
  }

  &.small {
    max-width: 360px;
    align-items: flex-start;
    padding: 12px;

    @include s-1440 {
      padding: 8px;
      max-width: 310px;
    }
    .flow-estimate-cont {
      display: flex;
      align-items: center;
    }

    & .flow-estimate-img {
      margin-right: 12px;

      @include s-1440 {
        margin-right: 5px;
      }
      img {
        display: block;
      }
    }
  }

  &-text {
    & .subtitle {
      font-family: var(--font-reg);
      font-weight: normal;
      display: block;
    }
  }

  &-img {
    max-width: 60px;
    margin-right: 24px;

    @include s-1440 {
      max-width: 54px;
      margin-right: 16px;
    }
  }

  &-icon {
    margin-left: auto;
    display: flex;
    align-items: center;

    & svg {
      cursor: pointer;
      margin-left: 12px;

      @include s-1440 {
        margin-left: 8px;
      }
    }
  }
}
