.c-accordion {
  & .MuiPaper-root,
  & .MuiPaper-root:first-of-type,
  & .MuiPaper-root:last-of-type {
    padding: 0;
    box-shadow: none;
  }

  & .MuiAccordion-region {
    background: #f0f1f2;
    border-radius: 20px;
  }

  & .MuiAccordionDetails-root {
    padding: 20px 20px 80px;

    @include s-1440 {
      padding: 16px 16px 60px;
    }

    @include s-1024 {
      padding: 12px 12px 40px;
    }

    @include s-375 {
      padding: 12px 12px 30px;
    }

    & h6 {
      padding-top: 40px;
      padding-bottom: 20px;

      @include s-1440 {
        padding-top: 32px;
        padding-bottom: 16px;
      }

      @include s-1024 {
        padding-top: 24px;
        padding-bottom: 12px;
      }

      &:first-of-type {
        padding-top: 0;
      }
    }

    & ul {
      list-style-type: disc;
      padding-left: 20px;

      & li {
        padding-bottom: 8px;
      }
    }

    & button {
      width: 100%;
      margin-top: 40px;

      @include s-1440 {
        margin-top: 24px;
        padding: 14px;
      }

      @include s-1024 {
        padding: 12px;
      }
    }
  }

  & .MuiButtonBase-root {
    min-height: unset;
    padding: 19px 16px;
    background: #ffffff;
    border: 1px solid #c5c8cb;
    border-radius: 20px;
    margin-bottom: 20px;
    transition: all ease-out 0.3s;

    @include s-1440 {
      padding: 15px;
      margin-bottom: 16px;
    }

    @include s-1024 {
      padding: 12px;
      margin-bottom: 11px;
    }

    &:before {
      content: none;
    }

    &.Mui-expanded {
      background: #2c2f32;
      border: 1px solid #2c2f32;

      & .position,
      & .location,
      & .work {
        color: var(--color-white);
      }

      & svg {
        & rect#bg {
          fill: var(--color-white);
        }

        & rect#plus {
          transform: rotate(45deg);
          fill: var(--color-black);
        }
      }
    }
  }

  & .MuiPaper-root.MuiPaper-elevation,
  & .MuiAccordionSummary-content.Mui-expanded,
  & .MuiAccordionSummary-content {
    margin: 0;
    align-items: center;
    border: none;

    @include s-375 {
      flex-wrap: wrap;
    }
  }

  & .MuiPaper-root:before {
    content: none;
  }

  & .MuiAccordionDetails-root {
    margin-bottom: 20px;
  }

  & .position {
    line-height: 120%;
    max-width: 430px;
    width: 100%;
    padding-right: 20px;

    @include s-1440 {
      max-width: 310px;
    }

    @include s-1024 {
      padding-right: 5px;
      max-width: 186px;
    }

    @include s-768 {
      max-width: 376px;
    }

    @include s-375 {
      max-width: 240px;
    }
  }

  & .location {
    max-width: 168px;
    width: 100%;

    @include s-1440 {
      max-width: 130px;
    }

    @include s-1024 {
      max-width: 112px;
      padding-right: 5px;
    }

    @include s-768 {
      max-width: 144px;
    }

    @include s-375 {
      max-width: unset;
      width: unset;
      padding-right: 16px;
    }
  }

  & .work,
  & .location {
    font-size: 18px;
    line-height: 150%;
    color: #2c2f32;
    padding-right: 20px;

    @include s-1440 {
      font-size: 16px;
    }

    @include s-1024 {
      font-size: 15px;
    }

    @include s-375 {
      display: flex;
      order: 3;
    }
  }

  & .icon {
    margin: 0 0 0 auto;
    width: 42px;
    height: 42px;

    @include s-1440 {
      width: 36px;
      height: 36px;
    }

    @include s-375 {
      margin: 0 0 0 auto;
    }

    & rect {
      transition: all ease-out 0.3s;
      transform-origin: center;
    }
  }
}
