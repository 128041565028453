.p-tfa {
  background-color: var(--color-white);
}

.c-tfa-box {
  padding-top: 40px;
  width: 100%;
  display: block;
  text-align: center;
  max-width: 960px;
  margin: 0 auto;

  @include s-1440 {
    padding-top: 32px;
    max-width: 800px;
  }

  @include s-1024 {
    padding-top: 28px;
  }

  @include s-768 {
    max-width: 600px;
    padding-top: 20px;
  }

  &-text {
    color: var(--color-gray-7);
  }

  &-code {
    padding-top: 48px;
    display: flex;
    justify-content: center;
    gap: 16px;

    @include s-1440 {
      padding-top: 40px;
    }

    @include s-1024 {
      padding-top: 28px;
    }

    @include s-375 {
      padding-top: 80px;
    }

    & .input-box {
      gap: 24px;

      @include s-1440 {
        gap: 16px;
      }

      @include s-768 {
        gap: 10px;
      }

      @include s-375 {
        gap: 8px;
      }

      &--code {
        width: 86px;
        height: 112px;
        border-radius: 20px;
        overflow: hidden;
        font-size: 40px;
        padding: 32px;
        line-height: 100%;
        text-align: center;
        border: 1px solid var(--color-gray-2);
        padding-block: 0;
        padding-inline: 0;
        outline: none;
        transition: all 0.3s ease-in-out;
        color: var(--color-black);
        font-family: var(--font-med);

        @include s-1440 {
          width: 66px;
          height: 86px;
          font-size: 32px;
        }

        @include s-1024 {
          width: 46px;
          height: 66px;
          font-size: 28px;
          border-radius: 12px;
        }

        @include s-375 {
          width: 50px;
        }

        &:not([value='']) {
          border: 1px solid var(--color-gray-6);
        }
        &:focus {
          border: 1px solid var(--color-orange-main);
        }
      }
    }

    &.error {
      & .input-box--code {
        border: 1px solid var(--color-red-2);
        background-color: var(--color-red-error);
      }
    }
  }

  &-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin: 40px auto 0;
    max-width: 400px;

    @include s-1440 {
      margin: 32px auto 0;
    }

    @include s-1024 {
      margin: 28px auto 0;
    }

    &-link {
      margin: 0 auto;
    }
  }

  &-img {
    max-width: 200px;
    margin: 0 auto;
    line-height: 0;
    margin-bottom: 80px;

    @include s-1440 {
      max-width: 160px;
      margin-bottom: 64px;
    }

    @include s-1024 {
      max-width: 140px;
      margin-bottom: 56px;
    }
  }

  &-notes {
    margin-top: 16px;
    display: block;
    text-align: center;
    color: var(--color-gray-6);

    @include s-1440 {
      margin-top: 12px;
    }

    @include s-1024 {
      margin-top: 10px;
    }

    &[disabled] {
      pointer-events: none;
    }

    & .el-link-s {
      color: var(--color-gray-6);

      &.disabled {
        text-decoration: none;
        font-family: var(--font-reg);
        &::after {
          display: none;
        }
      }
    }
  }

  & .el-text-m {
    padding-top: 16px;
    display: block;

    @include s-1440 {
      padding-top: 12px;
    }

    @include s-1024 {
      padding-top: 10px;
    }
  }
}
