.MuiFormControl-root.el-input-select {
  border-radius: 12px;
  line-height: 150%;
  font-size: 18px;
  letter-spacing: 0;
  color: inherit;
  font-family: var(--font-reg);

  @include s-1440 {
    font-size: 16px;
    border-radius: 8px;
  }

  @include s-768 {
    font-size: 14px;
  }

  &:hover {
    color: #0b0c0d;
  }

  & .MuiSelect-select {
    text-transform: capitalize;
    height: unset;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 16px;
    min-height: 60px;
    box-sizing: border-box;
    transition: all ease-in-out 0.3s;
    width: 100%;
    background: transparent;

    @include s-1440 {
      font-size: 16px;
      padding: 16px;
      min-height: 56px;
    }

    @include s-1024 {
      font-size: 15px;
      min-height: 54px;
      padding: 12px;
    }

    @include s-768 {
      font-size: 16px;
      padding: 16px;
      min-height: 50px;
    }
  }

  &.Mui-focused {
    & .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-orange-main);
      border-width: 1px;
    }
  }

  &.Mui-error {
    border-color: var(--color-red);

    & label {
      color: var(--color-red);
    }

    & .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-red);
    }

    & .el-input-phone-fieldset {
      border-color: $errorBorderColor;
    }

    & .el-input-phone-label {
      color: $errorBorderColor;
    }
  }

  &.Mui-disabled {
    background-color: #fff;
    border-color: #a7acb1;
    color: #a7acb1;
  }

  & .MuiSelect-img {
    width: 28px;
    height: 28px;
    margin-right: 12px;

    @include s-1440 {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  // & .MuiSelect-icon {
  //   position: absolute;
  //   width: 20px;
  //   height: 20px;
  //   top: calc(50% - 8px);

  //   @include s-1440 {
  //     width: 16px;
  //     height: 16px;
  //     margin-left: 8px;
  //   }
  // }

  & .MuiInputLabel-root {
    color: #6d757d;
    &.Mui-focused {
      color: #6d757d;
    }
    &.Mui-error {
      color: var(--color-red);
    }
  }

  & .form-control {
    padding-left: 48px;
    border: none;
    width: 100%;
    height: 100%;
    line-height: 120%;
    min-height: 56px;
  }

  & .flag-dropdown {
    border: none;
    background-color: transparent;
  }

  & .selected-flag {
    padding: 0 0 0 16px;
    pointer-events: none;
  }

  & .arrow {
    display: none;
  }
}

.MuiPaper-root.MuiPaper-elevation {
  box-shadow: 0 8px 12px 0 rgba(44, 47, 50, 0.1);
  border-radius: 8px;
  border: 1px solid var(--colors-gray-2);
  margin-top: 4px;
  border: 1px solid var(--colors-gray-2, #d3d6d8);

  & .MuiMenuItem-root {
    border-radius: 0;
    font-family: var(--font-reg);

    @include s-768 {
      font-size: 16px;
    }

    @include s-375 {
      font-size: 13px;
    }

    &.Mui-selected {
      background-color: var(--color-gray-1);
    }

    &.capitalize {
      text-transform: capitalize;
    }
  }

  & .MuiSelect-img {
    width: 28px;
    height: 28px;
    margin-right: 12px;

    @include s-1440 {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
  &.select-currency {
    border-radius: 20px;
    @include s-1440 {
      border-radius: 16px;
    }
    .MuiMenu-list {
    }
    .MuiMenuItem-root {
      border-radius: 0;
      height: 44px;
      padding: 12px;
      min-height: 44px;
      font-size: 16px;
      font-family: var(--font-reg);
      @include s-1440 {
        padding: 6px 8px;
        height: 36px;
        min-height: 36px;
      }
      @include s-1024 {
        height: 35px;
        min-height: 35px;
      }
    }
    .MuiSelect-icon {
      width: 28px;
      height: 28px;
      margin-right: 12px;
      @include s-1440 {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      @include s-1024 {
        width: 20px;
        height: 20px;
      }
    }
    .MuiTouchRipple-root {
    }
  }
  &.select-period {
    border-radius: 20px;
    @include s-1440 {
      border-radius: 16px;
    }

    .MuiMenu-list {
    }
    .MuiMenuItem-root {
      border-radius: 0;
      height: 48px;
      padding: 12px 12px 12px 16px;
      min-height: 48px;
      font-size: 16px;
      @include s-1440 {
        padding: 6px 8px 6px 12px;
        height: 36px;
        min-height: 36px;
        font-size: 14px;
      }
      @include s-1024 {
        height: 35px;
        min-height: 35px;
        font-size: 13px;
      }
    }
    .MuiSelect-icon {
      width: 28px;
      height: 28px;
      margin-right: 12px;

      @include s-1440 {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      @include s-1024 {
        width: 20px;
        height: 20px;
      }
    }
  }
}
