.MuiContainer-root {
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 100px;
  box-sizing: content-box;

  @include s-1440 {
    max-width: 1440px;
    padding: 0 70px;
  }

  @include s-1024 {
    max-width: 1024px;
    padding: 0 42px;
  }

  @include s-768 {
    max-width: 768px;
    padding: 0 20px;
  }

  @include s-375 {
    padding: 0 20px;
  }

  &.c-760 {
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
    max-width: 792px;

    @include s-1440 {
      max-width: 632px;
    }

    @include s-768 {
      max-width: 476px;
    }

    @include s-375 {
      max-width: 375px;
      padding: 0;
    }
  }
}
