.p-about {
  & .s {
    &-hero {
      position: sticky;
      top: 0;
      min-height: 1044px;
      padding: 222px 0;
      color: var(--color-white);
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      margin-bottom: -50px;
      overflow: hidden;

      @include s-1440 {
        min-height: 870px;
        padding: 192px 0;
      }

      @include s-1024 {
        min-height: 816px;
        padding: 150px 0;
      }

      @include s-768 {
        min-height: 1072px;
        padding: 156px 0;
      }

      @include s-375 {
        min-height: 90vh;
        padding: 90px 0;
        margin-top: -70px;
        z-index: -1;
      }

      &-bg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 120%;
        height: 120%;
        z-index: -1;

        @include s-375 {
          width: 150%;
          height: 150%;
        }

        & img {
          object-fit: cover;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 140%;
          height: 140%;
          transform: translate(-50%, -50%);
          transform-origin: center;
        }

        & iframe {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 120%;
          height: 120%;

          @include s-375 {
            display: none;
          }
        }
      }

      & h1 {
        color: var(--color-white);
        max-width: 1720px;
        text-transform: uppercase;
        font-size: 96px;
        line-height: 120%;

        @include s-1440 {
          max-width: 1060px;
          font-size: 68px;
        }

        @include s-1024 {
          max-width: 100%;
          font-size: 58px;
        }

        @include s-375 {
          font-size: 56px;
        }

        & i {
          color: var(--color-orange-main);

          @include s-375 {
            display: block;
            min-height: 68px;
          }
        }
      }
    }

    &-story {
      position: relative;
      z-index: 1;
      background-color: var(--color-white);
      border-radius: 40px 40px 0 0;
      overflow: hidden;
      @include pad120;
      padding-bottom: 260px;

      @include s-1440 {
        padding-bottom: 170px;
      }

      @include s-1024 {
        padding-bottom: 140px;
      }

      @include s-768 {
        padding-bottom: 140px;
      }

      &-img {
        margin-top: 50px;
        aspect-ratio: 1720/1110;

        @include s-1440 {
          margin-top: 40px;
          aspect-ratio: 1300/840;
        }

        @include s-1024 {
          margin-top: 30px;
          aspect-ratio: 940/608;
        }

        @include s-768 {
          aspect-ratio: 728/470;
        }

        @include s-375 {
          margin-top: 20px;
          aspect-ratio: 335/218;
        }

        & img {
          height: 100%;
          object-fit: cover;
        }
      }

      &-desc {
        padding-top: 56px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        @include s-1440 {
          padding-top: 36px;
        }

        @include s-1024 {
          padding-top: 32px;
        }

        @include s-375 {
          flex-wrap: wrap;
          padding-top: 20px;
        }

        &-text {
          display: flex;
          font-size: 26px;
          line-height: 120%;

          @include s-1440 {
            font-size: 22px;
          }

          @include s-1024 {
            font-size: 20px;
          }

          @include s-375 {
            width: 100%;
            flex-wrap: wrap;
          }

          &--left,
          &--right {
            max-width: 590px;
            padding-right: 32px;

            @include s-1440 {
              padding-right: 20px;
              max-width: 440px;
            }

            @include s-1024 {
              max-width: 320px;
            }

            @include s-768 {
              max-width: 250px;
            }

            @include s-375 {
              padding-right: 0;
              max-width: 375px;
            }
          }
        }

        &--author {
          text-align: right;

          @include s-375 {
            margin: 6px 0 0 auto;
          }

          & h6 {
            color: #6d757d;
            display: block;
            padding-top: 10px;
          }
        }
      }
    }

    &-mission {
      padding: 240px 0;
      background-color: #f0f1f2;
      position: relative;

      @include s-1440 {
        padding: 200px 0;
      }

      @include s-1024 {
        padding: 160px 0;
      }

      @include s-375 {
        padding: 140px 0;
      }

      & h3 {
        color: #2c2f32;
        max-width: 1480px;

        @include s-1440 {
          max-width: 1280px;
        }
      }
    }

    &-minds {
      position: relative;
      background-color: var(--color-white);
      @include padBot240;

      &-inner {
        @include pad240;
      }

      &-list {
        padding-top: 30px;
        margin-bottom: -40px;

        @include s-1440 {
          padding-top: 20px;
        }

        @include s-1024 {
          padding-top: 30px;
        }

        @include s-768 {
          padding-top: 20px;
        }

        & .item {
          &-inner {
            position: relative;
          }

          &-img {
            background: #f0f1f2;
            border: 1px solid var(--color-orange-main);
            border-radius: 20px;
            overflow: hidden;
            aspect-ratio: 420/440;
          }

          &-desc {
            padding: 32px;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            flex-direction: column;

            @include s-1440 {
              padding: 20px;
            }

            @include s-1024 {
              padding: 16px;
            }

            &-social {
              margin: 0 0 0 auto;

              &--link {
                display: block;
                margin-bottom: 24px;
                transition: transform ease 0.3s;

                @include s-1440 {
                  margin-bottom: 16px;
                }

                &:hover {
                  transform: scale(1.1);
                }

                & svg {
                  width: 42px;
                  height: 42px;

                  @include s-1440 {
                    width: 36px;
                    height: 36px;
                  }
                }
              }
            }

            &-author {
              padding: 12px;
              background-color: var(--color-white);
              border-radius: 10px;
              display: inline-block;

              @include s-1440 {
                padding: 8px;
              }

              &--pos {
                display: block;
                font-size: 16px;
                line-height: 150%;
                color: #2c2f32;
                padding-top: 6px;

                @include s-1440 {
                  font-size: 14px;
                }

                @include s-1024 {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }

    &-team {
      position: relative;
      background-color: var(--color-white);

      &-inner {
        position: relative;
        min-height: 1280px;
        display: flex;
        align-items: flex-end;
        padding: 120px 100px;

        @include s-1440 {
          padding: 100px 70px;
          min-height: 960px;
        }

        @include s-1024 {
          min-height: 680px;
          padding: 70px 42px;
        }

        @include s-768 {
          min-height: 512px;
          padding: 70px 20px;
        }

        @include s-375 {
          display: flex;
          flex-wrap: wrap;
          min-height: unset;
          padding: 0;
          background: linear-gradient(100deg, #E98000 1.83%, #FF9C25 98.07%);
        }

        &--bg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;

          @include s-375 {
            all: unset;
          }

          & img {
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &--bg {
        position: relative;
        aspect-ratio: 375/250;
        display: none;

        @include s-375 {
          display: block;
        }
      }

      &-desc {
        padding: 120px;
        text-align: center;
        border-radius: 20px;
        position: relative;
        z-index: 1;
        opacity: 0;
        visibility: hidden;

        @include s-1440 {
          padding: 100px;
        }

        @include s-1024 {
          padding: 70px;
        }

        @include s-375 {
          opacity: 1;
          visibility: visible;
          padding: 40px 20px;
        }

        &--bg {
          background: linear-gradient(100deg, #E98000 1.83%, #FF9C25 98.07%);
          opacity: 0.95;
          position: absolute;
          z-index: -1;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;

          @include s-375 {
            display: none;
          }
        }

        & h3 {
          color: var(--color-white);
        }
      }
    }
  }

  & .c-footer {
    margin-top: 0;
  }
}
