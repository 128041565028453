.p-account {
  & .send {
    &-step {
      &-content {
        padding-top: 64px;

        @include s-1440 {
          padding-top: 40px;
        }

        & .el-input-date {
          & .MuiButtonBase-root {
            height: 28px;
            position: absolute;
            right: 34px;
            top: calc(50% - 14px);
            width: 28px;
            @include s-1440 {
              width: 24px;
              height: 24px;
              top: calc(50% - 12px);
              right: 26px;
            }
          }
          .MuiSvgIcon-root {
            height: 100%;
            width: 100%;
          }
        }

        &-rewards {
          margin-top: 48px;
          padding-bottom: 0;

          @include s-1440 {
            margin-top: 40px;
          }

          @include s-1024 {
            margin-top: 32px;
          }
        }

        &-estimate {
          margin-top: 24px;

          @include s-1440 {
            margin-top: 16px;
          }

          @include s-1024 {
            margin-top: 12px;
          }
        }
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      margin-top: 48px;

      @include s-1440 {
        gap: 16px;
        margin-top: 40px;
      }

      @include s-1024 {
        gap: 16px;
        margin-top: 36px;
      }
    }
  }
}
