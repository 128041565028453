.c-header {
  // padding: 16px 26px;
  padding: 26px 26px;
  position: fixed;
  top: 0;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include s-1440 {
    padding: 16px;
  }

  @include s-1024 {
    padding: 14px 20px;
  }

  @include s-768 {
    padding: 14px 16px;
  }

  @include s-375 {
    padding: 8px 20px;
    position: relative;
  }

  &-logo {
    cursor: pointer;
    display: inline-flex;
    max-width: 200px;
    width: 100%;
    height: 50px;

    @include s-1440 {
      max-width: 160px;
      height: 40px;
    }

    @include s-1024 {
      max-width: 128px;
      height: 36px;
    }

    @include s-768 {
      max-width: 138px;
    }

    &.opened-menu {
      @include s-375 {
        opacity: 0;
      }
    }
  }

  &-menu {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    @include s-375 {
      padding: 8px;
      width: 100%;
      background: #2c2f32;
      bottom: 0;
      z-index: 9999;
      left: 0;
      width: 100%;
      position: fixed;
      gap: 12px;
      justify-content: space-between;
    }

    & > ul {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      @include s-375 {
        width: 50%;
        gap: 12px;
      }
    }

    & li {
      margin-right: 26px;

      @include s-1440 {
        margin-right: 12px;
      }

      @include s-1024 {
        margin-right: 12px;
      }
      @include s-375 {
        margin-right: 0;
        width: 100%;
      }
    }

    & .el-button {
      height: 100%;
      text-transform: uppercase;
      @include s-1024 {
        padding: 9px;
      }
      @include s-768 {
      }
      @include s-375 {
        min-width: 110px;
        border-radius: 5px;
      }
    }

    &-gamb {
      display: flex;
      // @include s-375 {
      //   width: 50%;
      // }
      &--title {
        display: none;

        @include s-375 {
          display: inline-block;
        }
      }

      & svg {
        width: 48px;
        min-width: 48px;
        height: 48px;

        @include s-1440 {
          width: 42px;
          min-width: 42px;
          height: 42px;
        }

        @include s-1024 {
          width: 36px;
          min-width: 36px;
          height: 36px;
        }

        @include s-375 {
          width: 32px;
          min-width: 32px;
          height: 32px;
        }
      }

      & #basic-button {
        padding: 0;
        border: none;
        border-radius: 0;
        min-width: unset;
        outline: none;
        transition: all ease 0.3s;

        &:hover {
          transform: scale(1.05);
        }

        @include s-375 {
          border: 1px solid #ffffff;
          border-radius: 5px;
          color: var(--color-white);
          padding: 2px 14px;
          // width: 111px;
          display: flex;
          justify-content: center;
          width: 100%;
        }

        & .line {
          transform-origin: center;
          transition: transform ease 0.3s;
        }

        &.active {
          & .line2 {
            transform: rotate(-45deg);
          }

          & .line1 {
            transform: translate(-6px, 4px) rotate(45deg);
          }

          & .line3 {
            transform: translate(3px, -4px) rotate(45deg);
          }
        }
      }
    }
  }

  & .close-flow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;

    @include s-1440 {
      width: 40px;
      height: 40px;
    }

    @include s-1024 {
      width: 36px;
      height: 36px;
    }

    @include s-768 {
      width: 30px;
      height: 30px;
    }

    & svg {
      & path {
        fill: var(--color-gray-5);
      }
    }

    &-chat {
      margin-left: 32px;

      @include s-1440 {
        margin-left: 24px;
      }

      @include s-1024 {
        margin-left: 20px;
      }
    }

    &:hover {
      & svg {
        & path {
          fill: var(--color-orange-main);
        }
      }
    }

    &.hide {
      display: none;
    }
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100vw;
  user-select: none;
  pointer-events: none;

  &.opened-menu {
    user-select: auto;
    pointer-events: auto;

    & .menu--bg {
      background-color: rgba($color: #000000, $alpha: 0.2);
    }
  }

  &--bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0);
    transition: background-color ease 0.3s;
  }

  &-box {
    background: #2c2f32;
    border-radius: 40px 0px 0px 40px;
    color: var(--color-white);
    overflow-y: auto;
    padding: 12px 26px 80px 80px;
    max-width: 732px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 auto;
    height: 100%;
    position: relative;
    z-index: 1;

    @include s-1440 {
      padding: 16px 40px 60px 60px;
      max-width: 550px;
    }

    @include s-1024 {
    }

    @include s-768 {
      max-width: 540px;
      padding: 14px 20px 40px 40px;
    }

    @include s-375 {
      max-width: 100vw;
      border-radius: unset;
      padding: 32px 8px 40px 20px;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include s-375 {
      align-items: center;
    }

    &-item {
      padding: 0;
      text-transform: uppercase;
      padding-top: 20px;
      line-height: 0;
      min-height: unset;

      @include s-375 {
        padding-top: 15px;
      }

      &:first-of-type {
        padding-top: 0;
      }

      & a {
        font-size: 48px;
        line-height: 120%;
        transition: color ease 0.3s;
        font-family: var(--font-reg);

        @include s-1440 {
          font-size: 40px;
        }

        @include s-375 {
          font-size: 32px;
        }

        &.active {
          font-style: italic;
          color: var(--color-orange-main);
        }
      }

      &:hover {
        a {
          color: var(--color-orange-main);
          text-decoration: none;
        }
      }
    }
  }

  & .menu-close {
    margin: 0 0 0 auto;
    position: relative;
    z-index: 2;
    cursor: pointer;
    margin-bottom: 56px;

    @include s-1440 {
      margin-bottom: 62px;
    }

    @include s-768 {
      margin-bottom: 70px;
    }

    @include s-375 {
      display: none;
    }

    & svg {
      width: 48px;
      height: 48px;

      @include s-768 {
        width: 36px;
        height: 36px;
      }

      @include s-1440 {
        width: 42px;
        height: 42px;
      }

      @include s-375 {
        width: 32px;
        height: 32px;
        display: none;
      }
    }
  }

  & .menu-store {
    padding-top: 90px;
    padding-bottom: 20px;

    @include s-768 {
      padding-top: 46px;
    }

    @include s-1440 {
      padding-top: 66px;
    }

    @include s-375 {
      padding-top: 42px;
    }

    & a {
      margin-right: 20px;
      max-width: 248px;
      transition: transform ease 0.3s;

      &:hover {
        transform: scale(1.05);
      }

      @include s-1440 {
        max-width: 144px;
      }

      @include s-1024 {
        max-width: 167px;
      }

      @include s-375 {
        max-width: 158px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  & .menu-contact {
    max-width: 220px;
    font-size: 13px;
    line-height: 150%;
    margin: auto 0 0 0;

    @include s-375 {
      margin: 46px auto;
      text-align: center;
    }

    & a {
      font-family: var(--font-bold);

      &:hover {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
}
