.c-reward {
  margin-top: 80px;
  padding: 32px;
  border-radius: 16px;
  background-color: var(--color-gray-1);
  overflow: hidden;
  position: relative;

  @include s-1440 {
    margin-top: 64px;
    padding: 24px;
  }

  @include s-1024 {
    margin-top: 56px;
    padding: 20px;
  }

  @include s-768 {
    margin-top: 46px;
    padding: 24px;
  }

  @include s-375 {
    margin-top: 36px;
  }

  &-wrap {
    display: flex;
    position: relative;
    align-items: center;

    @include s-768 {
      align-items: flex-start;
    }
  }

  &-img {
    flex: 0 0 104px;
    display: flex;
    align-items: center;

    @include s-768 {
      flex: 0 0 74px;
    }

    @include s-375 {
      max-width: 80px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  &-content {
    padding-left: 24px;
    flex: 1;

    @include s-768 {
      padding-left: 18px;
      flex: 0 0 76%;
    }

    @include s-375 {
      flex: 0 0 70%;
      padding-left: 0;
    }

    & .title {
      line-height: 150%;
      color: var(--color-black);
    }

    & .text {
      padding-top: 4px;
      color: var(--color-black);
      line-height: 150%;
    }

    & ul {
      list-style: initial;
      list-style-position: inside;
    }

    & li {
      font-size: 14px;
      color: var(--color-black);
      line-height: 150%;
    }

    & .el-link-underline {
      margin-top: 16px;
      font-size: 14px;
      font-style: normal;
      line-height: 150%;
    }
  }

  &-close {
    width: 28px;
    height: 28px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;

    @include s-1440 {
      width: 24px;
      height: 24px;
    }

    @include s-1024 {
      width: 20px;
      height: 20px;
    }
  }

  & .link {
    margin-top: 24px;
    position: relative;
    line-height: 120%;
    display: flex;
    align-items: center;

    @include s-1440 {
      margin-top: 16px;
    }

    @include s-1024 {
      margin-top: 12px;
    }

    &-icon {
      margin-right: 8px;
      cursor: pointer;
    }
  }

  & .MuiSkeleton-root {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}
