form,
.form {
  max-width: 760px;
  margin: 0 auto;

  @include s-1440 {
    max-width: 600px;
  }

  @include s-1024 {
    max-width: 544px;
  }

  @include s-768 {
    max-width: 400px;
  }

  @include s-375 {
    max-width: 100vw;
    padding: 0 16px;
  }

  &-inputs {
    padding-top: 48px;

    @include s-1440 {
      padding-top: 40px;
    }

    @include s-1024 {
      padding-top: 36px;
    }
  }

  &-btns {
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include s-1440 {
      padding-top: 40px;
    }

    @include s-1024 {
      padding-top: 36px;
    }

    @include s-768 {
      padding-top: 16px;
    }
  }

  & .send-btns {
    padding-top: 48px;

    @include s-1440 {
      padding-top: 40px;
    }

    @include s-1024 {
      padding-top: 36px;
    }
  }

  & .MuiFormControlLabel-root {
    margin-left: 0;
    display: flex;
  }

  & .el-input,
  & .el-input-select,
  & .el-input-phone,
  & .el-input-date {
    margin-bottom: 24px;

    @include s-1440 {
      margin-bottom: 16px;
    }

    @include s-1024 {
      margin-bottom: 12px;
    }

    @include s-375 {
      margin-bottom: 16px;
      font-size: 16px;
    }
  }

  & h4 {
    text-align: center;
  }

  & h6 {
    text-align: center;
    // padding-bottom: 48px;

    // @include s-1440 {
    //   padding-bottom: 40px;
    // }

    // @include s-1024 {
    //   padding-bottom: 36px;
    // }
  }

  & .cancel {
    margin-top: 24px;
    font-family: var(--font-semi);
    cursor: pointer;
    color: var(--color-gray-6);
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      box-sizing: border-box;
      border-bottom: 2px solid var(--color-gray-6);
    }
    &:hover {
      color: var(--color-gray-5);
      &::after {
        border-color: var(--color-gray-5);
      }
    }
    @include s-1440 {
      margin-top: 16px;
    }

    @include s-1024 {
      margin-top: 12px;
    }
  }

  & .country-select {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
