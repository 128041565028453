.p-account {
  // & .activity {
  & .tx {
    min-height: 100vh;
    // padding-top: 24px;

    &-search {
      overflow: hidden;
      border-radius: 24px;
      background-color: var(--color-gray-1);

      &-wrap {
        padding: 32px;
      }

      &-input {
        padding-top: 32px;

        @include s-1440 {
          padding-top: 24px;
        }

        @include s-1024 {
          padding-top: 20px;
        }
        .el-input-search .MuiInputBase-root {
          // &.Mui-focused{
          //   .MuiOutlinedInput-notchedOutline{
          //     border-color: var(--color-gray-6);
          //   }
          // }
          // .MuiOutlinedInput-notchedOutline{
          //   border-color: var(--color-gray-3);
          // }
        }
      }
    }
    &-attention {
      padding-top: 48px;
      @include s-1440 {
        padding-top: 40px;
      }
      @include s-1024 {
        padding-top: 36px;
      }
    }

    &-list {
      padding-top: 48px;

      @include s-1440 {
        padding-top: 40px;
      }

      @include s-1024 {
        padding-top: 36px;
      }

    }
    &-no-results{
      padding-top: 48px;
      @include s-1440 {
        padding-top: 40px;
      }
      @include s-1024 {
        padding-top: 36px;
      }
    }
    &-date {
      color: var(--color-gray-6);
    }
    &-title{
      color: var(--color-gray-6);
    }
    &-block {
      padding-top: 24px;
      &:first-of-type {
        padding-top: 0;
      }
    }

    &-table {
      padding-top: 16px;
      display: flex;
      flex-direction: column;
      // gap: 24px;

      // @include s-1440 {
      //   gap: 16px;
      // }

      // @include s-1024 {
      //   gap: 12px;
      // }

      .item {
        padding-top: 20px;

        &:first-of-type {
          padding-top: 0;
        }

        &-wrap {
          border-radius: 8px;
          border: 1px solid var(--color-gray-2);
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 18px;
        }

        &-info {
          display: flex;

          &-avatar {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            overflow: hidden;
          }

          &-name {
            display: flex;
            align-items: center;

            &-title {
              color: var(--color-black);
              font-family: var(--font-bold);
              font-size: 16px;
              line-height: 150%;
              padding-right: 8px;
            }

            img {
              width: 16px;
              height: 16px;
            }

            &-star {
              width: 16px;
              height: 16px;
            }
          }

          &-date {
            font-size: 13px;
            line-height: 1;
          }

          &-desc {
            padding-left: 12px;
          }
        }

        &-quantity {
          &-wrap {
            display: flex;
            align-items: center;
          }
          .icon {
            width: 32px;
            height: 32px;
            img {
              width: 32px;
              height: 32px;
            }
          }
          .text {
            font-size: 16px;
            font-family: var(--font-med);
            line-height: 150%;
            padding: 4px 12px;

            border-radius: 100px;
            overflow: hidden;
          }
          & .sent {
            .icon {
            }
            .text {
              color: var(--color-red);
              background-color: var(--color-deposit-bg-2);
            }
          }
          & .received {
            .icon {
            }
            .text {
              color: var(--color-green);
              background-color: var(--color-deposit-bg-3);
            }
          }
        }
        &-status {
          &-wrap {
            display: flex;
            align-items: center;
          }
          .icon {
            width: 16px;
            height: 16px;
            img {
              width: 16px;
              height: 16px;
              display: block;
            }
          }
          .text {
            padding-left: 4px;
            font-size: 16px;
            font-family: var(--font-med);
            line-height: 150%;
          }
          & .success {
            .icon {
            }
            .text {
              color: var(--color-green);
            }
          }
          & .failed {
            .text {
              color: var(--color-red);
            }
          }
        }
        &-details {
          &-btn {
            border: 0 none;
            background-color: transparent;
            box-shadow: none;
            outline: none;
            width: 24px;
            min-width: 24px;
            height: 24px;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
}
