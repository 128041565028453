.dashboard {
  &-availability {
    margin-top: 64px;

    @include s-768 {
      margin-top: 36px;
    }

    &-wrap {
      display: flex;
      align-items: center;
      padding: 24px;
      border-radius: 16px;
      background-color: var(--color-blue);
    }

    &-img {
      flex: 0 0 104px;
    }

    &-content {
      flex: 1;
      padding-left: 24px;

      .title {
        font-size: 18px;
        font-family: var(--font-med);
        font-weight: 500;
        line-height: 150%;
        color: var(--color-black);
      }
      .text {
        font-size: 14px;
        color: var(--color-black);
        line-height: 150%;
      }
      .el-link-underline {
        margin-top: 16px;
        font-size: 14px;
        font-style: normal;
        line-height: 150%;
      }
    }
  }

  &-wallet {
    display: flex;

    @include s-768 {
      flex-wrap: wrap;
    }

    & .wallet {
      overflow: hidden;
      border-radius: 24px;
      border: 1px solid #d3d6d8;
      padding: 40px;
      max-width: 100%;
      flex: 1;

      @include s-1440 {
        padding: 32px;
      }

      @include s-1024 {
        padding: 28px;
      }

      @include s-768 {
        width: 100%;
        flex: unset;
      }

      @include s-375 {
        padding: 20px;
      }

      &-total {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        color: var(--color-gray-6);

        &-icon {
          width: 28px;
          height: 28px;
          margin-left: 12px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          @include s-1440 {
            width: 16px;
            height: 16px;
            margin-left: 8px;
          }
        }
      }

      &-ballance {
        text-transform: uppercase;
      }

      &-info {
        display: flex;
        flex-wrap: wrap;
        padding-top: 24px;

        @include s-1440 {
          padding-top: 16px;
        }

        @include s-1024 {
          padding-top: 12px;
        }

        &-box {
          &:first-of-type {
            margin-right: 32px;

            @include s-1440 {
              margin-right: 24px;
            }

            @include s-1024 {
              margin-right: 20px;
            }
          }
        }
        &-el {
          &-text {
          }
          &-not {
            margin-left: 8px;
            width: 16px;
            height: 16px;
            display: inline-block;
            svg {
              width: 100%;
              height: 100%;
            }
          }
          &.bottom {
            padding-top: 20px;
            @include s-1440 {
              padding-top: 16px;
            }
            @include s-1024 {
              padding-top: 12px;
            }
          }
          &-title {
            color: var(--color-gray-6);
            display: inline-flex;
            align-items: center;
          }

          &-value {
            color: var(--color-gray-7);

            & .el-text-s {
              padding-left: 12px;
              color: var(--color-gray-5);

              @include s-1440 {
                padding-top: 8px;
              }
            }
          }
        }
      }

      &-links {
        display: flex;
        gap: 24px;
        padding-top: 32px;
        flex-wrap: wrap;

        @include s-1440 {
          gap: 16px;
          padding-top: 24px;
        }

        @include s-1024 {
          gap: 12px;
          padding-top: 20px;
        }

        & .MuiButtonBase-root.el-button {
          flex: 1 1 auto;
          width: unset;

          @include s-375 {
            width: 100%;
          }
        }
      }

      & .flow-message {
        margin-top: 32px;

        @include s-1440 {
          margin-top: 24px;
        }

        @include s-1024 {
          margin-top: 20px;
        }
      }
    }

    & .statistic {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 32px;
      flex: 0 0 360px;
      max-width: 360px;

      @include s-1440 {
        padding-left: 24px;
        flex: 0 0 300px;
        max-width: 300px;
      }

      @include s-1024 {
        padding-left: 20px;
        flex: 0 0 244px;
        max-width: 244px;
      }

      @include s-768 {
        padding: 36px 0 0 0;
        flex: unset;
        flex-direction: row;
        max-width: unset;
        gap: 20px;
        width: 100%;
        align-items: start;
      }

      @include s-375 {
        justify-content: center;
        flex-wrap: wrap;
      }

      &-el {
        padding: 24px 32px 32px;
        border-radius: 20px;
        background: #ecedef;
        flex: 0 0 calc(50% - 16px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include s-1440 {
          padding: 16px 24px 24px;
          flex: 0 0 calc(50% - 12px);
        }

        @include s-1024 {
          padding: 12px 20px 20px;
          margin-bottom: 20px;
          flex: 0 0 calc(50% - 10px);
        }

        @include s-768 {
          flex-basis: calc(100% - 10px);
        }

        @include s-375 {
          min-width: 210px;
        }

        &-title {
          display: flex;
          align-items: center;
          text-transform: uppercase;
          .title {
            display: flex;
            color: var(--color-gray-7);
            &-slash {
              color: var(--color-gray-5);
              margin-left: 4px;
            }
          }
        }

        &-select {
          text-transform: none;
          margin-left: 12px;
          @include s-1440 {
            margin-left: 10px;
          }

          @include s-1024 {
            margin-left: 8px;
          }
          .MuiInputBase-root.el-input-select {
            border-radius: 20px;
            font-family: inherit;
            font-size: unset;
            line-height: 100%;
            letter-spacing: unset;
            height: 36px;
            @include s-1440 {
              border-radius: 16px;
              height: 29px;
            }
            @include s-1024 {
              height: 28px;
            }
            &.Mui-focused {
              & fieldset {
                border-color: $borderColorFocused;
                border-width: 1px;
              }
            }

            &.Mui-disabled {
              & .MuiSelect-select {
                cursor: not-allowed;
                background: inherit;
              }

              & input {
                cursor: not-allowed;
              }
            }
            .MuiSelect-select {
              min-width: 93px;
              min-height: 29px;
              padding: 4px 8px 4px 16px;
              display: flex;
              align-items: center;
              font-size: 16px;
              @include s-1440 {
                font-size: 14px;
                padding: 4px 8px 4px 12px;
              }
              @include s-1024 {
                font-size: 13px;
              }
              input {
                min-height: 29px;
                padding: 4px 8px 4px 12px;
              }
              & label {
                &.MuiFormLabel-filled,
                &.Mui-focused {
                  background-color: transparent;
                }
              }
              &:hover {
                background: transparent;
              }
            }
            fieldset {
              border-color: var(--color-gray-10);
            }
            .MuiSelect-icon {
              width: 20px;
              height: 20px;
              right: 8px;
              top: calc(50% - 10px);
            }
            .MuiSelect-nativeInput {
              height: 0;
              padding: 0;
            }
          }
        }

        &-value {
          padding-top: 6px;

          @include s-1440 {
            padding-top: 4px;
          }

          @include s-1024 {
            padding-top: 2px;
          }
        }

        &-link {
          color: var(--color-gray-6);
          // font-family: var(--font-semi);
          // font-size: 14px;
          // line-height: 140%;
          margin-top: 10px;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            display: block;
            // background-color: var(--color-gray-6);
            border-bottom: 2px solid var(--color-gray-6);
          }
          &:hover {
            color: var(--color-gray-5);
            &::after {
              border-color: var(--color-gray-5);
            }
          }
          // @include s-1440 {
          //   font-size: 13px;
          // }
          // @include s-1024 {
          //   font-size: 12px;
          // }
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        &.send {
          & .statistic-el-value {
            color: var(--color-send);
          }
        }

        &.received {
          & .statistic-el-value {
            color: var(--color-receive);
          }
        }
      }
    }
  }

  &-rejected {
    background-color: var(--color-red-1);
    overflow: hidden;
    border-radius: 28px;
    padding: 32px 40px;
    display: flex;
    align-items: center;
    margin-top: 80px;

    @include s-1440 {
      padding: 24px 32px;
      border-radius: 24px;
      margin-top: 64px;
    }

    @include s-1024 {
      padding: 20px 28px;
      margin-top: 56px;
    }

    &-icon {
      width: 40px;
      height: 40px;
      margin-right: 32px;

      @include s-1440 {
        margin-right: 24px;
      }

      @include s-1024 {
        margin-right: 20px;
      }
    }

    &-text {
      margin-top: 4px;
    }
  }

  &-verify {
    padding: 40px;
    margin-top: 80px;
    border-radius: 28px;
    position: relative;
    overflow: hidden;

    @include s-1440 {
      border-radius: 24px;
      padding: 32px;
      margin-top: 64px;
    }

    @include s-1024 {
      padding: 28px;
      margin-top: 56px;
    }

    &--bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      & img {
        height: 100%;
        object-fit: cover;
      }
    }

    &-inner {
      position: relative;
    }

    &--subtitle {
      padding: 24px 0;
      display: block;

      @include s-1440 {
        padding: 16px 0;
      }

      @include s-1024 {
        padding: 12px 0;
      }
    }

    & ul {
      list-style-type: disc;
      padding-left: 24px;
    }

    &-icon {
      display: block;
      // width: 152px;
      // height: 128px;
      height: 100%;
      width: auto;
      position: absolute;
      right: 0;
      bottom: 0;
      img {
        height: 100%;
        object-fit: cover;
      }
    }

    & .el-button {
      margin-top: 32px;
      max-width: 140px;

      @include s-1440 {
        margin-top: 24px;
      }

      @include s-1024 {
        margin-top: 20px;
      }
    }
  }

  &-contacts {
    padding-top: 80px;

    @include s-1440 {
      padding-top: 64px;
    }

    @include s-1024 {
      padding-top: 56px;
    }

    &-content {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      padding-top: 24px;

      @include s-1440 {
        padding-top: 16px;
      }

      @include s-1024 {
        padding-top: 12px;
      }
    }

    & .contact {
      cursor: pointer;
      border-radius: 12px;
      border: 1px solid #d3d6d8;
      background: #fff;
      padding: 24px 12px 46px 12px;
      max-width: 160px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-right: 24px;
      width: 100%;
      min-height: 200px;
      position: relative;
      overflow: hidden;
      // box-shadow: none;
      transition: box-shadow ease 0.3s;

      @include s-1440 {
        padding: 16px 8px 36px 8px;
        max-width: 128px;
        margin-right: 16px;
        min-height: 158px;
      }

      @include s-1024 {
        min-height: 148px;
        padding: 12px 8px 30px 8px;
        margin-right: 12px;
      }

      @include s-768 {
        padding: 12px 8px 20px 8px;
        min-height: 100px;
      }

      @include s-375 {
        padding: 10px 8px 16px 8px;
        min-height: 80px;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        box-shadow: 0 8px 12px 0 rgba(44, 47, 50, 0.1);
      }

      &.contact-search:hover {
        box-shadow: none;
        & .contact-avatar {
          background-color: var(--color-orange-main);
        }

        & .contact-search-icon {
          fill: var(--color-white);
        }
      }

      &-icon {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 20px;
        height: 20px;

        @include s-1440 {
          top: 8px;
          right: 8px;
          width: 16px;
          height: 16px;
        }

        @include s-1024 {
          top: 6px;
          right: 6px;
          width: 14px;
          height: 14px;
        }
      }

      &-avatar {
        width: 96px;
        min-width: 96px;
        height: 96px;
        color: #fff;
        font-size: 22px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: 1.65px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        transition: background-color ease 0.4s;

        @include s-1440 {
          width: 80px;
          min-width: 80px;
          height: 80px;
        }

        @include s-768 {
          width: 50px;
          min-width: 50px;
          height: 50px;
        }

        @include s-375 {
          width: 30px;
          min-width: 30px;
          height: 30px;
        }

        & .label-agent {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }

        &-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &.empty {
            background: linear-gradient(99.55deg, #8ecae6 1.83%, #2487a7 98.07%);
            font-family: var(--font-reg);
          }
        }
      }

      &-name {
        color: #0b0c0d;
        text-align: center;
        line-height: 120%;
        padding-top: 12px;
        font-family: var(--font-med);
        font-size: 18px;
        text-transform: capitalize;

        @include s-1440 {
          font-size: 16px;
          padding-top: 8px;
        }

        @include s-1024 {
          font-size: 15px;
          padding-top: 6px;
        }

        @include s-768 {
          font-size: 13px;
        }

        @include s-375 {
          font-size: 11px;
        }
      }

      &-search {
        border: none;

        & .contact-avatar {
          background: var(--color-orange-bg);

          &-icon {
            width: 24px;
            height: 24px;

            @include s-1440 {
              width: 20px;
              height: 20px;
            }

            @include s-1024 {
              width: 18px;
              height: 18px;
            }
          }
        }

        &-icon {
          width: 20px;
          height: 20px;
          fill: var(--color-orange-main);
          transition: fill ease 0.4s;
        }
      }

      & .MuiSkeleton-root {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    &-empty {
      padding: 24px 0;
      margin-top: 24px;
      color: var(--color-gray-5);
      @include s-1440 {
        padding: 16px 0;
        margin-top: 16px;
      }

      @include s-1024 {
        padding: 12px 0;
        margin-top: 12px;
      }

      & .icon {
        margin-right: 10px;
      }

      & a {
        margin: 0 8px;
        color: var(--color-gray-6);
      }
    }

    & .flow-message {
      margin-top: 24px;

      @include s-1440 {
        margin-top: 16px;
      }

      @include s-1024 {
        margin-top: 12px;
      }
    }
  }

  &-transactions {
    padding-top: 80px;

    @include s-1440 {
      padding-top: 64px;
    }

    @include s-1024 {
      padding-top: 56px;
    }

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-link {
        display: flex;
        border-radius: 20px;
        background: #ecedef;
        padding: 6px 24px;
        align-items: center;
        &:hover {
          background: var(--color-gray-2);
          color: var(--color-black);
        }
        &:active {
          background: var(--color-gray-3);
          color: var(--color-black);
        }
        &.disabled {
          background: var(--color-gray-1);
          color: var(--color-gray-5);
          .link-icon {
            path {
              fill: var(--color-gray-5);
            }
          }
        }
        @include s-1440 {
          padding: 4px 16px;
        }

        @include s-1024 {
          padding: 4px 12px;
        }

        & .link-icon {
          width: 28px;
          height: 28px;
          margin-right: 12px;

          @include s-1440 {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }

          @include s-1024 {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  &-invite {
    padding-top: 80px;

    @include s-1440 {
      padding-top: 64px;
    }

    @include s-1024 {
      padding-top: 56px;
    }

    &-wrap {
      position: relative;
      border-radius: 24px;
      overflow: hidden;
    }

    &-content {
      position: relative;
      z-index: 1;
      padding: 80px 40px;
      color: var(--color-white);

      @include s-1024 {
        padding: 64px 36px;
      }

      @include s-375 {
        padding: 34px 20px;
      }
    }

    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-title {
      font-family: var(--font-reg);
      font-size: 80px;
      line-height: 120%;

      @include s-1440 {
        font-size: 60px;
      }

      @include s-1024 {
        font-size: 48px;
      }

      @include s-768 {
        font-size: 36px;
      }

      @include s-375 {
        font-size: 24px;
      }

      &--title {
        color: var(--color-white);
      }

      &--color {
        color: var(--color-orange-light1);
      }
    }

    &-notifications {
      position: absolute;
      top: 40px;
      right: 40px;

      @include s-1024 {
        top: 36px;
        right: 36px;
      }

      @include s-375 {
        top: 16px;
        right: 16px;
      }

      &-icon {
        cursor: pointer;
      }
    }
    &-copy {
      &--title {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 24px;
        line-height: 120%;
        @include s-1440 {
          font-size: 24px;
        }
        @include s-1024 {
          font-size: 20px;
        }
      }
      &-block {
        display: inline-flex;
        border-radius: 8px;
        border: 1px solid var(--color-gray-10);
        align-items: center;
        justify-content: space-between;
        padding: 12px;

        @include s-1024 {
          padding: 10px;
        }
      }
      &-ref {
        padding-right: 24px;
        font-size: 16px;
        line-height: 150%;
        min-width: 494px;

        @include s-1024 {
          font-size: 15px;
          padding-right: 20px;
          min-width: 406px;
        }

        @include s-768 {
          min-width: unset;
        }

        @include s-375 {
          word-break: break-all;
        }
      }

      & .el-button {
        min-width: 86px;
        width: 86px;
        height: 44px;
        @include s-1024 {
          min-width: 75px;
          width: 75px;
          height: 39px;
        }
      }
    }
  }

  &-availability {
    position: relative;
    z-index: 1;
    padding: 24px 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    overflow: hidden;

    @include s-1440 {
      padding: 16px 24px;
      border-radius: 24px;
    }

    @include s-1024 {
      padding: 12px 20px;
    }

    @include s-768 {
      padding: 24px;
    }

    &--bg {
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      & img {
        height: 100%;
        object-fit: cover;
      }
    }

    &-img {
      margin-left: 24px;

      @include s-1440 {
        margin-left: 16px;
      }

      @include s-1024 {
        margin-left: 12px;
      }

      @include s-768 {
        display: none;
      }

      & img {
        width: 200px;
        min-width: 200px;
        height: 170px;

        @include s-1440 {
          width: 180px;
          min-width: 180px;
          height: 150px;
        }

        @include s-1024 {
          width: 160px;
          min-width: 160px;
          height: 130px;
        }

        @include s-768 {
          width: 120px;
          min-width: 120px;
          height: 100px;
        }

        @include s-375 {
          width: 90px;
          min-width: 90px;
          height: 70px;
        }
      }
    }

    &-desc {
      &-noti {
        margin-left: 8px;
        width: 16px;
        height: 16px;
        display: inline-block;
      }
    }

    & .MuiFormControlLabel-root {
      align-items: flex-start;
      padding: 24px 0;
      margin-left: 0;

      @include s-1440 {
        padding: 16px 0;
      }

      @include s-1024 {
        padding: 12px 0;
      }

      &:first-of-type {
        margin-top: 24px;

        @include s-1440 {
          margin-top: 16px;
        }

        @include s-1024 {
          margin-top: 12px;
        }
      }
    }

    & .MuiSwitch-track {
      border-radius: 28px;
      background: transparent;
      border: 1px solid #c4c8cb;
      background-color: transparent;
      opacity: 1;
    }

    & .MuiSwitch-thumb {
      color: #a7acb1;
      box-shadow: none;
    }

    & .MuiSwitch-switchBase.Mui-checked {
      & .MuiSwitch-thumb {
        color: var(--color-orange-main);
      }

      & + .MuiSwitch-track {
        background-color: transparent;
      }
    }

    & .MuiSwitch-switchBase {
      &:hover {
        background-color: rgba(255, 11, 37, 0.04);
      }
    }

    & .MuiFormGroup-root.processing {
      & .MuiSwitch-thumb {
        color: #a7acb1;
      }
    }

    & .MuiTypography-root {
      margin-left: 32px;

      @include s-1440 {
        margin-left: 24px;
      }

      @include s-1024 {
        margin-left: 20px;
      }

      & .title {
        color: var(--color-black);
        display: flex;
        align-items: center;
      }

      & .text {
        padding-top: 12px;
        display: block;
        color: var(--color-black);

        @include s-1440 {
          padding-top: 8px;
        }
      }
    }

    & .MuiTouchRipple-root {
      color: var(--color-orange-main);
    }
  }

  &-loader {
    position: fixed;
    left: 0;
    top: 0;
    background-color: var(--color-white);
    width: 100vw;
    height: 100vh;
    z-index: 3;
  }
}
