body {
  background-color: var(--color-white);
  color: var(--color-black);
  font-family: var(--font-reg);
  font-size: 18px;
  line-height: 150%;

  @include s-1440 {
    font-size: 16px;
  }

  @include s-1024 {
    font-size: 15px;
  }

  @include s-768 {
    font-size: 13px;
  }

  @include s-375 {
    font-size: 12px;
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

img {
  width: 100%;
  height: auto;
  line-height: 0;
}

.MuiPopover-root {
  @include s-375 {
    z-index: 1 !important;
  }
}

#recaptcha-container.resend {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
