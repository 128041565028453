.p-home {
  background-color: var(--color-black-2);

  & .s {
    &-hero {
      padding-top: 80px;
      // padding-top: 150px;
      // position: sticky;
      @include s-1440 {
        padding-top: 74px;
      }
      @include s-1024 {
        padding-top: 64px;
      }
      @include s-375 {
        padding-top: 52px;
      }

      & h1 {
        font-size: 96px;
        letter-spacing: 2px;

        @include s-1440 {
          font-size: 68px;
        }

        @include s-1024 {
          letter-spacing: 0;
          font-size: 58px;
          line-height: 116%;
        }
        @include s-375 {
          font-size: 56px;
        }
        & span {
          display: inline-block;
        }
      }

      & > .c-container {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;

        @include s-1024 {
          flex-direction: column;
        }
      }

      &-desc {
        max-width: 880px;
        margin-right: 240px;
        width: 100%;
        margin-top: 160px;

        @include s-1440 {
          margin-top: 125px;
          margin-right: 190px;
          max-width: 600px;
        }

        @include s-1024 {
          margin-right: 0;
          margin-top: 96px;
          max-width: 580px;
        }

        @include s-768 {
          max-width: 460px;
        }

        @include s-768 {
          width: 100%;
          margin: 0;
        }
        @include s-375 {
          width: 100%;
          margin: 0;
        }

        & h1 {
          text-transform: uppercase;

          & i {
            color: var(--color-orange-dark);
          }
        }

        & .links {
          display: none;

          @include s-1024 {
            display: flex;
            padding: 40px 0 0 0;
          }

          @include s-375 {
            position: relative;
            z-index: 2;
            padding: 30px 0 0 0;
          }
        }
      }

      &-phone {
        position: relative;
        padding-bottom: 80px;
        @include s-1440 {
          padding-bottom: 60px;
        }
        @include s-1024 {
          padding-bottom: 40px;
        }

        @include s-768 {
          padding-bottom: 270px;
        }

        @include s-375 {
          padding-bottom: 215px;
          // display: flex;
          // justify-content: center;
          // margin: 0 158px 0 auto;
          // transform: translate(0, 60px);
        }

        & .phone-img {
          width: 290px;
          aspect-ratio: 292/608;
          position: relative;
          overflow: hidden;

          @include s-1440 {
            width: 236px;
            aspect-ratio: 236/490;
          }

          @include s-1024 {
            width: 168px;
            aspect-ratio: 168/348;
          }

          @include s-768 {
            width: 186px;
            aspect-ratio: 186/388;
          }

          @include s-375 {
            width: 124px;
            aspect-ratio: 124/260;
          }

          &:nth-child(1) {
            transform: rotate(5.3deg) translate(0, 135px);
            position: absolute;
            right: 300px;
            bottom: 0;
            z-index: 1;
            @include s-1440 {
              transform: rotate(5.3deg) translate(20%, 140px);
            }
            @include s-1024 {
              right: 230px;
              transform: rotate(5.3deg) translate(20%, 130px);
            }
            @include s-768 {
              right: 290px;
              bottom: -75px;
            }
            @include s-375 {
              right: 50%;
              bottom: 20px;
            }
          }

          &:nth-child(2) {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 0;
            transform: rotate(25deg) translate(10%, 280px);
            @include s-1440 {
              transform: rotate(25deg) translate(10%, 265px);
            }
            @include s-1024 {
              transform: rotate(25deg) translate(10%, 225px);
            }
            @include s-768 {
              bottom: -80px;
              right: 30px;
            }
            @include s-375 {
              right: auto;
              left: 50%;
              bottom: 0;
              transform: rotate(25deg) translate(50px, 160px);
            }
          }

          & iframe {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 130%;
            height: 100%;
          }
        }
      }

      &-bottom {
        // margin-top: -90px;
        padding: 80px 0;
        position: relative;
        overflow: hidden;

        @include s-1440 {
          // margin-top: -134px;
          padding: 60px 0;
        }

        @include s-1024 {
          // margin-top: -120px;
        }

        @include s-375 {
          // margin-top: -40px;
        }

        &-bg {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 120%;
          height: 120%;
          z-index: -1;
          overflow: hidden;
          transform: translate(-50%, -50%);

          @include s-768 {
            width: 160%;
            height: 160%;
          }

          &.loaded {
            & img {
              display: none;

              @include s-375 {
                display: block;
              }
            }
          }

          &--layout {
            display: block;
            background: linear-gradient(100deg, #e98000 1.83%, #ff9c25 98.07%);
            height: 100%;
          }

          & img {
            object-fit: cover;
            position: absolute;
            left: 50%;
            top: 50%;
            width: 120%;
            height: 120%;
            transform: translate(-50%, -50%);
            transform-origin: center;
          }

          & iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 140%;
            height: 140%;
            opacity: 0.2;

            @include s-375 {
              display: none;
            }
          }
        }

        & .text {
          padding: 350px 0 240px 0;

          @include s-1440 {
            padding: 344px 0 220px 0;
          }

          @include s-1024 {
            padding: 320px 0 176px 0;
          }

          @include s-768 {
            padding: 420px 0 180px 0;
          }

          @include s-375 {
            padding: 246px 0 160px 0;
          }

          & h3 {
            color: var(--color-white);
          }
        }

        & .links {
          @include s-1024 {
            display: none;
          }
        }
      }

      & .links a {
        display: inline-block;
        margin-right: 46px;
        max-width: 250px;
        width: 100%;
        transition: transform ease 0.3s;

        &:hover {
          transform: scale(1.05);
        }

        @include s-1440 {
          margin-right: 40px;
          max-width: 188px;
        }

        @include s-1024 {
          margin-right: 20px;
          max-width: 170px;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    &-why {
      border-radius: 40px 40px 0 0;
      background-color: var(--color-white);
      margin-top: -80px;
      padding-top: 120px;
      position: relative;

      @include s-1440 {
        padding-top: 100px;
      }

      @include s-1024 {
        padding-top: 70px;
      }

      @include s-375 {
        padding-top: 40px;
      }

      &-image {
        margin-top: 50px;
        aspect-ratio: 1720/582;
        object-fit: cover;

        @include s-1440 {
          margin-top: 40px;
        }

        @include s-1024 {
          aspect-ratio: 940/440;
          margin-top: 30px;
        }

        @include s-768 {
          aspect-ratio: 728/440;
        }

        @include s-375 {
          aspect-ratio: 335/440;
          margin-top: 22px;
        }

        & img {
          height: 100%;
          object-fit: cover;
        }
      }

      &-list {
        margin: 0 -10px;
        padding-top: 50px;

        @include s-1440 {
          padding-top: 40px;
        }

        @include s-1024 {
          padding-top: 30px;
        }

        @include s-768 {
          padding-top: 30px;
        }

        @include s-375 {
          padding-top: 26px;
        }

        &--title {
          border-top: 2px solid #c5c8cb;
          padding-top: 24px;
          display: block;

          @include s-1024 {
            padding-top: 16px;
          }

          @include s-375 {
            padding-top: 14px;
          }
        }

        &--subtitle {
          display: block;
          padding-top: 8px;
          color: #2c2f32;

          @include s-375 {
            padding-bottom: 16px;
          }
        }
      }
    }

    &-customers {
      @include padTopBot240;
      overflow-x: hidden;
      background: var(--color-white);
      position: relative;
      z-index: 1;

      &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include s-768 {
          flex-wrap: wrap;
        }

        &-btns {
          display: flex;
          justify-content: flex-end;
          align-items: stretch;

          @include s-768 {
            width: 100%;
            justify-content: flex-start;
            padding-top: 30px;
          }

          @include s-375 {
            padding-top: 24px;
          }

          & .arrow {
            margin-right: 20px;
            display: block;
            cursor: pointer;
            transition: transform ease 0.3s;

            &:hover {
              transform: scale(1.05);
            }

            &.right {
              margin: 0;
            }

            & svg {
              width: 42px;
              height: 42px;
              display: block;

              @include s-1440 {
                width: 36px;
                height: 36px;
              }
            }
          }
        }
      }

      & .swiper {
        padding: 50px 100px 0;
        margin: 0 0 0 auto;

        @include s-1440 {
          padding: 40px 70px 0;
        }

        @include s-1024 {
          padding: 30px 42px 0;
        }

        @include s-768 {
          padding: 30px 20px 0;
        }

        @include s-375 {
          padding: 26px 20px 0;
        }

        &-wrapper {
          align-items: stretch;
        }

        &-slide {
          border-radius: 20px;
          min-height: 440px;
          padding: 50px;
          display: flex;
          flex-direction: column;
          height: 100%;
          align-items: flex-start;
          background: #f0f1f2;
          max-width: 720px;
          height: auto;

          @include s-1440 {
            max-width: 600px;
            min-height: 330px;
            padding: 40px;
          }

          @include s-1024 {
            padding: 30px;
            max-width: 520px;
            min-height: 300px;
          }

          @include s-375 {
            padding: 22px 20px;
            max-width: 290px;
            min-height: 280px;
          }

          &--text {
            line-height: 120%;
            padding-bottom: 20px;
            color: #2c2f32;
          }

          &--author {
            line-height: 150%;
            display: inline-block;
            margin: auto 0 0 0;
            padding: 8px;
            background: var(--color-white);
            border-radius: 10px;
          }
        }
      }
    }
  }

  & .c-footer {
    margin: 0;
  }
}
