.c-post {
  &-item {
    padding: 20px 20px 10px !important;
    background: var(--color-white);
    border: 1px solid #d3d6d8;
    border-radius: 30px;
    transition: transform ease 0.3s;

    @include s-1440 {
      padding: 16px 16px 8px !important;
      border-radius: 20px;
    }

    @include s-1024 {
      padding: 12px 12px 6px !important;
      border-radius: 15px;
    }

    @include s-375 {
      padding: 12px 12px 4px !important;
    }

    &:hover {
      text-decoration: none;
      transform: scale(1.05);
    }

    & a {
      width: 100%;

      &:hover {
        text-decoration: none;
      }
    }

    &-img {
      border-radius: 19px;
    }

    &--pretitle {
      display: block;
      padding-top: 26px;
      color: #2c2f32;
      line-height: 150%;
      font-size: 16px;

      @include s-1440 {
        padding-top: 22px;
        font-size: 14px;
      }

      @include s-1024 {
        padding-top: 10px;
        font-size: 13px;
      }

      @include s-375 {
        padding-top: 14px;
      }
    }

    &--title {
      display: block;
      padding-top: 24px;

      @include s-1440 {
        padding-top: 20px;
      }

      @include s-1024 {
        padding-top: 12px;
      }
    }

    &--link {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 26px;
      font-size: 16px;
      line-height: 150%;
      color: var(--color-orange-main);
      text-transform: uppercase;

      @include s-1440 {
        margin-top: 20px;
        font-size: 14px;
      }

      @include s-1024 {
        margin-top: 12px;
        font-size: 13px;
      }

      & svg {
        width: 42px;
        height: 42px;
        margin-right: 12px;
        transform: scale(-1, -1);

        @include s-1440 {
          width: 36px;
          height: 36px;
        }

        @include s-1024 {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
}
