.c-acc-content {
  width: calc(100% - 320px);
  display: flex;
  flex-direction: column;
  padding: 40px 20px 0;

  @include s-1440 {
    width: calc(100% - 270px);
    padding-top: 24px;
  }

  @include s-1024 {
    width: calc(100% - 200px);
    padding-top: 16px;
  }

  @include s-375 {
    width: 100%;
  }

  &.without {
    width: 100%;

    & .system-message {
      padding-top: 48px;

      @include s-1440 {
        padding-top: 40px;
      }

      @include s-1024 {
        padding-top: 36px;
      }
    }
  }

  .c-acc-container {
    max-width: 1080px;
    padding: 0 0 320px;
    margin: 0 auto;

    @include s-1440 {
      max-width: 850px;
      padding-bottom: 200px;
    }

    @include s-1024 {
      max-width: 704px;
      padding-bottom: 160px;
    }

    @include s-768 {
      max-width: 704px;
      padding-bottom: 100px;
    }
  }

  .c-acc-header {
    .MuiContainer-root {
      max-width: 100%;
      width: 100%;
      margin: 0;
      display: flex;
      justify-content: flex-end;
      padding-right: 48px;
    }
  }
}
