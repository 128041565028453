.c-arrow-back {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  left: 50px;
  top: 150px;
  font-size: 18px;
  color: var(--color-gray-5);
  transition: color 0.3s ease-in-out;
  cursor: pointer;
  font-family: var(--font-med);
  z-index: 2;

  @include s-1440 {
    top: 110px;
    left: 40px;
    font-size: 16px;
  }

  @include s-1024 {
    top: 92px;
    left: 30px;
    font-size: 15px;
  }

  @include s-768 {
    top: 92px;
    left: 30px;
    font-size: 13px;
  }

  @include s-375 {
    top: 70px;
    left: 16px;
    font-size: 12px;
  }

  &-icon {
    display: inline-flex;
    margin-right: 36px;
    align-items: center;
    // width: 18px;
    // height: 18px;
    width: 24px;
    height: 24px;

    @include s-1440 {
      margin-right: 20px;
    }

    @include s-1024 {
      margin-right: 12px;
    }

    @include s-768 {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }

    @include s-375 {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    & svg {
      fill: var(--color-gray-5);
      transition: fill 0.3s ease-in-out;
    }
  }

  &:hover {
    color: var(--color-gray-6);

    & svg {
      fill: var(--color-orange-main);
    }
  }
}
